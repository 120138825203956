import { FC } from "react"
import { BlackButton } from "@hornet-web-react/core/components/UI/TextButton"
import QuickiesAnalyticsEvent from "../../models/quickies-analytics-event"
import NiceModal from "@ebay/nice-modal-react"
import { UserSignedUpOrigin } from "@hornet-web-react/login/models/login-analytics-event"
import useTranslation from "next-translate/useTranslation"
import { useQuickiesCookieConsent } from "../../hooks/use-quickies-cookie-consent"
import { useEventTrackerService } from "../../hooks/use-event-tracker-service"
import { useLazyLoaded } from "@hornet-web-react/core/hooks/use-lazy-loaded"

type G7LoginButtonProps = {
  redirectTo: string | null
  userSignedUpOrigin: UserSignedUpOrigin
  size?: "default" | "small"
}

const i18nKey = `quickies:components.layout.g7_login_button`

const G7LoginButton: FC<G7LoginButtonProps> = ({
  redirectTo,
  userSignedUpOrigin,
  size = "default",
}) => {
  const { t } = useTranslation()
  const { handleCookieConsentModal } = useQuickiesCookieConsent()
  const { reportEvent } = useEventTrackerService()
  const { get: getLoginG7Modal } = useLazyLoaded(
    () => import("../Modals/LoginG7Modal")
  )

  const handleLoginOnClick = async () => {
    void reportEvent(QuickiesAnalyticsEvent.landingTapOnLoginCta())

    await handleCookieConsentModal()

    void getLoginG7Modal((modal) =>
      NiceModal.show(modal, {
        redirectTo,
        userSignedUpOrigin,
      })
    )
  }

  return (
    <BlackButton
      data-test-id="g7-login-button-login-btn"
      size={size}
      onClick={() => handleLoginOnClick()}
    >
      {t(`${i18nKey}.label`)}
    </BlackButton>
  )
}

export default G7LoginButton
