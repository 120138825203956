import { FC } from "react"
import QuickiesMemberModel from "../../models/quickies-member.model"
import ProfileContentCard from "@hornet-web-react/profile/components/ProfileContentCard"
import useTranslation from "next-translate/useTranslation"
import GroupBlock from "../Groups/GroupBlock"
import { useQuickiesGroupPreview } from "../../hooks/use-quickies-group-preview"
import QuickiesAnalyticsEvent from "../../models/quickies-analytics-event"

type QuickiesProfileGroupsProps = {
  member: QuickiesMemberModel
}

const i18nKey = "quickies:components.quickies_profile.quickies_profile_groups"

const QuickiesProfileGroups: FC<QuickiesProfileGroupsProps> = ({ member }) => {
  const { t } = useTranslation()
  const { openGroupPreview } = useQuickiesGroupPreview()

  if (!member.hostingGroups.length) {
    return null
  }

  return (
    <ProfileContentCard>
      <ProfileContentCard.Header>
        {t(`${i18nKey}.heading`)}
      </ProfileContentCard.Header>
      <ProfileContentCard.Content>
        {member.hostingGroups.length > 0 &&
          member.hostingGroups.map((group) => (
            <GroupBlock.Item
              key={group.groupId}
              title={group.title}
              startsAt={group.startsAt}
              thumbnailUrl={group.thumbnailUrl}
              onClick={() =>
                openGroupPreview(
                  group.groupId,
                  QuickiesAnalyticsEvent.profileTapOnGroup(group.groupId)
                )
              }
            />
          ))}
      </ProfileContentCard.Content>
    </ProfileContentCard>
  )
}

export default QuickiesProfileGroups
