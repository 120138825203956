import styled from "styled-components"
import {
  Background as BackgroundV2,
  Label as LabelV2,
  Wrapper as WrapperV2,
} from "./InputV2.styles"

export {
  InputContainer,
  FormInput,
  AppendText,
  FormError,
  FormInfo,
  InfoIcon,
  ErrorIcon,
} from "./InputV2.styles"

export const Background = styled(BackgroundV2)`
  padding: ${({ theme }) => theme.spacing.half};
`

export const Wrapper = styled(WrapperV2)`
  &:last-child {
    margin-bottom: 0;
  }
`

export const Label = styled(LabelV2)`
  ${({ theme }) => theme.font.regular.caption1}
  display: block;
  color: ${({ theme }) => theme.color.text.secondary};
  margin-bottom: 2px;
`
