import useImmutableSWR from "swr"
import ApiService from "@hornet-web-react/core/services/API/ApiService"
import { ApiServiceEndpoint } from "@hornet-web-react/core/services/API/ApiServiceEndpoint"
import { useSessionUser } from "@hornet-web-react/core/contexts/session"
import { QuickiesLookupDataApiPayload } from "../models/quickies-lookup-data.model"
import { useMemo } from "react"

export function useQuickiesLookupData() {
  const { isAuthenticated } = useSessionUser()
  const { data, isLoading, error, mutate } =
    useImmutableSWR<QuickiesLookupDataApiPayload>(
      isAuthenticated
        ? ApiService.getEndpoint(ApiServiceEndpoint.QuickiesLookupDataGet)
        : null,
      {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
      }
    )

  return {
    data: useMemo(() => {
      const parsed = QuickiesLookupDataApiPayload.safeParse(data)
      if (parsed.success) {
        return parsed.data
      }

      data && console.error(parsed.error)
    }, [data]),
    isLoading,
    error,
    reloadQuickiesLookupData: () => mutate(),
  }
}
