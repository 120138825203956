import { Translate } from "next-translate"
import { Locale } from "@hornet-web-react/core/types/session"

export function getDefaultMeta(t: Translate, locale: Locale) {
  switch (locale) {
    case Locale.enum.id:
      return {
        metaTitle: t("quickies:meta.title_id"),
        metaDescription: t("quickies:meta.description_id"),
      }

    case Locale.enum.zh:
      return {
        metaTitle: t("quickies:meta.title_uk"),
        metaDescription: t("quickies:meta.description_uk"),
      }

    case Locale.enum.th:
      return {
        metaTitle: t("quickies:meta.title_uk"),
        metaDescription: t("quickies:meta.description_uk"),
      }

    case Locale.enum.tr:
      return {
        metaTitle: t("quickies:meta.title_uk"),
        metaDescription: t("quickies:meta.description_uk"),
      }

    case Locale.enum.uk:
      return {
        metaTitle: t("quickies:meta.title_uk"),
        metaDescription: t("quickies:meta.description_uk"),
      }

    case Locale.enum.de:
      return {
        metaTitle: t("quickies:meta.title_de"),
        metaDescription: t("quickies:meta.description_de"),
      }

    case Locale.enum.fr:
      return {
        metaTitle: t("quickies:meta.title_fr"),
        metaDescription: t("quickies:meta.description_fr"),
      }

    case Locale.enum.pt:
      return {
        metaTitle: t("quickies:meta.title_pt"),
        metaDescription: t("quickies:meta.description_pt"),
      }

    default:
      return {
        metaTitle: t("quickies:meta.title_row"),
        metaDescription: t("quickies:meta.description_row"),
      }
  }
}
