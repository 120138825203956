import { FC } from "react"
import {
  Wrapper,
  Background,
  Label,
  FormInfo,
  InfoIcon,
} from "./SelectV3.styles"
import { useUniqueId } from "@hornet-web-react/core/utils"
import { SelectControl, SelectProps } from "./Select"

type SelectV3Props = SelectProps & {
  label?: string
}

const SelectV3: FC<SelectV3Props> = (props) => {
  // consume some props and leave the attrs
  const {
    className,
    name,
    label,
    value,
    options,
    onChange,
    appendText,
    children,
    inputRef,
    ...attrs
  } = props

  const inputUniqueId = useUniqueId(name)

  return (
    <Wrapper className={className}>
      <Background hasError={false}>
        {label && <Label htmlFor={inputUniqueId}>{label}</Label>}
        <SelectControl
          name={name}
          value={value}
          onChange={onChange}
          inputRef={inputRef}
          inputUniqueId={inputUniqueId}
          options={options}
          appendText={appendText}
          {...attrs}
        />
      </Background>

      {children && (
        <FormInfo>
          <InfoIcon />
          {children}
        </FormInfo>
      )}
    </Wrapper>
  )
}

export default SelectV3
