import { FC, ReactNode, useCallback } from "react"
import styled from "styled-components"
import { PlusCircleIcon } from "@heroicons/react/24/outline"
import useTranslation from "next-translate/useTranslation"
import {
  ProfilePhotoUploadModel,
  ProfilePhotoUploadResult,
} from "../models/profile-photo-upload.model"
import ProfilePhotoUploadModal from "./Modals/ProfilePhotoUploadModal"
import { isRight, makeRight } from "@hornet-web-react/core/utils"
import { useModal } from "@ebay/nice-modal-react"

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.color.bg.gradient};
  z-index: 1;
`

const CtaBox = styled.div`
  cursor: pointer;
  position: absolute;
  padding: ${({ theme }) => theme.spacing.regular};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${({ theme }) => theme.color.bg.light01};
  border-radius: 9px;
  min-width: 250px;
`

const Icon = styled.div`
  height: 30px;
  color: ${({ theme }) => theme.color.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing.less};
  font-size: 26px;
  display: flex;
  justify-content: center;
  flex-direction: row;
`

const Heading = styled.h4`
  ${({ theme }) => theme.font.bold.headline}
  color: ${({ theme }) => theme.color.text.primary};
  margin: 0 0 ${({ theme }) => theme.spacing.half};
  padding: 0;
  text-align: center;
`

const Text = styled.p`
  ${({ theme }) => theme.font.regular.body}
  color: ${({ theme }) => theme.color.text.primary};
  margin: 0 0 ${({ theme }) => theme.spacing.half};
  text-align: center;
`

type ProfilePhotoUploadCtaProps = {
  onUpload: (
    formData: ProfilePhotoUploadModel
  ) => Promise<ProfilePhotoUploadResult>
  customPhotoPolicy?: ReactNode
  photoPlaceholderUrl?: string | null // if null, no placeholder is shown
  i18nOverride?: {
    closeButton?: string
    uploadButton?: string
    title?: string
    text?: string
    thumbCropTitle?: string
    heroCropTitle?: string
  }
  hasHeroCrop?: boolean
  className?: string
}

const i18nKey = "profile:components.profile_photo_upload_cta"

const ProfilePhotoUploadCta: FC<ProfilePhotoUploadCtaProps> = ({
  onUpload,
  customPhotoPolicy,
  className,
  photoPlaceholderUrl,
  i18nOverride,
  hasHeroCrop,
}) => {
  const { t } = useTranslation()
  const profilePhotoModal = useModal(ProfilePhotoUploadModal)

  const hideProfilePhotoUploadModal = useCallback(() => {
    void profilePhotoModal.hide()
  }, [profilePhotoModal])

  const handleContinue = useCallback(
    async (formData: ProfilePhotoUploadModel | undefined) => {
      // wait for upload, close in case of success
      if (typeof formData !== "undefined") {
        const result = await onUpload(formData)
        if (isRight(result)) {
          hideProfilePhotoUploadModal()
        }
        return result
      }

      // just close it
      hideProfilePhotoUploadModal()
      return makeRight(true)
    },
    [hideProfilePhotoUploadModal, onUpload]
  )

  const showProfilePhotoUploadModal = useCallback(() => {
    void profilePhotoModal.show({
      type: "profile",
      onClose: handleContinue,
      customPhotoPolicy,
      photoPlaceholderUrl,
      hasHeroCrop,
      i18nOverride: i18nOverride,
    })
  }, [
    profilePhotoModal,
    handleContinue,
    customPhotoPolicy,
    photoPlaceholderUrl,
    hasHeroCrop,
    i18nOverride,
  ])

  return (
    <Wrapper className={className}>
      <CtaBox role={"button"} onClick={showProfilePhotoUploadModal}>
        <Icon>
          <PlusCircleIcon width={30} />
        </Icon>
        <Heading>{i18nOverride?.title || t(`${i18nKey}.heading`)}</Heading>
        <Text>{i18nOverride?.text || t(`${i18nKey}.text`)}</Text>
      </CtaBox>
    </Wrapper>
  )
}

export default ProfilePhotoUploadCta
