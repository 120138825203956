import { useEffect, useState } from "react"
import {
  useSessionDevice,
  useSessionEnvironment,
  useSessionUser,
} from "@hornet-web-react/core/contexts/session"
import styled from "styled-components"
import {
  Country,
  PartialSessionState,
} from "@hornet-web-react/core/types/session"
import NiceModal from "@ebay/nice-modal-react"
import SlideModal from "@hornet-web-react/core/components/Modals/SlideModal"
import Legend from "@hornet-web-react/core/components/Form/Legend"
import Fieldset from "@hornet-web-react/core/components/Form/Fieldset"
import TextButton from "@hornet-web-react/core/components/UI/TextButton"
import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
} from "@heroicons/react/24/solid"
import SelectV3 from "@hornet-web-react/core/components/Form/SelectV3"
import { removeCookie, setCookie } from "typescript-cookie"
import { CookieName } from "@hornet-web-react/core/types"
import appConfig from "../../app-config"
import { useQuickiesIpLocation } from "../../hooks/use-quickies-ip-location"
import {
  IS_CUSTOM_GPS_COOKIE_NAME,
  getCustomGps,
  serializeCustomGps,
} from "../../utils/is-custom-gps"

type QuickiesDebugModalProps = {
  //
}

const QuickiesDebugModal = NiceModal.create(
  (props: QuickiesDebugModalProps) => {
    const modal = NiceModal.useModal()
    const environment = useSessionEnvironment()
    const device = useSessionDevice()
    const user = useSessionUser()
    const { countryCode: ipCountryCode } = useQuickiesIpLocation()

    const [session, setSession] = useState<PartialSessionState>({})
    const [serviceWorkerError, setServiceWorkerError] = useState("")
    const [isServiceWorkerExpanded, setIsServiceWorkerExpanded] =
      useState(false)
    const [isSessionExpanded, setIsSessionExpanded] = useState(false)
    const [superTravel, setSuperTravel] = useState("")

    useEffect(() => {
      setSession({ environment, device, user })
    }, [environment, device, user])

    const [currentUrl, setCurrentUrl] = useState("")

    useEffect(() => {
      setCurrentUrl(window.location.href)

      // also pre-select super-travel city if custom gps is set
      const customGps = getCustomGps()

      if (customGps) {
        const city = superTravelCities.find(
          (city) =>
            city.coords &&
            city.coords[0] === customGps.lat.toString() &&
            city.coords[1] === customGps.lng.toString()
        )

        if (city && city.name) {
          setSuperTravel(city.name)
          return
        }

        // this means that there are some custom GPS set, but not in the list
      }
    }, [])

    const handleSuperTravelChange = (value: string, name: string) => {
      setSuperTravel(value)

      const city = superTravelCities.find((city) => city.name === value)

      if (!city || !city.countryCode || !city.coords) {
        // reset landing variant to current IP country code
        if (ipCountryCode) {
          setCookie(
            CookieName.Country,
            ipCountryCode,
            appConfig.simpleCookieConfig()
          )
        }

        // reset custom gps
        removeCookie(IS_CUSTOM_GPS_COOKIE_NAME)

        window.location.href = "/map"
        return
      }

      // handle landing variant
      setCookie(
        CookieName.Country,
        city.countryCode,
        appConfig.simpleCookieConfig()
      )

      // set custom gps
      setCookie(
        IS_CUSTOM_GPS_COOKIE_NAME,
        serializeCustomGps(city.coords[0], city.coords[1]),
        appConfig.simpleCookieConfig()
      )

      window.location.href = "/map"
    }

    return (
      <SlideModal
        title="DebugView Modal"
        isOpen={modal.visible}
        closeAction={{
          label: "Close",
          onClick: () => modal.hide(),
        }}
      >
        <DebugFieldset>
          <DebugLegend>Current URL</DebugLegend>
          <div>
            <DebugCode>{currentUrl}</DebugCode>
          </div>
        </DebugFieldset>

        <DebugFieldset>
          <DebugLegend>Super-travel</DebugLegend>
          <div>
            <SelectV3
              name={"superTravel"}
              value={superTravel}
              label={"Select a city"}
              onChange={handleSuperTravelChange}
              options={superTravelCities.map((city) => ({
                optionValue: city.name || "",
                optionLabel: city.name
                  ? `(${city.country}) ${city.name}`
                  : "Not selected",
              }))}
            />
          </div>
        </DebugFieldset>

        <DebugFieldset>
          <DebugLegend
            role={"button"}
            onClick={() => setIsSessionExpanded(!isSessionExpanded)}
          >
            <span>Current Session</span>

            {isSessionExpanded ? (
              <ChevronDoubleUpIcon height={24} />
            ) : (
              <ChevronDoubleDownIcon height={24} />
            )}
          </DebugLegend>

          <DebugFieldsetContent isExpanded={isSessionExpanded}>
            <DebugCode>{JSON.stringify(session, null, "\t")}</DebugCode>
          </DebugFieldsetContent>
        </DebugFieldset>

        <DebugFieldset>
          <DebugLegend
            role={"button"}
            onClick={() => setIsServiceWorkerExpanded(!isServiceWorkerExpanded)}
          >
            <span>Service Worker</span>

            {isServiceWorkerExpanded ? (
              <ChevronDoubleUpIcon height={24} />
            ) : (
              <ChevronDoubleDownIcon height={24} />
            )}
          </DebugLegend>

          <DebugFieldsetContent isExpanded={isServiceWorkerExpanded}>
            <div>
              <DebugCode>
                Available: {"serviceWorker" in navigator ? "yes" : "no"}
                <br />
                Error: {serviceWorkerError}
              </DebugCode>
            </div>

            <ServiceWorkerButtons>
              <TextButton
                data-test-id=""
                primary
                onClick={() => {
                  if (!("serviceWorker" in navigator)) {
                    return
                  }

                  console.log(
                    `navigator.serviceWorker`,
                    navigator.serviceWorker
                  )

                  navigator.serviceWorker.ready
                    .then((registration) => {
                      return registration.update()
                    })
                    .catch((err) => {
                      setServiceWorkerError(err.message || "Unknown error")
                      alert(err)
                    })
                }}
                size={"small"}
              >
                Update Registration
              </TextButton>

              <TextButton
                data-test-id=""
                size={"small"}
                onClick={() => {
                  if (navigator.serviceWorker.controller) {
                    navigator.serviceWorker.controller.postMessage("ping")
                  } else {
                    alert("No service worker controller")
                  }
                }}
              >
                Ping
              </TextButton>

              <TextButton
                data-test-id=""
                size={"small"}
                onClick={() => {
                  if (navigator.serviceWorker.controller) {
                    navigator.serviceWorker.controller.postMessage(
                      "postWindowVisibility"
                    )
                  } else {
                    alert("No service worker controller")
                  }
                }}
              >
                Get Window Visibility
              </TextButton>

              <TextButton
                data-test-id=""
                size={"small"}
                onClick={() => {
                  navigator.serviceWorker
                    .getRegistrations()
                    .then(function (registrations) {
                      registrations.forEach(function (v) {
                        console.log(v)
                      })
                    })
                }}
              >
                Get Registrations
              </TextButton>
            </ServiceWorkerButtons>
          </DebugFieldsetContent>
        </DebugFieldset>
      </SlideModal>
    )
  }
)

export default QuickiesDebugModal

// const DebugViewSelect = styled(Select)`
//   margin-bottom: 10px;
//   box-shadow: none;
//
//   & .form-select__input {
//     color: ${({ theme }) => theme.color.text.primary};
//     ${({ theme }) => theme.font.regular.body};
//   }
// `

const DebugFieldset = styled(Fieldset)`
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
`

const DebugFieldsetContent = styled.div<{ isExpanded: boolean }>`
  display: ${({ isExpanded }) => (isExpanded ? "block" : "none")};
  margin-top: 10px;
`

const DebugLegend = styled(Legend)`
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const DebugCode = styled.code`
  white-space: pre;
  overflow: auto;
  width: 100%;
  display: block;
`

const ServiceWorkerButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
`

const superTravelCities = [
  { name: null, coords: null, country: null, countryCode: null },
  {
    name: "Kyiv",
    coords: ["50.4501", "30.5234"],
    country: "Ukraine",
    countryCode: Country.unwrap().enum.ua,
  },
  {
    name: "Kharkiv",
    coords: ["49.9935", "36.2304"],
    country: "Ukraine",
    countryCode: Country.unwrap().enum.ua,
  },
  {
    name: "Odesa",
    coords: ["46.4825", "30.7233"],
    country: "Ukraine",
    countryCode: Country.unwrap().enum.ua,
  },
  {
    name: "Dnipro",
    coords: ["48.4647", "35.0462"],
    country: "Ukraine",
    countryCode: Country.unwrap().enum.ua,
  },
  {
    name: "Lviv",
    coords: ["49.8397", "24.0297"],
    country: "Ukraine",
    countryCode: Country.unwrap().enum.ua,
  },
  {
    name: "Jakarta",
    coords: ["-6.2088", "106.8456"],
    country: "Indonesia",
    countryCode: Country.unwrap().enum.id,
  },
  {
    name: "Surabaya",
    coords: ["-7.2575", "112.7521"],
    country: "Indonesia",
    countryCode: Country.unwrap().enum.id,
  },
  {
    name: "Bandung",
    coords: ["-6.9175", "107.6191"],
    country: "Indonesia",
    countryCode: Country.unwrap().enum.id,
  },
  {
    name: "Medan",
    coords: ["3.5952", "98.6722"],
    country: "Indonesia",
    countryCode: Country.unwrap().enum.id,
  },
  {
    name: "Denpasar, Bali",
    coords: ["-8.6705", "115.2166"],
    country: "Indonesia",
    countryCode: Country.unwrap().enum.id,
  },
  {
    name: "Bekasi",
    coords: ["-6.2416", "106.992"],
    country: "Indonesia",
    countryCode: Country.unwrap().enum.id,
  },
  {
    name: "Taipei",
    coords: ["25.033", "121.5654"],
    country: "Taiwan",
    countryCode: Country.unwrap().enum.tw,
  },
  {
    name: "Kaohsiung",
    coords: ["22.6273", "120.3014"],
    country: "Taiwan",
    countryCode: Country.unwrap().enum.tw,
  },
  {
    name: "Taichung",
    coords: ["24.1477", "120.6736"],
    country: "Taiwan",
    countryCode: Country.unwrap().enum.tw,
  },
  {
    name: "Tainan",
    coords: ["22.9997", "120.227"],
    country: "Taiwan",
    countryCode: Country.unwrap().enum.tw,
  },
  {
    name: "Hsinchu",
    coords: ["24.8138", "120.9675"],
    country: "Taiwan",
    countryCode: Country.unwrap().enum.tw,
  },
  {
    name: "Berlin",
    coords: ["52.52", "13.405"],
    country: "Germany",
    countryCode: Country.unwrap().enum.de,
  },
  {
    name: "Hamburg",
    coords: ["53.5511", "9.9937"],
    country: "Germany",
    countryCode: Country.unwrap().enum.de,
  },
  {
    name: "Munich",
    coords: ["48.1351", "11.582"],
    country: "Germany",
    countryCode: Country.unwrap().enum.de,
  },
  {
    name: "Cologne",
    coords: ["50.9375", "6.9603"],
    country: "Germany",
    countryCode: Country.unwrap().enum.de,
  },
  {
    name: "Frankfurt",
    coords: ["50.1109", "8.6821"],
    country: "Germany",
    countryCode: Country.unwrap().enum.de,
  },
  {
    name: "Bangkok",
    coords: ["13.7563", "100.5018"],
    country: "Thailand",
    countryCode: Country.unwrap().enum.th,
  },
  {
    name: "Nonthaburi",
    coords: ["13.8621", "100.5144"],
    country: "Thailand",
    countryCode: Country.unwrap().enum.th,
  },
  {
    name: "Nakhon Ratchasima",
    coords: ["14.9799", "102.0977"],
    country: "Thailand",
    countryCode: Country.unwrap().enum.th,
  },
  {
    name: "Chiang Mai",
    coords: ["18.7883", "98.9853"],
    country: "Thailand",
    countryCode: Country.unwrap().enum.th,
  },
  {
    name: "Hat Yai",
    coords: ["7.0084", "100.4747"],
    country: "Thailand",
    countryCode: Country.unwrap().enum.th,
  },
  {
    name: "Istanbul",
    coords: ["41.0082", "28.9784"],
    country: "Turkey",
    countryCode: Country.unwrap().enum.tr,
  },
  {
    name: "Ankara",
    coords: ["39.9334", "32.8597"],
    country: "Turkey",
    countryCode: Country.unwrap().enum.tr,
  },
  {
    name: "Izmir",
    coords: ["38.4237", "27.1428"],
    country: "Turkey",
    countryCode: Country.unwrap().enum.tr,
  },
  {
    name: "Bursa",
    coords: ["40.1828", "29.0665"],
    country: "Turkey",
    countryCode: Country.unwrap().enum.tr,
  },
  {
    name: "Antalya",
    coords: ["36.8969", "30.7133"],
    country: "Turkey",
    countryCode: Country.unwrap().enum.tr,
  },
  {
    name: "Paris",
    coords: ["48.8566", "2.3522"],
    country: "France",
    countryCode: Country.unwrap().enum.fr,
  },
  {
    name: "Marseille",
    coords: ["43.2965", "5.3698"],
    country: "France",
    countryCode: Country.unwrap().enum.fr,
  },
  {
    name: "Lyon",
    coords: ["45.764", "4.8357"],
    country: "France",
    countryCode: Country.unwrap().enum.fr,
  },
  {
    name: "Toulouse",
    coords: ["43.6047", "1.4442"],
    country: "France",
    countryCode: Country.unwrap().enum.fr,
  },
  {
    name: "São Paulo",
    coords: ["-23.5505", "-46.6333"],
    country: "Brazil",
    countryCode: Country.unwrap().enum.br,
  },
  {
    name: "Rio de Janeiro",
    coords: ["-22.9068", "-43.1729"],
    country: "Brazil",
    countryCode: Country.unwrap().enum.br,
  },
  {
    name: "Brasília",
    coords: ["-15.8267", "-47.9218"],
    country: "Brazil",
    countryCode: Country.unwrap().enum.br,
  },
  {
    name: "Salvador",
    coords: ["-12.9714", "-38.5014"],
    country: "Brazil",
    countryCode: Country.unwrap().enum.br,
  },
  {
    name: "Fortaleza",
    coords: ["-3.7172", "-38.5433"],
    country: "Brazil",
    countryCode: Country.unwrap().enum.br,
  },
]
