import { AppContext, AppProps } from "next/app"
import Head from "next/head"
import "./styles.css"
import "@abraham/reflection"
import { SessionDataProvider } from "@hornet-web-react/core/contexts/session"
import { ThemeProvider } from "styled-components"
import theme from "../styles/theme"
import SWRConfigWrapper from "@hornet-web-react/core/components/Utils/SWRConfigWrapper"
import { ServicesProvider } from "@hornet-web-react/core/contexts/services"
import createContainer from "../services/container"
import ErrorBoundary from "@hornet-web-react/core/components/Utils/ErrorBoundary"
import { isClient } from "@hornet-web-react/core/utils"
import appWithI18n from "next-translate/appWithI18n"
import i18nConfig from "../../i18n"
import appConfig from "../app-config"
import { getReq } from "../utils/od"
import NiceModal from "@ebay/nice-modal-react"
import { ProfileVerificationProvider } from "@hornet-web-react/core/contexts/profile-verification-context"
import Layout from "../components/Layout/Layout"
import PageError from "../components/Pages/PageError"
import Bottle from "bottlejs"
import ProfileVerification from "../components/ProfileVerification"
import { QuickiesMapProvider } from "../contexts/quickies-map-context"
import { ChatNotificationsContextProvider } from "../contexts/chat-notifications-context"

// TODO: move this to a dynamically loaded component if that works
import "mapbox-gl/dist/mapbox-gl.css"
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"
import "yet-another-react-lightbox/styles.css"
import { UploadNudesReminderContextProvider } from "../contexts/upload-nudes-reminder-context"
import { AppContainerContextContextProvider } from "../contexts/app-container-context"
import QuickiesAppStart from "../components/QuickiesAppStart"

// we want to have a singleton container on client
let serviceContainer: Bottle
if (isClient) {
  serviceContainer = createContainer()
}

const QuickiesApp = ({ Component, pageProps }: AppProps) => {
  const sessionDataProviderInitialData = pageProps.session

  const content = pageProps.error ? (
    <Layout title={pageProps.error.message}>
      <PageError
        statusCode={pageProps.error.statusCode}
        message={pageProps.error.message}
      />
    </Layout>
  ) : (
    <Component {...pageProps} />
  )

  const [req1, req2] = getReq(appConfig.version, pageProps.rt)

  return (
    <>
      <Head>
        <title>HornetX</title>
      </Head>

      <ThemeProvider theme={theme}>
        <SessionDataProvider
          initialData={sessionDataProviderInitialData}
          appConfig={appConfig}
        >
          <ProfileVerificationProvider>
            <ServicesProvider
              container={serviceContainer}
              createContainer={createContainer}
              req1={req1}
              req2={req2}
              appName={"Quickies"}
            >
              <SWRConfigWrapper fallback={pageProps?.fallback}>
                <AppContainerContextContextProvider>
                  <UploadNudesReminderContextProvider>
                    <QuickiesMapProvider>
                      <NiceModal.Provider>
                        <ErrorBoundary errorElement={<PageError />}>
                          <ChatNotificationsContextProvider>
                            <QuickiesAppStart>
                              <ProfileVerification>
                                {content}
                              </ProfileVerification>
                            </QuickiesAppStart>
                          </ChatNotificationsContextProvider>
                        </ErrorBoundary>
                      </NiceModal.Provider>
                    </QuickiesMapProvider>
                  </UploadNudesReminderContextProvider>
                </AppContainerContextContextProvider>
              </SWRConfigWrapper>
            </ServicesProvider>
          </ProfileVerificationProvider>
        </SessionDataProvider>
      </ThemeProvider>
    </>
  )
}

QuickiesApp.getInitialProps = async (context: AppContext) => {
  let pageProps = {}
  if (context.Component.getInitialProps) {
    pageProps = await context.Component.getInitialProps(context.ctx)
  }
  return { pageProps }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default appWithI18n(QuickiesApp, {
  ...i18nConfig,
  loadLocaleFrom: (lang, ns) => {
    switch (ns) {
      case "core":
      case "login":
      case "chat":
      case "profile":
      case "shop":
        return import(`../../../../locales/${lang}/${ns}.json`).then(
          (m) => m.default
        )
    }

    return import(`../../locales/${lang}/${ns}.json`).then((m) => m.default)
  },
})
