import { z } from "zod"
import { ProfilePhotoUploadModel } from "@hornet-web-react/profile/models/profile-photo-upload.model"
import { QuickiesGroupId } from "@hornet-web-react/core/types"

export const GroupFormDataAddress = z.string().max(140).nullable()

export const GroupFormDataAdditionalInfo = z.string().max(600).nullable()

export const GroupFormData = z.object({
  title: z.string().max(100).nullable(),
  description: z.string().max(600).nullable(),
  starts_at: z.coerce.date(),
  duration: z.number(),
  lat: z.number(),
  lng: z.number(),
  address: GroupFormDataAddress,
  additional_info: GroupFormDataAdditionalInfo,
})
export type GroupFormData = z.infer<typeof GroupFormData>

export function serializeGroupPhotoToFormData(
  data: ProfilePhotoUploadModel,
  quickiesGroupId: QuickiesGroupId
): FormData {
  const formData = new FormData()

  formData.append("hangout_photo[photo]", data.file)
  formData.append("hangout_photo[hangout_id]", quickiesGroupId)

  formData.append("hangout_photo[thumb_width]", String(data.thumbData.width))
  formData.append("hangout_photo[thumb_height]", String(data.thumbData.height))
  formData.append("hangout_photo[thumb_x1]", String(data.thumbData.x1))
  formData.append("hangout_photo[thumb_y1]", String(data.thumbData.y1))
  formData.append("hangout_photo[thumb_x2]", String(data.thumbData.x2))
  formData.append("hangout_photo[thumb_y2]", String(data.thumbData.y2))

  return formData
}
