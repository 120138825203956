import { default as BaseContentCard } from "@hornet-web-react/core/components/UI/ContentCard"
import styled from "styled-components"

const ContentCard = styled(BaseContentCard)`
  &:last-child {
    margin-bottom: 0;
  }
`

const ContentCardContent = styled(BaseContentCard.Content)`
  border-radius: 0;
  border-right: 0;
  border-left: 0;

  @media screen and ${({ theme }) => theme.breakpoints.device.gtXs} {
    border-right: 1px solid ${({ theme }) => theme.color.border.default};
    border-left: 1px solid ${({ theme }) => theme.color.border.default};
  }
`

const ContentCardHeader = styled(BaseContentCard.Header)`
  @media screen and ${({ theme }) => theme.breakpoints.device.gtXs} {
    padding: 0 ${({ theme }) => theme.spacing.half};
  }
`

export default Object.assign(ContentCard, {
  Content: ContentCardContent,
  Header: ContentCardHeader,
  Separator: BaseContentCard.Separator,
})
