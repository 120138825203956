import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useReducer,
} from "react"
import { ConversationId } from "@hornet-web-react/chat/models/inbox-conversations.model"

type ChatNotificationsState = {
  isChatNotificationShown: boolean
  chatNotificationPreview?: string
  chatNotificationConversationId?: ConversationId
}

type ChatNotificationsApi = {
  showChatNotification: (
    preview: string,
    conversationId: ConversationId
  ) => void
  closeChatNotification: () => void
}

const ChatNotificationsStateContext = createContext<ChatNotificationsState>({
  isChatNotificationShown: false,
})
const ChatNotificationsApiContext = createContext<ChatNotificationsApi>(
  {} as ChatNotificationsApi
)

type Actions =
  | {
      type: "showChatNotification"
      preview: string
      conversationId: ConversationId
    }
  | { type: "closeChatNotification" }

const reducer = (
  state: ChatNotificationsState,
  action: Actions
): ChatNotificationsState => {
  switch (action.type) {
    case "showChatNotification":
      return {
        ...state,
        isChatNotificationShown: true,
        chatNotificationPreview: action.preview,
        chatNotificationConversationId: action.conversationId,
      }

    case "closeChatNotification":
      return {
        ...state,
        isChatNotificationShown: false,
        chatNotificationPreview: undefined,
        chatNotificationConversationId: undefined,
      }
  }
}

export const ChatNotificationsContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducer, {} as ChatNotificationsState)

  const api = useMemo(() => {
    const showChatNotification = (
      preview: string,
      conversationId: ConversationId
    ) => {
      dispatch({ type: "showChatNotification", preview, conversationId })
    }

    const closeChatNotification = () => {
      dispatch({ type: "closeChatNotification" })
    }

    return {
      showChatNotification,
      closeChatNotification,
    }
  }, [])

  return (
    <ChatNotificationsApiContext.Provider value={api}>
      <ChatNotificationsStateContext.Provider value={state}>
        {children}
      </ChatNotificationsStateContext.Provider>
    </ChatNotificationsApiContext.Provider>
  )
}

export const useChatNotificationsApi = () =>
  useContext(ChatNotificationsApiContext)
export const useChatNotificationsState = () =>
  useContext(ChatNotificationsStateContext)
