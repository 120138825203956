import { useCallback, useContext } from "react"
import NiceModal from "@ebay/nice-modal-react"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import { TYPES as CORE_TYPES } from "@hornet-web-react/core/services/types"
import QuickiesAnalyticsEvent from "../models/quickies-analytics-event"
import GoogleAnalyticsService from "@hornet-web-react/core/services/GoogleAnalyticsService"
import { useSessionEnvironment } from "@hornet-web-react/core/contexts/session"
import { useQuickiesProfileViewsCounter } from "./use-quickies-profile-views-counter"
import { useQuickiesSession } from "./use-quickies-session"

import {
  QuickiesPremiumPaywall,
  QuickiesProfileId,
} from "@hornet-web-react/core/types"
import { useEffectOnce } from "react-use"
import { useQuickiesPremium } from "./use-quickies-premium"
import { useEventTrackerService } from "./use-event-tracker-service"
import QuickiesProfilePreviewModal from "../components/Modals/QuickiesProfilePreviewModal"

export function useQuickiesProfilePreview() {
  const modals = useContext(NiceModal.NiceModalContext)
  const {
    hasReachedLimit,
    countProfileView,
    openReachedProfileLimitModal,
    clearCounter,
  } = useQuickiesProfileViewsCounter()
  const { openQuickiesPremiumModal, hasPremium } = useQuickiesPremium()
  const { data: quickiesSession } = useQuickiesSession()
  const { appUrl } = useSessionEnvironment()
  const { reportEvent } = useEventTrackerService()
  const googleAnalyticsService = useCoreService<GoogleAnalyticsService>(
    CORE_TYPES.GoogleAnalyticsService
  )

  useEffectOnce(() => {
    // preload the modal
    if ("requestIdleCallback" in window) {
      window.requestIdleCallback(
        async () => import("../components/Modals/QuickiesProfilePreviewModal")
      )
    }
  })

  // TODO: maybe remove here completely, it depends
  // we are currently disabling the Map altogether, but maybe from Chat you can still open
  // and lurk profiles
  // HACK: keep this here for reference, until we figure out, what exactly needs to happen
  const isPaywalled = false

  return {
    openProfilePreview: useCallback(
      async (quickiesProfileId: QuickiesProfileId, forceView = false) => {
        NiceModal.register(
          "QuickiesProfilePreviewModal",
          QuickiesProfilePreviewModal
        )

        if (
          quickiesProfileId !== quickiesSession?.profile.profileId &&
          isPaywalled
        ) {
          // TODO: correct paywall, maybe pass it around?
          // TODO: have some helper that uses the paywall features enum that either does the action
          // or triggers paywall instead - not here but everywhere that triggers
          void openQuickiesPremiumModal(QuickiesPremiumPaywall.enum.map_profile)
          return
        }

        // maybe there's a reminder to upload a photo (if you're not Premium)
        if (
          hasReachedLimit(quickiesProfileId) &&
          quickiesProfileId !== quickiesSession?.profile.profileId &&
          !forceView &&
          !hasPremium
        ) {
          clearCounter()
          void openReachedProfileLimitModal()
          return
        }

        countProfileView(quickiesProfileId)

        if (quickiesSession?.profile.profileId !== quickiesProfileId) {
          void reportEvent(
            QuickiesAnalyticsEvent.mapTapOnGuy(quickiesProfileId)
          )
        } else {
          // report my own profile
          void reportEvent(
            QuickiesAnalyticsEvent.navigationTapOnMyProfile(quickiesProfileId)
          )
        }

        void googleAnalyticsService.trackPageView({
          title: `Profile ${quickiesProfileId}`,
          location: `${appUrl}/profiles/${quickiesProfileId}`,
          path: `/profiles/${quickiesProfileId}`,
        })

        void NiceModal.show("QuickiesProfilePreviewModal", {
          quickiesProfileId,
        })
      },
      [
        quickiesSession?.profile.profileId,
        isPaywalled,
        hasReachedLimit,
        hasPremium,
        countProfileView,
        googleAnalyticsService,
        appUrl,
        openQuickiesPremiumModal,
        clearCounter,
        openReachedProfileLimitModal,
        reportEvent,
      ]
    ),

    closeProfilePreview: useCallback(async (): Promise<boolean> => {
      // shouldn't be necessary because if there is a need to close the modal,
      // it must've been opened in first place and thus the loading and
      // the registering is already done
      // but if not, hiding unregistered modal would throw and this action
      // is idempotent anyway so it doesn't hurt
      NiceModal.register(
        "QuickiesProfilePreviewModal",
        QuickiesProfilePreviewModal
      )

      if (modals["QuickiesProfilePreviewModal"]?.visible === true) {
        void NiceModal.hide("QuickiesProfilePreviewModal")
        return true
      }

      // it's not open, we don't need to close it
      // helps to determine the next step for blocking, reporting...
      return false
    }, [modals]),
  }
}
