import { SlideModal } from "@hornet-web-react/core/components/Modals"
import useTranslation from "next-translate/useTranslation"
import ProfilePhotoUpload from "../ProfilePhotoUpload"
import {
  ProfilePhotoUploadModel,
  ProfilePhotoUploadResult,
} from "../../models/profile-photo-upload.model"
import styled from "styled-components"
import NiceModal from "@ebay/nice-modal-react"
import { ReactNode } from "react"
import TrackEvent from "@hornet-web-react/core/models/track-event"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import EventTrackerService from "@hornet-web-react/core/services/EventTrackerService"
import { CORE_TYPES } from "@hornet-web-react/core/services/types"

type ProfilePhotoUploadModalProps = {
  type: "profile" | "featured" | "private"
  customPhotoPolicy?: ReactNode
  onClose: (
    formData: ProfilePhotoUploadModel | undefined
  ) => Promise<ProfilePhotoUploadResult>
  onSelect?: () => void
  i18nOverride?: {
    closeButton?: string
    uploadButton?: string
    title?: string
    text?: string
    thumbCropTitle?: string
    heroCropTitle?: string
  }
  photoPlaceholderUrl?: string | null // if null, no placeholder is shown
  hasHeroCrop?: boolean
}

const i18nKey = "profile:components.modals.profile_photo_upload_modal"

const ProfilePhotoUploadModal = NiceModal.create(
  ({
    type,
    onClose,
    onSelect,
    customPhotoPolicy,
    photoPlaceholderUrl,
    hasHeroCrop,
    i18nOverride,
  }: ProfilePhotoUploadModalProps) => {
    const { t } = useTranslation()
    const modal = NiceModal.useModal()
    const eventTrackerService = useCoreService<EventTrackerService>(
      CORE_TYPES.EventTrackerService
    )

    const closeAction = {
      label:
        i18nOverride?.closeButton || (t(`${i18nKey}.close_button`) as string),
      onClick: () => onClose(undefined),
    }

    return (
      <SlideModal
        title={i18nOverride?.title || t(`${i18nKey}.${type}_title`)}
        isOpen={modal.visible}
        closeAction={closeAction}
      >
        {i18nOverride?.text ? <Paragraph>{i18nOverride.text}</Paragraph> : null}

        <ProfilePhotoUpload
          onUpload={onClose}
          onSelect={() => {
            void eventTrackerService.report(
              TrackEvent.welcomeTapAddProfilePhoto()
            )

            if (typeof onSelect === "function") {
              onSelect()
            }
          }}
          customPhotoPolicy={customPhotoPolicy}
          photoPlaceholderUrl={photoPlaceholderUrl}
          hasHeroCrop={hasHeroCrop}
          addPhotoButtonLabel={i18nOverride?.uploadButton}
          thumbCropTitle={i18nOverride?.thumbCropTitle}
          heroCropTitle={i18nOverride?.heroCropTitle}
        />
      </SlideModal>
    )
  }
)

export default ProfilePhotoUploadModal

const Paragraph = styled.p`
  margin: ${({ theme }) => theme.spacing.regular} 0
    ${({ theme }) => theme.spacing.half};
  text-align: center;
`
