import {
  updateQuickiesSessionLocally,
  useQuickiesSession,
} from "./use-quickies-session"
import { useCallback, useEffect, useState } from "react"
import QuickiesMemberModel from "../models/quickies-member.model"
import { updateQuickiesProfileLocally } from "./use-quickies-member"
import {
  ProfilePhotoMember,
  useProfilePhotoActions,
} from "@hornet-web-react/profile/hooks/use-profile-photo-actions"
import { ApiServiceEndpoint } from "@hornet-web-react/core/services/API/ApiServiceEndpoint"
import EventEmitter from "eventemitter3"
import { ProfilePhotoUploadModel } from "@hornet-web-react/profile/models/profile-photo-upload.model"
import MemberPhotoModel, {
  MemberPhotoCreateApiPayload,
} from "@hornet-web-react/core/models/member-photo.model"
import { useEventTrackerService } from "./use-event-tracker-service"
import QuickiesAnalyticsEvent from "../models/quickies-analytics-event"
import {
  HornetPrivatePhotoId,
  QuickiesPrivatePhotosFromHornetCreateApiPayload,
} from "../models/quickies-private-photos-from-hornet.model"
import TeapotMessageError from "@hornet-web-react/core/services/API/Errors/TeapotMessageError"
import {
  isRight,
  makeLeft,
  makeRight,
  unwrapEither,
} from "@hornet-web-react/core/utils"
import CustomApiError from "@hornet-web-react/core/services/API/Errors/CustomApiError"
import { useLoggerService } from "@hornet-web-react/core/hooks/use-logger-service"
import { useApi } from "@hornet-web-react/core/hooks/use-api"

const eventEmitter = new EventEmitter()

export function useQuickiesProfilePhotoActions() {
  const { data: session, mutate: mutateSession } = useQuickiesSession()
  const { reportEvent } = useEventTrackerService()
  const { logExceptionWithSentry, logMessageWithSentry, createLoggingContext } =
    useLoggerService()
  const { makeApiRequest, getEndpoint } = useApi()
  const [hasCheckForProfilePhoto, setHasCheckForProfilePhoto] = useState(false)

  const emitSessionPhotoUpdated = () => eventEmitter.emit("sessionPhotoUpdated")

  // check after using private photo from Hornet as a profile photo, whether the API actually
  // returned that new photo or whether it returned stale data
  useEffect(() => {
    if (hasCheckForProfilePhoto) {
      if (session && !session.profile.hasCustomProfilePhoto) {
        logMessageWithSentry(
          "Profile photo not updated after using private photo from Hornet",
          createLoggingContext({
            hook: "useQuickiesProfilePhotoActions",
            step: "hasCheckForProfilePhoto",
            currentProfilePhotoUrl: session.profile.profilePhoto?.squareUrl,
          })
        )
      }
      setHasCheckForProfilePhoto(false)
    }
  }, [
    createLoggingContext,
    hasCheckForProfilePhoto,
    logMessageWithSentry,
    session,
  ])

  // photo action needs to update both profile (for underlying profile modal) but also
  // session profile (for the current edit profile modal)
  const updateProfileLocally = useCallback(
    async (member: QuickiesMemberModel) => {
      void updateQuickiesProfileLocally(member)

      if (session) {
        const profileWithNewPhotos = session.profile.usePhotosFromMember(member)
        void updateQuickiesSessionLocally(
          session.updateProfile(profileWithNewPhotos)
        )
        emitSessionPhotoUpdated()
      }
    },
    [session]
  )

  // {
  //     deletePhoto,
  //     makePhotoPrivate,
  //     makePhotoPublic,
  //     setAsProfilePhoto,
  //     handleProfilePhotoUpload,
  //     handleFeaturedPhotoUpload,
  //     handlePrivatePhotoUpload,
  //   }
  const profilePhotoActions = useProfilePhotoActions(updateProfileLocally, {
    PhotosDelete: ApiServiceEndpoint.QuickiesPhotosDelete,
    PhotosSlotsPut: ApiServiceEndpoint.QuickiesPhotosSlotsPut,
    PhotosPut: ApiServiceEndpoint.QuickiesPhotosPut,
    PhotosPost: ApiServiceEndpoint.QuickiesPhotosPost,
  })

  // now override some with added analytics

  return {
    ...profilePhotoActions,

    handleProfilePhotoUpload: useCallback(
      async (
        member: ProfilePhotoMember<QuickiesMemberModel>,
        formData: ProfilePhotoUploadModel
      ) => {
        void reportEvent(QuickiesAnalyticsEvent.profileTapOnUploadPhoto(0))

        return profilePhotoActions.handleProfilePhotoUpload(member, formData)
      },
      [profilePhotoActions, reportEvent]
    ),

    handleFeaturedPhotoUpload: useCallback(
      async (
        member: ProfilePhotoMember<QuickiesMemberModel>,
        slot: number,
        formData: ProfilePhotoUploadModel
      ) =>
        profilePhotoActions.handleFeaturedPhotoUpload(member, slot, formData),
      [profilePhotoActions]
    ),

    deletePhoto: useCallback(
      async (
        member: ProfilePhotoMember<QuickiesMemberModel>,
        photo: MemberPhotoModel
      ) => {
        void reportEvent(QuickiesAnalyticsEvent.profileTapOnDeletePhoto())

        return profilePhotoActions.deletePhoto(member, photo)
      },
      [profilePhotoActions, reportEvent]
    ),

    eventEmitter,
    emitSessionPhotoUpdated,

    uploadPrivatePhotoFromHornet: useCallback(
      async (privatePhotoId: HornetPrivatePhotoId) => {
        const apiResult = await makeApiRequest<MemberPhotoCreateApiPayload>(
          getEndpoint(
            ApiServiceEndpoint.QuickiesPrivatePhotosFromHornetPost,
            []
          ),
          QuickiesPrivatePhotosFromHornetCreateApiPayload.parse({
            photo_id: Number(privatePhotoId),
            set_as_profile_photo: true,
          }),
          (error) => {
            // [418, 460, 461].includes(response.status)
            if (error instanceof TeapotMessageError) {
              return makeLeft({
                title: error.teapotTitle,
                message: error.teapotMessage,
              })
            } else if (
              error instanceof CustomApiError &&
              error.status === 400
            ) {
              return makeLeft({
                message: error.message,
              })
            } else {
              if (error instanceof Error) {
                logExceptionWithSentry(
                  error,
                  createLoggingContext({
                    hook: "useQuickiesPrivatePhotosFromHornet",
                    step: "uploadPrivatePhotoFromHornet",
                  })
                )

                return makeLeft({
                  message: error.message,
                })
              }

              return makeLeft({
                message: "unknown",
              })
            }
          }
        )

        if (isRight(apiResult)) {
          // this request doesn't return any data, so we just need to refresh the session
          // and check if the updated profile data is there
          const result = unwrapEither(apiResult)

          // if we have session, we can just update locally (which is faster)
          // with the result and move on
          if (session) {
            await updateProfileLocally(
              session.profile.addPhoto(
                new MemberPhotoModel({
                  photo: {
                    id: result.photo.id,
                    state: result.photo.state,
                    slot: result.photo.slot,
                    is_public: result.photo.public,
                    url: result.photo.photo.cropped_noretina.url,
                    full_url: result.photo.photo.cropped_retina.url,
                    full_large_url: result.photo.photo.cropped_retina.url,
                    thumbnail_url: result.photo.photo.thumbnail_noretina.url,
                    thumbnail_large_url:
                      result.photo.photo.thumbnail_retina.url,
                    square_url: result.photo.photo.square_retina.url,
                    v6_full_url: result.photo.photo.v6_cropped_retina.url,
                  },
                })
              )
            )

            return makeRight(true)
          }

          // otherwise, wait a bit for API and just do hard refresh
          await new Promise((r) => setTimeout(r, 2000))

          await mutateSession()

          setHasCheckForProfilePhoto(true)

          return makeRight(true)
        }

        return apiResult
      },
      [
        createLoggingContext,
        getEndpoint,
        logExceptionWithSentry,
        makeApiRequest,
        mutateSession,
        session,
        updateProfileLocally,
      ]
    ),
  }
}
