import useTranslation from "next-translate/useTranslation"
import NiceModal from "@ebay/nice-modal-react"
import InfoSheetModal from "./InfoSheetModal"
import { useModal } from "@hornet-web-react/core/hooks/use-modal"
import { useQuickiesNavigation } from "../../hooks/use-quickies-navigation"

const i18nKey =
  "quickies:components.modals.quickies_shop_purchase_success_modal"

const QuickiesShopPurchaseSuccessModal = NiceModal.create(() => {
  const { t } = useTranslation()
  const { createAction } = useModal()
  const { navigateHome } = useQuickiesNavigation()
  const modal = NiceModal.useModal()

  return (
    <InfoSheetModal
      modalName={"ShopPurchaseSuccessModal"}
      closeAction={createAction(
        {
          label: " ",
          onClick: async () => {
            await navigateHome()
            void modal.hide()
          },
        },
        "ShopPurchaseSuccessModal"
      )}
    >
      {({ modal }) => (
        <>
          <InfoSheetModal.Heading>
            {t(`${i18nKey}.heading`)}
          </InfoSheetModal.Heading>

          <InfoSheetModal.Paragraph>
            {t(`${i18nKey}.text`)}
          </InfoSheetModal.Paragraph>

          <InfoSheetModal.SubmitButton
            primary={true}
            onClick={async () => {
              await navigateHome()
              void modal.hide()
            }}
          >
            {t(`${i18nKey}.cta_button`)}
          </InfoSheetModal.SubmitButton>
        </>
      )}
    </InfoSheetModal>
  )
})

export default QuickiesShopPurchaseSuccessModal
