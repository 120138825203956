import { ApiServiceEndpoint } from "@hornet-web-react/core/services/API/ApiServiceEndpoint"
import { removeCookie } from "typescript-cookie"
import { CookieName } from "@hornet-web-react/core/types"
import { useCallback, useState } from "react"
import { useQuickiesRealtimeProfiles } from "./use-quickies-realtime-profiles"
import { useApi } from "@hornet-web-react/core/hooks/use-api"
import { useSessionApi } from "@hornet-web-react/core/contexts/session"
import { debug } from "@hornet-web-react/core/utils"

export const useQuickiesLogout = () => {
  const { markAsOffline } = useQuickiesRealtimeProfiles()
  const { makeApiRequest, getEndpoint } = useApi()
  const { user } = useSessionApi()
  const [isLoggingOut, setIsLoggingOut] = useState(false)

  const logoutAction = useCallback(
    async (targetLocation = "/") => {
      debug(
        `useQuickiesLogout: logoutAction: targetLocation: ${targetLocation}`
      )

      setIsLoggingOut(true)

      // until API amends DELETE /session endpoint with setting the profile offline
      // we need to do it manually
      await markAsOffline()

      // then delete session
      await makeApiRequest(getEndpoint(ApiServiceEndpoint.SessionDelete))

      user.logout()

      // delete reminders
      removeCookie(CookieName.PostNudes)
      removeCookie(CookieName.IgnoreNotifications)

      // hard refresh to clean up any SWR cache etc
      window.location.href = targetLocation
    },
    [getEndpoint, makeApiRequest, markAsOffline, user]
  )

  return { logoutAction, isLoggingOut }
}
