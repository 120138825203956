import useTranslation from "next-translate/useTranslation"
import NiceModal from "@ebay/nice-modal-react"
import SlideModal from "@hornet-web-react/core/components/Modals/SlideModal"
import styled from "styled-components"
import PageQuickiesProfile from "../Pages/PageQuickiesProfile"
import Spinner from "@hornet-web-react/core/components/UI/Spinner"
import { useQuickiesMember } from "../../hooks/use-quickies-member"
import { QuickiesProfileId } from "@hornet-web-react/core/types"
import CloseModalButton from "@hornet-web-react/core/components/Modals/CloseModalButton"
import CustomModalScrollableArea from "@hornet-web-react/core/components/Modals/CustomModalScrollableArea"
import { useModal } from "@hornet-web-react/core/hooks/use-modal"

type QuickiesProfilePreviewModalProps = {
  quickiesProfileId: QuickiesProfileId
}

const i18nKey = "quickies:components.modals.profile_preview_modal"

const QuickiesProfilePreviewModal = NiceModal.create(
  ({ quickiesProfileId }: QuickiesProfilePreviewModalProps) => {
    const { t } = useTranslation()
    const modal = NiceModal.useModal()
    const { createAction } = useModal()
    const { data: profile } = useQuickiesMember(quickiesProfileId)

    const closeAction = createAction(
      {
        label: t(`${i18nKey}.close_button`),
        onClick: async () => {
          await modal.hide()
          modal.remove()
        },
      },
      "ProfilePreviewModal"
    )

    return (
      <SlideModal
        title={t(`${i18nKey}.title`)}
        isCustom={true}
        isFullscreen={true}
        hasNavigation={false}
        isOpen={modal.visible}
        closeAction={closeAction}
      >
        <ContentWrapper>
          <CloseModalButton onClick={closeAction.onClick} />

          <CustomModalScrollableArea>
            {profile ? (
              <PageQuickiesProfile
                quickiesProfileId={quickiesProfileId}
                hornetProfileId={profile.hornetProfileId}
              />
            ) : (
              <Spinner isFullscreen={true} />
            )}
          </CustomModalScrollableArea>
        </ContentWrapper>
      </SlideModal>
    )
  }
)

export default QuickiesProfilePreviewModal

const ContentWrapper = styled.div`
  position: relative;
  background: ${({ theme }) => theme.color.bg.light03};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: flex;
  overflow: auto;
  flex: 1;

  @media screen and ${({ theme }) => theme.breakpoints.device.gtXs} {
    border-top-left-radius: ${({ theme }) => theme.borderRadius.modal};
    border-top-right-radius: ${({ theme }) => theme.borderRadius.modal};
  }
`
