import { FC, ReactNode } from "react"
import TextButton from "@hornet-web-react/core/components/UI/TextButton"
import styled from "styled-components"
import Message from "@hornet-web-react/core/components/UI/Message"
import useTranslation from "next-translate/useTranslation"

type RetryLoadingMessageProps = {
  onReload: () => void
  message?: ReactNode
}

const i18nKey = "quickies:components.layout.retry_loading_message"

const RetryLoadingMessage: FC<RetryLoadingMessageProps> = ({
  onReload,
  message,
}) => {
  const { t } = useTranslation()

  return (
    <Wrapper context={"error"}>
      <p>{message ?? t(`${i18nKey}.message`)}</p>

      <TextButton
        data-test-id="retry-loading-message-retry-btn"
        onClick={onReload}
        size={"small"}
      >
        {t(`${i18nKey}.button_label`)}
      </TextButton>
    </Wrapper>
  )
}

export default RetryLoadingMessage

const Wrapper = styled(Message)`
  flex-direction: column;

  p {
    margin-bottom: ${({ theme }) => theme.spacing.regular};
  }
`
