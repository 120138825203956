import styled, { css } from "styled-components"

const defaultStyle = css`
  background-color: ${({ theme }) => theme.color.bg.focused};
  color: ${({ theme }) => theme.color.text.primary};
`
const errorStyle = css`
  background: ${({ theme }) => theme.color.bg.error};
  color: ${({ theme }) => theme.color.text.error};
`

const successStyle = css`
  background: ${({ theme }) => theme.color.bg.success};
  color: ${({ theme }) => theme.color.text.success};
`

interface MessageProps {
  readonly context?: string
}

const Message = styled.div<MessageProps>`
  ${({ theme }) => theme.font.regular.subheadline};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  padding: ${({ theme }) => theme.spacing.less};
  width: 100%;
  text-align: center;
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing.regular};
  word-wrap: break-word;
  max-width: 100%;

  ${(props) => props.context === "error" && errorStyle};
  ${(props) => props.context === "success" && successStyle};
  ${(props) => props.context === "info" && defaultStyle};

  @media screen and ${({ theme }) => theme.breakpoints.device.gtXs} {
    padding: ${({ theme }) => theme.spacing.regular};
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export default Message
