import { FC, MouseEvent } from "react"
import QuickiesMemberModel from "../../models/quickies-member.model"
import { useQuickiesLookupData } from "../../hooks/use-quickies-lookup-data"
import invariant from "tiny-invariant"
import NiceModal from "@ebay/nice-modal-react"
import { prop } from "ramda"
import QuickiesAnalyticsEvent from "../../models/quickies-analytics-event"
import { HornetProfileId } from "@hornet-web-react/core/types"
import {
  EllipsisHorizontalIcon,
  FlagIcon,
  NoSymbolIcon,
} from "@heroicons/react/24/solid"
import IconButton from "@hornet-web-react/core/components/UI/IconButton"
import { useSocialInteractions } from "@hornet-web-react/core/hooks/use-social-interactions"
import appConfig from "../../app-config"
import { useEventTrackerService } from "../../hooks/use-event-tracker-service"
import useTranslation from "next-translate/useTranslation"
import { useQuickiesProfilePreview } from "../../hooks/use-quickies-profile-preview"
import { useQuickiesMemberDefaultDisplayName } from "../../hooks/use-quickies-member"
import { useLazyLoaded } from "@hornet-web-react/core/hooks/use-lazy-loaded"

type QuickiesProfileEllipsisActionsProps = {
  member: QuickiesMemberModel
  isCurrentUser?: boolean
}

const i18nKey =
  "quickies:components.quickies_profile.quickies_profile_ellipsis_actions"

const QuickiesProfileEllipsisActions: FC<
  QuickiesProfileEllipsisActionsProps
> = ({ isCurrentUser, member }) => {
  const { t } = useTranslation()
  const { data: lookupData } = useQuickiesLookupData()
  const { blockMember, reportQuickiesMember } = useSocialInteractions(
    appConfig.googleRecaptchaV3.siteKey
  )
  const { reportEvent } = useEventTrackerService()
  const { closeProfilePreview } = useQuickiesProfilePreview()
  const quickiesMemberDefaultDisplayName = useQuickiesMemberDefaultDisplayName()
  const { get: getActionSheetModal } = useLazyLoaded(
    () => import("@hornet-web-react/core/components-lazy/ActionSheetModal")
  )
  const { get: getConfirmModal } = useLazyLoaded(
    () => import("@hornet-web-react/core/components-lazy/ConfirmModal")
  )
  const { get: getReportProfileModal } = useLazyLoaded(
    () => import("@hornet-web-react/core/components-lazy/ReportProfileModal")
  )

  const hasEllipsis =
    !isCurrentUser &&
    member.profileId &&
    !!lookupData?.quickies_report_reasons.length

  if (!hasEllipsis) {
    return null
  }

  const blockMemberAction = async (profileId: HornetProfileId) => {
    await blockMember(profileId)
  }

  const actionSheetModalItems = [
    {
      id: "block",
      icon: <NoSymbolIcon width={20} />,
      label: t(`${i18nKey}.profile_actions.actions.block`, {
        name: member.displayName || quickiesMemberDefaultDisplayName,
      }),
    },
    {
      id: "report",
      icon: <FlagIcon width={20} />,
      label: t(`${i18nKey}.profile_actions.actions.report`, {
        name: member.displayName || quickiesMemberDefaultDisplayName,
      }),
      isDanger: true,
    },
  ]

  const showActionSheetModal = async (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()

    void getActionSheetModal((modal) =>
      NiceModal.show(modal, {
        title: t(`${i18nKey}.profile_actions.title`),
        items: actionSheetModalItems,
      }).then((result: unknown) => {
        if (result === "block") {
          return showConfirmBlockModal()
        }

        // if (result === "share") {
        //   return showShareProfileModal()
        // }

        if (result === "report") {
          return showReportProfileModal()
        }
      })
    )
  }

  const showReportProfileModal = async () => {
    invariant(member.profileId, "profileId is required")
    invariant(lookupData, "lookupData is required")

    void getReportProfileModal((modal) =>
      NiceModal.show(modal, {
        headingMemberName:
          member.displayName || quickiesMemberDefaultDisplayName,
        reportReasons:
          lookupData.quickies_report_reasons.map(
            prop("quickies_report_reason")
          ) || [],
        reportMember: (reasonId: string, comments: string) =>
          reportQuickiesMember(member.profileId, reasonId, comments),
      }).then(async () => {
        void reportEvent(QuickiesAnalyticsEvent.guyTapOnReport())

        // navigate away from the profile
        void closeProfilePreview()
      })
    )
  }

  const showConfirmBlockModal = async () =>
    getConfirmModal((modal) =>
      NiceModal.show(modal, {
        isDangerous: true,
        question: t(`${i18nKey}.profile_actions.actions.block_question`, {
          name: member.displayName || quickiesMemberDefaultDisplayName,
        }),
      }).then(() => {
        if (member.hornetProfileId) {
          blockMemberAction(member.hornetProfileId)
        }
      })
    )

  return (
    <IconButton type={"button"} onClick={showActionSheetModal}>
      <EllipsisHorizontalIcon width={24} />
    </IconButton>
  )
}

export default QuickiesProfileEllipsisActions
