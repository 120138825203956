import React, { useCallback, useState } from "react"
import QuickiesAnalyticsEvent from "../models/quickies-analytics-event"
import { ApiServiceEndpoint } from "@hornet-web-react/core/services/API/ApiServiceEndpoint"
import { isRight, unwrapEither } from "@hornet-web-react/core/utils"
import { reloadQuickiesGroup } from "./use-quickies-group"
import { ApiRequestResult, useApi } from "@hornet-web-react/core/hooks/use-api"
import { useLoggerService } from "@hornet-web-react/core/hooks/use-logger-service"
import { useFlashMessage } from "@hornet-web-react/core/hooks/use-flash-message"
import { useQuickiesGroups } from "./use-quickies-groups"
import { useEventTrackerService } from "./use-event-tracker-service"
import {
  ActionSheetModalItemType,
  QuickiesGroupId,
  QuickiesGroupMembershipId,
} from "@hornet-web-react/core/types"
import invariant from "tiny-invariant"
import { Cog6ToothIcon, TrashIcon } from "@heroicons/react/24/outline"
import NiceModal from "@ebay/nice-modal-react"
import { QuickiesGroupModel } from "../models/quickies-group.model"
import useTranslation from "next-translate/useTranslation"
import { useQuickiesNavigation } from "./use-quickies-navigation"
import { useLazyLoaded } from "@hornet-web-react/core/hooks/use-lazy-loaded"

const i18nKey = `quickies:hooks.use_quickies_group_actions`

export function useQuickiesGroupActions() {
  const { t } = useTranslation()
  const { getEndpoint, makeApiRequest } = useApi()
  const { logExceptionWithSentry, createLoggingContext } = useLoggerService()
  const { showOops } = useFlashMessage()
  const { mutate: reloadGroups } = useQuickiesGroups()
  const { reportEvent } = useEventTrackerService()
  const { navigateHome } = useQuickiesNavigation()
  const { get: getActionSheetModal } = useLazyLoaded(
    () => import("@hornet-web-react/core/components-lazy/ActionSheetModal")
  )
  const { get: getConfirmModal } = useLazyLoaded(
    () => import("@hornet-web-react/core/components-lazy/ConfirmModal")
  )
  const { get: getGroupFormModal } = useLazyLoaded(
    () => import("../components/Modals/GroupFormModal")
  )

  const [isAskingToJoin, setIsAskingToJoin] = useState(false)
  const [isAskingToJoinDone, setIsAskingToJoinDone] = useState(false)
  const [isCancellingRequest, setIsCancellingRequest] = useState(false)
  const [isCancellingRequestDone, setIsCancellingRequestDone] = useState(false)
  const [isAcceptingInvite, setIsAcceptingInvite] = useState(false)
  const [isAcceptingInviteDone, setIsAcceptingInviteDone] = useState(false)
  const [isDecliningInvite, setIsDecliningInvite] = useState(false)
  const [isDecliningInviteDone, setIsDecliningInviteDone] = useState(false)
  const [isLeavingGroup, setIsLeavingGroup] = useState(false)
  const [isLeavingGroupDone, setIsLeavingGroupDone] = useState(false)
  const [isDeletingGroup, setIsDeletingGroup] = useState(false)
  const [isDeletingGroupDone, setIsDeletingGroupDone] = useState(false)

  const handleResponse = useCallback(
    async (
      apiResult: ApiRequestResult<unknown>,
      quickiesGroupId: QuickiesGroupId,
      logStep: string,
      setIsSubmitting: (isSubmitting: boolean) => void,
      setIsDone: (isDone: boolean) => void
    ) => {
      setIsSubmitting(false)

      if (isRight(apiResult)) {
        setIsDone(true)

        await new Promise((resolve) => setTimeout(resolve, 1000))

        // reload current group in preview
        if (logStep !== "handleDeleteGroup") {
          await reloadQuickiesGroup(quickiesGroupId)
        }
        setIsDone(false)

        // also reload the dashboard
        await reloadGroups()

        return true
      }

      const error = unwrapEither(apiResult)

      if (error instanceof Error) {
        logExceptionWithSentry(
          error,
          createLoggingContext({
            hook: "useQuickiesGroupActions",
            step: logStep,
            quickiesGroupId,
          })
        )
      }

      void showOops(error)
    },
    [createLoggingContext, logExceptionWithSentry, reloadGroups, showOops]
  )

  const deleteGroup = useCallback(
    async (quickiesGroupId: QuickiesGroupId) => {
      void reportEvent(
        QuickiesAnalyticsEvent.groupPreviewModalTapOnDeleteGroup(
          quickiesGroupId
        )
      )
      setIsDeletingGroup(true)

      const apiResult = await makeApiRequest(
        getEndpoint(ApiServiceEndpoint.QuickiesDestroyGroupDelete, [
          quickiesGroupId,
        ])
      )

      return handleResponse(
        apiResult,
        quickiesGroupId,
        "handleDeleteGroup",
        setIsDeletingGroup,
        setIsDeletingGroupDone
      )
    },
    [getEndpoint, handleResponse, makeApiRequest, reportEvent]
  )

  return {
    handleAskToJoin: useCallback(
      async (quickiesGroupId: QuickiesGroupId) => {
        void reportEvent(
          QuickiesAnalyticsEvent.groupPreviewModalTapOnAskToJoin(
            quickiesGroupId
          )
        )
        setIsAskingToJoin(true)

        const apiResult = await makeApiRequest(
          getEndpoint(ApiServiceEndpoint.QuickiesRequestToJoinGroupPost, [
            quickiesGroupId,
          ])
        )

        return handleResponse(
          apiResult,
          quickiesGroupId,
          "handleAskToJoin",
          setIsAskingToJoin,
          setIsAskingToJoinDone
        )
      },
      [getEndpoint, handleResponse, makeApiRequest, reportEvent]
    ),

    handleCancelRequest: useCallback(
      async (
        quickiesGroupId: QuickiesGroupId,
        quickiesGroupMembershipId: QuickiesGroupMembershipId
      ) => {
        void reportEvent(
          QuickiesAnalyticsEvent.groupPreviewModalTapOnCancelRequest(
            quickiesGroupId
          )
        )
        setIsCancellingRequest(true)

        const apiResult = await makeApiRequest(
          getEndpoint(ApiServiceEndpoint.QuickiesCancelRequestToJoinGroupPut, [
            quickiesGroupId,
            quickiesGroupMembershipId,
          ])
        )

        return handleResponse(
          apiResult,
          quickiesGroupId,
          "handleCancelRequest",
          setIsCancellingRequest,
          setIsCancellingRequestDone
        )
      },
      [getEndpoint, handleResponse, makeApiRequest, reportEvent]
    ),

    handleAcceptInvite: useCallback(
      async (
        quickiesGroupId: QuickiesGroupId,
        quickiesGroupMembershipId: QuickiesGroupMembershipId
      ) => {
        void reportEvent(
          QuickiesAnalyticsEvent.groupPreviewModalTapOnAcceptInvite(
            quickiesGroupId
          )
        )
        setIsAcceptingInvite(true)

        const apiResult = await makeApiRequest(
          getEndpoint(ApiServiceEndpoint.QuickiesAcceptInviteToJoinGroupPut, [
            quickiesGroupId,
            quickiesGroupMembershipId,
          ])
        )

        return handleResponse(
          apiResult,
          quickiesGroupId,
          "handleAcceptInvite",
          setIsAcceptingInvite,
          setIsAcceptingInviteDone
        )
      },
      [getEndpoint, handleResponse, makeApiRequest, reportEvent]
    ),

    handleDeclineInvite: useCallback(
      async (
        quickiesGroupId: QuickiesGroupId,
        quickiesGroupMembershipId: QuickiesGroupMembershipId
      ) => {
        void reportEvent(
          QuickiesAnalyticsEvent.groupPreviewModalTapOnDeclineInvite(
            quickiesGroupId
          )
        )
        setIsDecliningInvite(true)

        const apiResult = await makeApiRequest(
          getEndpoint(ApiServiceEndpoint.QuickiesDeclineInviteToJoinGroupPut, [
            quickiesGroupId,
            quickiesGroupMembershipId,
          ])
        )

        return handleResponse(
          apiResult,
          quickiesGroupId,
          "handleDeclineInvite",
          setIsDecliningInvite,
          setIsDecliningInviteDone
        )
      },
      [getEndpoint, handleResponse, makeApiRequest, reportEvent]
    ),

    handleLeaveGroup: useCallback(
      async (
        quickiesGroupId: QuickiesGroupId,
        quickiesGroupMembershipId: QuickiesGroupMembershipId
      ) => {
        void reportEvent(
          QuickiesAnalyticsEvent.groupPreviewModalTapOnLeaveGroup(
            quickiesGroupId
          )
        )
        setIsLeavingGroup(true)

        const apiResult = await makeApiRequest(
          getEndpoint(ApiServiceEndpoint.QuickiesLeaveGroupDelete, [
            quickiesGroupId,
            quickiesGroupMembershipId,
          ])
        )

        return handleResponse(
          apiResult,
          quickiesGroupId,
          "handleLeaveGroup",
          setIsLeavingGroup,
          setIsLeavingGroupDone
        )
      },
      [getEndpoint, handleResponse, makeApiRequest, reportEvent]
    ),

    deleteGroup,

    isAskingToJoin,
    isAskingToJoinDone,
    isCancellingRequest,
    isCancellingRequestDone,
    isAcceptingInvite,
    isAcceptingInviteDone,
    isDecliningInvite,
    isDecliningInviteDone,
    isLeavingGroup,
    isLeavingGroupDone,
    isDeletingGroup,
    isDeletingGroupDone,

    handleGroupMoreActionsOnClick: useCallback(
      async (quickiesGroup: QuickiesGroupModel) => {
        invariant(quickiesGroup.isCurrentUserHosting, "user must be hosting")

        const actionSheetModalItems: ActionSheetModalItemType[] = [
          {
            id: "edit_group",
            icon: <Cog6ToothIcon width={20} />,
            label: t(`${i18nKey}.actions.edit_group`),
          },
          {
            id: "delete_group",
            icon: <TrashIcon width={20} />,
            label: t(`${i18nKey}.actions.delete_group`),
            isDanger: true,
          },
        ]

        void getActionSheetModal((modal) =>
          NiceModal.show(modal, {
            items: actionSheetModalItems,
          }).then(async (result) => {
            if (result === "edit_group") {
              void reportEvent(
                QuickiesAnalyticsEvent.groupPreviewModalTapOnEditGroup(
                  quickiesGroup.groupId
                )
              )

              void getGroupFormModal((modal) =>
                NiceModal.show(modal, {
                  quickiesGroupId: quickiesGroup.groupId,
                }).then(() => reloadQuickiesGroup(quickiesGroup.groupId))
              )

              return
            }

            if (result === "delete_group") {
              void getConfirmModal((modal) =>
                NiceModal.show(modal, {
                  isDangerous: true,
                  question: t(`${i18nKey}.delete_group.question`),
                  confirmLabel: t(`${i18nKey}.delete_group.confirm`),
                  cancelLabel: t(`${i18nKey}.delete_group.cancel`),
                })
                  .then(() => deleteGroup(quickiesGroup.groupId))
                  .then(() => navigateHome())
              )
            }
          })
        )
      },
      [
        t,
        getActionSheetModal,
        reportEvent,
        getGroupFormModal,
        getConfirmModal,
        deleteGroup,
        navigateHome,
      ]
    ),
  }
}
