import { useSessionDevice } from "@hornet-web-react/core/contexts/session"
import { format } from "date-fns"

// TODO: TECH DEBT: all this loads to the client, could save some kBs here
import { enUS, uk, de, id, zhTW, th, tr, fr, ptBR } from "date-fns/locale"
import { Locale } from "@hornet-web-react/core/types/session"

export type DateFormFn = (date: Date | number, dateFormat: string) => string

export function useLocalizedDateFormat(): { dateFormat: DateFormFn } {
  const { locale } = useSessionDevice()

  let dateFnsLocale = enUS

  switch (locale) {
    case Locale.enum.de: {
      dateFnsLocale = de
      break
    }
    case Locale.enum.uk: {
      dateFnsLocale = uk
      break
    }

    case Locale.enum.id: {
      dateFnsLocale = id
      break
    }

    case Locale.enum.zh: {
      dateFnsLocale = zhTW
      break
    }

    case Locale.enum.th: {
      dateFnsLocale = th
      break
    }

    case Locale.enum.tr: {
      dateFnsLocale = tr
      break
    }

    case Locale.enum.fr: {
      dateFnsLocale = fr
      break
    }

    case Locale.enum.pt: {
      dateFnsLocale = ptBR
      break
    }
  }

  return {
    dateFormat(date: Date | number, dateFormat: string) {
      return format(date, dateFormat, { locale: dateFnsLocale })
    },
  }
}
