import styled from "styled-components"
import NiceModal from "@ebay/nice-modal-react"
import SlideModal from "@hornet-web-react/core/components/Modals/SlideModal"
import React, { useRef } from "react"
import { useModal } from "@hornet-web-react/core/hooks/use-modal"
import CloseModalButtonV2 from "@hornet-web-react/core/components/Modals/CloseModalButtonV2"
import CustomModalScrollableArea from "@hornet-web-react/core/components/Modals/CustomModalScrollableArea"
import { QuickiesGroupId } from "@hornet-web-react/core/types"
import {
  reloadQuickiesGroup,
  useQuickiesGroup,
} from "../../hooks/use-quickies-group"
import Spinner from "@hornet-web-react/core/components/UI/Spinner"
import RetryLoadingMessage from "../Layout/RetryLoadingMessage"
import { useQuickiesGroupActions } from "../../hooks/use-quickies-group-actions"
import IconButton from "@hornet-web-react/core/components/UI/IconButton"
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid"
import GroupDetails from "../Groups/GroupDetails"

// const i18nKey = "quickies:components.modals.group_preview_modal"

type GroupPreviewModalProps = {
  quickiesGroupId: QuickiesGroupId
}

const GroupPreviewModal = NiceModal.create(
  ({ quickiesGroupId }: GroupPreviewModalProps) => {
    const {
      data: quickiesGroup,
      isLoading,
      error,
    } = useQuickiesGroup(quickiesGroupId)
    const { createAction } = useModal()
    const modal = NiceModal.useModal()
    const { handleGroupMoreActionsOnClick } = useQuickiesGroupActions()

    const modalScrollableArea = useRef<HTMLDivElement>(null)

    const closeAction = createAction(
      {
        label: "",
        onClick: () => modal.hide(),
      },
      "GroupPreviewModal"
    )

    return (
      <SlideModal
        title={""}
        isOpen={modal.visible}
        closeAction={closeAction}
        isCustom={true}
        afterClose={() => modal.remove()}
      >
        {quickiesGroup && quickiesGroup.isCurrentUserHosting ? (
          <GroupMoreActionsButton
            type={"button"}
            onClick={() => handleGroupMoreActionsOnClick(quickiesGroup)}
          >
            <EllipsisHorizontalIcon width={24} />
          </GroupMoreActionsButton>
        ) : null}

        <CloseModalButtonV2 onClick={closeAction.onClick} />

        <CustomModalScrollableArea ref={modalScrollableArea}>
          <InnerPadding>
            {isLoading && <Spinner isFullscreen={true} />}

            {error ? (
              <RetryLoadingMessage
                onReload={() => reloadQuickiesGroup(quickiesGroupId)}
              />
            ) : null}

            {quickiesGroup && !error ? (
              <GroupDetails
                quickiesGroup={quickiesGroup}
                onShowOnMap={() => modal.hide()}
              />
            ) : null}
          </InnerPadding>
        </CustomModalScrollableArea>
      </SlideModal>
    )
  }
)

export default GroupPreviewModal

const InnerPadding = styled.div`
  padding: 0 ${({ theme }) => theme.spacing.regular}
    ${({ theme }) => theme.spacing.regular};
`

const GroupMoreActionsButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 60;
`
