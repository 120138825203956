import useImmutableSWR from "swr"
import ApiService from "@hornet-web-react/core/services/API/ApiService"
import { ApiServiceEndpoint } from "@hornet-web-react/core/services/API/ApiServiceEndpoint"
import { useSessionUser } from "@hornet-web-react/core/contexts/session"
import { useCallback, useMemo } from "react"
import {
  QuickiesGroupModel,
  createQuickiesGroupModel,
  QuickiesGroupsDashboardApiPayload,
} from "../models/quickies-group.model"
import { useQuickiesSession } from "./use-quickies-session"
import { compose, identity, map } from "ramda"
import { useApi } from "@hornet-web-react/core/hooks/use-api"

type UseQuickiesGroupsDashboard = {
  hosting_hangouts: QuickiesGroupModel[]
  upcoming_hangouts: QuickiesGroupModel[]
  invited_hangouts: QuickiesGroupModel[]
}

export function useQuickiesGroups() {
  const { isAuthenticated } = useSessionUser()
  const { data: quickiesSession, hasActiveQuickiesProfile } =
    useQuickiesSession()
  const { getAbortableEndpoint, getEndpoint } = useApi()

  const isReady = !!quickiesSession && hasActiveQuickiesProfile

  const { data, isLoading, error, mutate } =
    useImmutableSWR<QuickiesGroupsDashboardApiPayload>(
      isAuthenticated && isReady
        ? ApiService.getEndpoint(ApiServiceEndpoint.QuickiesGroupsDashboardGet)
        : null,
      {
        revalidateIfStale: true,
        revalidateOnFocus: true,
        revalidateOnReconnect: true,
      }
    )

  const dashboard: UseQuickiesGroupsDashboard = useMemo(() => {
    const parsed = QuickiesGroupsDashboardApiPayload.safeParse(data)
    if (parsed.success) {
      const createQuickiesGroupsForDashboard = compose(
        identity,
        map(createQuickiesGroupModel)
      )

      return {
        hosting_hangouts: createQuickiesGroupsForDashboard(
          parsed.data.hosting_hangouts
        ) as QuickiesGroupModel[], // TODO: learn TS better and type the `createQuickiesGroupsForDashboard` function, drop this `as X`
        upcoming_hangouts: createQuickiesGroupsForDashboard(
          parsed.data.upcoming_hangouts
        ) as QuickiesGroupModel[],
        invited_hangouts: createQuickiesGroupsForDashboard(
          parsed.data.invited_hangouts
        ) as QuickiesGroupModel[],
      }
    }

    data && console.error(parsed.error)
    return {
      hosting_hangouts: [],
      upcoming_hangouts: [],
      invited_hangouts: [],
    }
  }, [data])

  return {
    data: dashboard,
    isLoading: isLoading || !isReady,
    error,
    mutate,
    isEmpty:
      !isLoading &&
      data &&
      data.hosting_hangouts.length === 0 &&
      data.upcoming_hangouts.length === 0 &&
      data.invited_hangouts.length === 0,

    markDashboardAsSeen: useCallback(
      () =>
        getAbortableEndpoint(
          getEndpoint(ApiServiceEndpoint.QuickiesGroupsDashboardSeenPut, [])
        ),
      [getAbortableEndpoint, getEndpoint]
    ),
  }
}
