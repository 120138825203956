import { z, ZodType } from "zod"
import { PhotoData } from "@hornet-web-react/core/models/photo-data-model"
import { Either } from "@hornet-web-react/core/utils"
import { SubmitErrorModel } from "@hornet-web-react/core/models/submit-error.model"

export const ProfilePhotoUploadModel = z.object({
  file: z.any() as ZodType<File>,
  thumbData: PhotoData,
  cropData: PhotoData,
})
export type ProfilePhotoUploadModel = z.infer<typeof ProfilePhotoUploadModel>

export type ProfilePhotoUploadResult = Either<SubmitErrorModel, boolean>

export function serializePhotoToFormData(
  data: ProfilePhotoUploadModel,
  isPublic = true,
  slot = 0
): FormData {
  const formData = new FormData()

  formData.append("photo[photo]", data.file)
  formData.append("photo[slot]", String(slot))
  formData.append("photo[public]", isPublic ? "1" : "0")
  formData.append("photo[automatic_slots]", "1")

  formData.append("photo[crop_width]", String(data.cropData.width))
  formData.append("photo[crop_height]", String(data.cropData.height))
  formData.append("photo[crop_x1]", String(data.cropData.x1))
  formData.append("photo[crop_y1]", String(data.cropData.y1))
  formData.append("photo[crop_x2]", String(data.cropData.x2))
  formData.append("photo[crop_y2]", String(data.cropData.y2))

  formData.append("photo[thumb_width]", String(data.thumbData.width))
  formData.append("photo[thumb_height]", String(data.thumbData.height))
  formData.append("photo[thumb_x1]", String(data.thumbData.x1))
  formData.append("photo[thumb_y1]", String(data.thumbData.y1))
  formData.append("photo[thumb_x2]", String(data.thumbData.x2))
  formData.append("photo[thumb_y2]", String(data.thumbData.y2))

  return formData
}
