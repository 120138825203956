import { FC } from "react"
import styled from "styled-components"
import CloseIcon from "../UI/CloseIcon"

type CloseModalButtonProps = {
  onClick: () => void
}

const CloseModalButton: FC<CloseModalButtonProps> = ({ onClick }) => {
  return <CloseModalIcon onClick={onClick} closesModal={true} />
}

export default CloseModalButton

const CloseModalIcon = styled(CloseIcon)`
  z-index: 100;
  width: 50px;
  height: 50px;
  line-height: 26px;
  background: radial-gradient(
    circle at center,
    ${({ theme }) => theme.color.bg.light03} 0,
    ${({ theme }) => theme.color.bg.light03} 50%,
    transparent 51%,
    transparent 100%
  );

  &:hover,
  &:focus {
    background: radial-gradient(
      circle at center,
      ${({ theme }) => theme.color.bg.light03} 0,
      ${({ theme }) => theme.color.bg.light03} 50%,
      transparent 51%,
      transparent 100%
    );
  }
`
