import React, { FC, useCallback, useState } from "react"
import styled from "styled-components"
import useTranslation from "next-translate/useTranslation"
import QuickiesMemberAvatar from "../QuickiesMemberAvatar"
import { QuickiesGroupMembership } from "../../models/quickies-group.model"
import { QuickiesGroupId } from "@hornet-web-react/core/types"
import { useQuickiesProfilePreview } from "../../hooks/use-quickies-profile-preview"
import { useApi } from "@hornet-web-react/core/hooks/use-api"
import { ApiServiceEndpoint } from "@hornet-web-react/core/services/API/ApiServiceEndpoint"
import { useFlashMessage } from "@hornet-web-react/core/hooks/use-flash-message"
import { isRight, unwrapEither } from "@hornet-web-react/core/utils"
import TextButton, {
  BlackButtonStyle,
} from "@hornet-web-react/core/components/UI/TextButton"
import { useQuickiesMemberDefaultDisplayName } from "../../hooks/use-quickies-member"
import { useEventTrackerService } from "../../hooks/use-event-tracker-service"
import QuickiesAnalyticsEvent from "../../models/quickies-analytics-event"

type GroupRequestProps = {
  membership: QuickiesGroupMembership
  groupId: QuickiesGroupId
}

const i18nKey = "quickies:components.groups.group_request"

const GroupRequest: FC<GroupRequestProps> = ({ groupId, membership }) => {
  const { t } = useTranslation()
  const { reportEvent } = useEventTrackerService()
  const { openProfilePreview } = useQuickiesProfilePreview()
  const { getEndpoint, makeApiRequest } = useApi()
  const { showOops } = useFlashMessage()
  const quickiesMemberDefaultDisplayName = useQuickiesMemberDefaultDisplayName()

  const [isAcceptingRequest, setIsAcceptingRequest] = useState(false)
  const [isAcceptingRequestDone, setIsAcceptingRequestDone] = useState(false)
  const [isDecliningRequest, setIsDecliningRequest] = useState(false)
  const [isDecliningRequestDone, setIsDecliningRequestDone] = useState(false)

  const handleAcceptRequestOnClick = useCallback(
    async (membership: QuickiesGroupMembership) => {
      void reportEvent(
        QuickiesAnalyticsEvent.groupPreviewModalTapOnAcceptRequest(groupId)
      )

      setIsAcceptingRequest(true)

      const apiResult = await makeApiRequest(
        getEndpoint(ApiServiceEndpoint.QuickiesAcceptRequestToJoinGroupPut, [
          groupId,
          membership.groupMembershipId,
        ])
      )

      if (isRight(apiResult)) {
        setIsAcceptingRequest(false)
        setIsAcceptingRequestDone(true)

        // await new Promise((resolve) => setTimeout(resolve, 1000))

        // feedback disappears when you refresh the group (payload changes
        // await reloadQuickiesGroup(groupId)

        // just leave it as "spent
        // setIsAcceptingRequestDone(false)
        return
      }

      const error = unwrapEither(apiResult)
      void showOops(error)
      setIsAcceptingRequest(false)
    },
    [getEndpoint, groupId, makeApiRequest, reportEvent, showOops]
  )

  const handleDeclineRequestOnClick = useCallback(
    async (membership: QuickiesGroupMembership) => {
      void reportEvent(
        QuickiesAnalyticsEvent.groupPreviewModalTapOnDeclineRequest(groupId)
      )

      setIsDecliningRequest(true)

      const apiResult = await makeApiRequest(
        getEndpoint(ApiServiceEndpoint.QuickiesRejectRequestToJoinGroupPut, [
          groupId,
          membership.groupMembershipId,
        ])
      )

      if (isRight(apiResult)) {
        setIsDecliningRequest(false)
        setIsDecliningRequestDone(true)

        // await new Promise((resolve) => setTimeout(resolve, 1000))

        // feedback disappears when you refresh the group (payload changes
        // await reloadQuickiesGroup(groupId)

        // just leave it as "spent
        // setIsDecliningRequestDone(false)
        return
      }

      const error = unwrapEither(apiResult)
      void showOops(error)
      setIsDecliningRequest(false)
    },
    [getEndpoint, groupId, makeApiRequest, reportEvent, showOops]
  )

  return (
    <Wrapper>
      <QuickiesMemberAvatar
        avatarUrl={membership.thumbnailUrl}
        onClick={() => openProfilePreview(membership.quickiesProfileId)}
        size={68}
      />

      <Actions>
        <DisplayName>
          {membership.displayName || quickiesMemberDefaultDisplayName}
        </DisplayName>

        {!isDecliningRequestDone && (
          <AcceptButton
            primary={true}
            isLoading={isAcceptingRequest}
            isDone={isAcceptingRequestDone}
            doneLabel={t(`${i18nKey}.labels.accepted`)}
            disabled={isDecliningRequest || isDecliningRequestDone}
            onClick={() => handleAcceptRequestOnClick(membership)}
          >
            {t(`${i18nKey}.actions.accept`)}
          </AcceptButton>
        )}

        {!isAcceptingRequestDone && (
          <DeclineButton
            isLoading={isDecliningRequest}
            isDone={isDecliningRequestDone}
            doneLabel={t(`${i18nKey}.labels.declined`)}
            disabled={isAcceptingRequest || isAcceptingRequestDone}
            onClick={() => handleDeclineRequestOnClick(membership)}
          >
            {t(`${i18nKey}.actions.decline`)}
          </DeclineButton>
        )}
      </Actions>
    </Wrapper>
  )
}

export default GroupRequest

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
  justify-content: flex-start;
  min-width: 0;
  margin-bottom: ${({ theme }) => theme.spacing.more};
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`

const Actions = styled.div`
  flex: 1 1 auto;
  min-width: 0;
`

const DisplayName = styled.div`
  ${({ theme }) => theme.font.regular.body};
  margin-bottom: ${({ theme }) => theme.spacing.regular};
  padding: 0 2px;
`

const AcceptButton = styled(TextButton)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.regular};
`

const DeclineButton = styled(TextButton)`
  width: 100%;
  ${BlackButtonStyle};
`
