import { FC, PropsWithChildren } from "react"
import styled from "styled-components"
import { BlackButton } from "@hornet-web-react/core/components/UI/TextButton"
import GroupStartsAt from "./GroupStartsAt"
import { QuickiesGroupApiPayload } from "../../models/quickies-group.model"
import {
  vanillaModeImageStyle,
  useQuickiesVanillaMode,
} from "../../hooks/use-quickies-vanilla-mode"
import Image from "next/image"
import { useQuickiesGroupDefaultName } from "../../hooks/use-quickies-group"

type GroupBlockProps = PropsWithChildren & {
  //
}

const GroupBlock: FC<GroupBlockProps> = ({ children }) => {
  return <GroupBlockWrapper>{children}</GroupBlockWrapper>
}

type GroupBlockItemProps = PropsWithChildren & {
  title: QuickiesGroupApiPayload["hangout"]["title"]
  startsAt: QuickiesGroupApiPayload["hangout"]["starts_at"]
  thumbnailUrl: string
  onClick?: () => void
}

const GroupBlockItem: FC<GroupBlockItemProps> = ({
  children,
  thumbnailUrl,
  title,
  startsAt,
  onClick,
}) => {
  const { state: isVanillaMode } = useQuickiesVanillaMode()
  const defaultGroupName = useQuickiesGroupDefaultName()

  return (
    <GroupBlockItemWrapper
      onClick={onClick}
      role={onClick ? "button" : "presentation"}
    >
      <GroupBlockItemImageWrapper isVanillaMode={isVanillaMode}>
        <GroupBlockItemImage
          src={thumbnailUrl}
          width={124}
          height={124}
          alt={""}
        />
      </GroupBlockItemImageWrapper>

      <GroupBlockItemContent>
        <div>
          <GroupBlockItemTitle>{title || defaultGroupName}</GroupBlockItemTitle>

          <GroupBlockItemStartsAt>
            <GroupStartsAt startsAt={startsAt} />
          </GroupBlockItemStartsAt>
        </div>

        {children}
      </GroupBlockItemContent>
    </GroupBlockItemWrapper>
  )
}

const GroupBlockItemWrapper = styled.div<Pick<GroupBlockItemProps, "onClick">>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: ${({ theme }) => theme.spacing.more};
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
`

const GroupBlockItemImageWrapper = styled.div<{
  isVanillaMode?: boolean
}>`
  width: 124px;
  height: 124px;
  border-radius: 20px;
  overflow: hidden;
  flex-shrink: 0;

  img {
    ${vanillaModeImageStyle};
  }
`

const GroupBlockItemImage = styled(Image)`
  display: block;
  width: 124px;
  height: 124px;
  border-radius: 20px;
`

const GroupBlockItemContent = styled.div`
  color: ${({ theme }) => theme.color.text.primary};
  flex-grow: 1;
  margin-left: ${({ theme }) => theme.spacing.regular};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing.regular};
  min-width: 0;
`

const GroupBlockItemTitle = styled.div`
  ${({ theme }) => theme.font.bold.headline};
  // override
  font-size: 17px;
`

const GroupBlockItemStartsAt = styled.div`
  ${({ theme }) => theme.font.regular.subheadline};
`

type GroupBlockActionProps = PropsWithChildren & {
  onClick?: () => void
}

const GroupBlockAction: FC<GroupBlockActionProps> = ({ children, onClick }) => {
  return (
    <GroupBlockActionButton onClick={onClick} size={"small"}>
      {children}
    </GroupBlockActionButton>
  )
}

const GroupBlockWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.double};

  &:last-child {
    margin-bottom: 0;
  }
`

const Heading = styled.div`
  ${({ theme }) => theme.font.bold.title2};
  color: ${({ theme }) => theme.color.text.primary};
  margin: 0 0 20px;
`

const GroupBlockActionButton = styled(BlackButton)`
  margin-top: ${({ theme }) => theme.spacing.less};
`

const GroupBlockWithComponents = Object.assign(GroupBlock, {
  Heading,
  Item: GroupBlockItem,
  GroupAction: GroupBlockAction,
})

export default GroupBlockWithComponents
