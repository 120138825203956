import { FC } from "react"
import Link from "next/link"

type HornetHashtagProps = {
  tag?: string
}

const HornetHashtag: FC<HornetHashtagProps> = ({ tag }) => {
  if (!tag) {
    return null
  }

  return (
    <Link href={`/grids/tag/${tag}`} title={`#${tag}`}>
      #{tag}
    </Link>
  )
}

export default HornetHashtag
