import useImmutableSWR, { mutate } from "swr"
import ApiService from "@hornet-web-react/core/services/API/ApiService"
import { ApiServiceEndpoint } from "@hornet-web-react/core/services/API/ApiServiceEndpoint"
import { QuickiesGroupId } from "@hornet-web-react/core/types"
import {
  QuickiesGroupModel,
  createQuickiesGroupModel,
  QuickiesGroupApiPayload,
} from "../models/quickies-group.model"
import { useMemo } from "react"
import useTranslation from "next-translate/useTranslation"

type UseQuickiesGroup = {
  data: QuickiesGroupModel | undefined
  isLoading: boolean
  error: Error | undefined
}

export function useQuickiesGroup(
  quickiesGroupId: QuickiesGroupId | undefined
): UseQuickiesGroup {
  const { data, error } = useImmutableSWR<QuickiesGroupApiPayload>(
    quickiesGroupId
      ? ApiService.getEndpoint(ApiServiceEndpoint.QuickiesViewGroupGet, [
          quickiesGroupId,
        ])
      : null,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  return {
    data: useMemo(() => createQuickiesGroupModel(data), [data]),
    isLoading: !error && !data,
    error,
  }
}

export function reloadQuickiesGroup(quickiesGroupId: QuickiesGroupId) {
  return mutate<QuickiesGroupApiPayload>(
    ApiService.getEndpoint(ApiServiceEndpoint.QuickiesViewGroupGet, [
      quickiesGroupId,
    ])
  )
}

export function useQuickiesGroupDefaultName() {
  const { t } = useTranslation()

  return t("quickies:hooks.use_quickies_group_default_name.default_group_name")
}
