import { z } from "zod"

export const QuickiesUserInvitesApiPayload = z.object({
  quickies_invite: z.object({
    invite_code: z.string(),
    invite_count: z.number(),
  }),
})
export type QuickiesUserInvitesApiPayload = z.infer<
  typeof QuickiesUserInvitesApiPayload
>
