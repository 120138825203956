import { useCallback, useContext } from "react"
import NiceModal from "@ebay/nice-modal-react"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import { TYPES as CORE_TYPES } from "@hornet-web-react/core/services/types"
import QuickiesAnalyticsEvent from "../models/quickies-analytics-event"
import GoogleAnalyticsService from "@hornet-web-react/core/services/GoogleAnalyticsService"
import { useSessionEnvironment } from "@hornet-web-react/core/contexts/session"
import { QuickiesGroupId } from "@hornet-web-react/core/types"
import { useEffectOnce } from "react-use"
import { useEventTrackerService } from "./use-event-tracker-service"
import { useQuickiesNavigation } from "./use-quickies-navigation"
import GroupPreviewModal from "../components/Modals/GroupPreviewModal"

export function useQuickiesGroupPreview() {
  const modals = useContext(NiceModal.NiceModalContext)
  const { appUrl } = useSessionEnvironment()
  const { reportEvent } = useEventTrackerService()
  const googleAnalyticsService = useCoreService<GoogleAnalyticsService>(
    CORE_TYPES.GoogleAnalyticsService
  )
  const { navigateToGroupDetail } = useQuickiesNavigation()

  useEffectOnce(() => {
    // preload the modal
    if ("requestIdleCallback" in window) {
      window.requestIdleCallback(
        async () => import("../components/Modals/GroupPreviewModal")
      )
    }
  })

  return {
    openGroupPreview: useCallback(
      async (
        quickiesGroupId: QuickiesGroupId,
        event: QuickiesAnalyticsEvent
      ) => {
        void reportEvent(event)

        return navigateToGroupDetail(quickiesGroupId)
      },
      [navigateToGroupDetail, reportEvent]
    ),

    openGroupPreviewInModal: useCallback(
      async (quickiesGroupId: QuickiesGroupId) => {
        NiceModal.register("GroupPreviewModal", GroupPreviewModal)

        void reportEvent(QuickiesAnalyticsEvent.mapTapOnGroup(quickiesGroupId))

        void googleAnalyticsService.trackPageView({
          title: `Group ${quickiesGroupId}`,
          location: `${appUrl}/groups/${quickiesGroupId}`,
          path: `/groups/${quickiesGroupId}`,
        })

        void NiceModal.show("GroupPreviewModal", {
          quickiesGroupId,
        })
      },
      [reportEvent, googleAnalyticsService, appUrl]
    ),

    closeGroupPreview: useCallback(async (): Promise<boolean> => {
      // shouldn't be necessary because if there is a need to close the modal,
      // it must've been opened in first place and thus the loading and
      // the registering is already done
      // but if not, hiding unregistered modal would throw and this action
      // is idempotent anyway so it doesn't hurt
      NiceModal.register("GroupPreviewModal", GroupPreviewModal)

      if (modals["GroupPreviewModal"]?.visible === true) {
        void NiceModal.hide("GroupPreviewModal")
        return true
      }

      // it's not open, we don't need to close it
      // helps to determine the next step for blocking, reporting...
      return false
    }, [modals]),
  }
}
