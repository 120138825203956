import device, { size } from "./breakpoints"
import { DefaultTheme } from "styled-components"
import { FontGroup } from "@hornet-web-react/core/types/theme"

const boldTypography = {
  largeTitle: {
    fontWeight: 700,
    fontSize: "34px",
    lineHeight: "41px",
    letterSpacing: "0.37px",
  },
  title1: {
    fontWeight: 700,
    fontSize: "28px",
    lineHeight: "34px",
    letterSpacing: "0.36px",
  },
  title2: {
    fontWeight: 700,
    fontSize: "22px",
    lineHeight: "28px",
    letterSpacing: "0.35px",
  },
  title3: {
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.38px",
  },
  headline: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "22px",
    letterSpacing: "0px",
  },
  body: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "-0.32px",
  },
  callout: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "21px",
    letterSpacing: "-0.32px",
  },
  subheadline: {
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "20px",
    letterSpacing: "-0.5px",
  },
  footnote: {
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    letterSpacing: "-0.08px",
  },
  caption1: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
  },
  caption2: {
    fontWeight: 600,
    fontSize: "11px",
    lineHeight: "13px",
    letterSpacing: "0.06px",
  },
}

const CONTAINER_MAX_WIDTH_MOBILE = 420
const CONTAINER_PANEL_WIDTH = 300 + 16 + 2 // (300 ad unit, 16 padding, 2 border)
export const CONTAINER_WULL_WIDTH =
  CONTAINER_MAX_WIDTH_MOBILE + CONTAINER_PANEL_WIDTH * 2

const theme: DefaultTheme = {
  name: "Quickies",
  color: {
    tint: {
      default: "#f75932",
      light01: "#f75932",
      light02: "#F87251",
      light03: "#00C62A",
      light04: "#63F381",
      light05: "#FFC736",
      // default: "#7032F2",
      // light01: "#7032F2",
      // light02: "#4827A9",
      // light03: "#BC9CFF",
      // light04: "#E6D7FF",
      // light05: "#000000", // TODO
      pressed: "#F87251",
      disabled: "#6E6D76",
    },
    bg: {
      light01: "#0F0F0F",
      light02: "#3E3D48",
      light03: "#000000",
      light04: "#2B2A35",
      focused: "#6E6D76",
      error: "#000000", // HACK: not in official styleguide
      gradient:
        "linear-gradient(109.88deg, #F76132 -3.85%, #FF3B10 115.15%), linear-gradient(0deg, rgba(235, 235, 235, 0.4), rgba(235, 235, 235, 0.4))",
      success: "#3E3D48", // HACK: not official
      input: "#3C3A3F",
      reactionsBubble: "#3E3D48",
      profilePhoto: "#131313",
      topNavBar: "#000000",
    },
    text: {
      primary: "#fff",
      secondary: "#B7B6BB",
      tertiary: "#757575", // HACK: originally #999999 but Lighthouse complains about contrast
      error: "#f44336", // HACK: not in official styleguide
      white: "#ffffff",
      success: "#fff", // HACK: not official
      disabled: "#B7B6BB",
    },
    separator: {
      default: "#dadada", // HACK: originally #C6C6C8 but was too dark
    },
    border: {
      default: "#2B2A35",
      input: "#3C3A3F",
    },
  },
  font: {
    family:
      "-apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
    // define `bold` only, regular is derived by substituting only `fontWeight` with `400`
    bold: boldTypography,
    regular: createRegularFromBoldFonts(boldTypography),
  },
  spacing: {
    regular: `16px`,
    less: `10px`,
    half: `8px`,
    quarter: `4px`,
    more: `24px`,
    double: `32px`,
  },
  boxShadow: {
    contentBox: "0px 0px 20px rgb(0 0 0 / 15%)",
  },
  borderRadius: {
    default: "8px",
    modal: "10px",
  },
  width: {
    containerMaxWidthMobile: `${CONTAINER_MAX_WIDTH_MOBILE}px`,
    containerMaxWidthDesktop: "1080px",
    containerPanelWidth: `${CONTAINER_PANEL_WIDTH}px`,
  },
  height: {
    topNavBar: "58px",
    topNavBarMobile: "58px",
  },
  breakpoints: {
    device: device,
    size: size,
    mediaQueryHasVisibleRightPanel: device.gtLg,
    mediaQueryHasVisibleLeftPanel: device.gtSm,
  },
}

// create `regular` from `bold` by changing the `fontWeight`
function createRegularFromBoldFonts(boldFonts: FontGroup): FontGroup {
  const regularFonts = { ...boldFonts }

  for (const fontItem in regularFonts) {
    regularFonts[fontItem as keyof FontGroup] = {
      ...regularFonts[fontItem as keyof FontGroup],
      fontWeight: 400,
    }
  }

  return regularFonts
}

export default theme
