import { z } from "zod"

export const QuickiesLookupDataItem = z.object({
  id: z.coerce.string(),
  title: z.string(),
  group_title: z.string().nullable(),
})
export type QuickiesLookupDataItem = z.infer<typeof QuickiesLookupDataItem>

export const QuickiesLookupDataApiPayload = z.object({
  body_types: z.array(z.object({ body_type: QuickiesLookupDataItem })),
  sexuality_attitudes: z.array(
    z.object({ sexuality_attitude: QuickiesLookupDataItem })
  ),
  sexuality_expressions: z.array(
    z.object({ sexuality_expression: QuickiesLookupDataItem })
  ),
  sexuality_spectrums: z.array(
    z.object({ sexuality_spectrum: QuickiesLookupDataItem })
  ),
  scene_locations: z.array(
    z.object({ scene_location: QuickiesLookupDataItem })
  ),
  scene_into_publics: z.array(
    z.object({ scene_into_public: QuickiesLookupDataItem })
  ),
  scene_looking_fors: z.array(
    z.object({ scene_looking_for: QuickiesLookupDataItem })
  ),
  scene_fetishes: z.array(z.object({ scene_fetish: QuickiesLookupDataItem })),
  scene_kinks: z.array(z.object({ scene_kink: QuickiesLookupDataItem })),
  scene_intos: z.array(z.object({ scene_into: QuickiesLookupDataItem })),
  scene_interactions: z.array(
    z.object({ scene_interaction: QuickiesLookupDataItem })
  ),
  health_safeguards: z.array(
    z.object({ health_safeguard: QuickiesLookupDataItem })
  ),
  health_protections: z.array(
    z.object({ health_protection: QuickiesLookupDataItem })
  ),
  health_hiv_statuses: z.array(
    z.object({ health_hiv_status: QuickiesLookupDataItem })
  ),
  quickies_report_reasons: z.array(
    z.object({ quickies_report_reason: QuickiesLookupDataItem })
  ),
})
export type QuickiesLookupDataApiPayload = z.infer<
  typeof QuickiesLookupDataApiPayload
>
