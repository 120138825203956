import { z } from "zod"
import {
  BareMessageApiPayload,
  BareMessageModel,
  BaseConversationMember,
  InboxType,
  MessageType,
} from "./messages-conversation.model"
import { ProfileId } from "@hornet-web-react/core/types/session"
import {
  HornetProfileId,
  QuickiesProfileId,
} from "@hornet-web-react/core/types"

export { InboxType }

/**
 * Docs:
 * https://github.com/hornet-network/hornet_docs/blob/master/API/Resources/Message.md
 */

// TODO: maybe also turn this into `HornetProfileId`
export const ConversationId = HornetProfileId
export type ConversationId = z.infer<typeof ConversationId>

export const LastMessageApiPayload = BareMessageApiPayload
export type LastMessageApiPayload = z.infer<typeof LastMessageApiPayload>

export const InboxConversationApiPayload = z.object({
  conversation: z.object({
    profile: BaseConversationMember.merge(
      z.object({
        quickies_profile_id: QuickiesProfileId.nullable().optional(),
        photo: z
          .object({
            full_url: z.string().url(),
            full_large_url: z.string().url(),
            thumbnail_url: z.string().url(),
            thumbnail_large_url: z.string().url(),
          })
          .nullable()
          .optional(), // TODO: remove the `.optional()` once API is fixed
      })
    ),
    last_message: LastMessageApiPayload.nullable(),
    unread_count: z.number(),
    inbox: InboxType,
  }),
})
export type InboxConversationApiPayload = z.infer<
  typeof InboxConversationApiPayload
>

export const InboxConversationsApiPayload = z.object({
  conversations: z.array(InboxConversationApiPayload),
  unread_count: z.number(),
})

export type InboxConversationsApiPayload = z.infer<
  typeof InboxConversationsApiPayload
>

export class InboxConversationModel {
  private readonly payload: InboxConversationApiPayload["conversation"]
  private readonly currentUserProfileId: ProfileId | undefined
  readonly lastMessage: BareMessageModel | undefined

  constructor(
    payload: InboxConversationApiPayload,
    currentUserProfileId?: ProfileId
  ) {
    this.payload = payload.conversation
    this.currentUserProfileId = currentUserProfileId

    this.lastMessage = payload.conversation.last_message
      ? new BareMessageModel(
          payload.conversation.last_message,
          currentUserProfileId
        )
      : undefined
  }

  get id(): ConversationId {
    return this.payload.profile.id
  }

  get username(): string {
    return this.payload.profile.account.username
  }

  get thumbnailUrl() {
    if (!this.lastMessage) {
      return this.payload.profile.photo?.thumbnail_url
    }

    switch (this.lastMessage.payload.type) {
      case MessageType.enum.web_view:
        return this.lastMessage.payload.data.photo
      default:
        return this.payload.profile.photo?.thumbnail_url
    }
  }

  get isOnline() {
    return this.payload.profile.online === true
  }

  get displayName() {
    return (
      this.payload.profile.display_name ||
      `@${this.payload.profile.account.username}`
    )
  }

  get lastMessageAgo() {
    return this.lastMessage ? this.lastMessage.createdAt : null
  }

  get unreadCount() {
    return this.payload.unread_count
  }

  get isBroadcastProfile() {
    return this.payload.profile.broadcast_profile === true
  }

  get isSystemProfile() {
    return this.payload.profile.system_profile === true
  }

  get isBroadcastMessage() {
    return this.lastMessage && this.lastMessage.webViewTitle
  }

  get isUnread() {
    return this.payload.unread_count > 0
  }

  get quickiesProfileId(): QuickiesProfileId | null {
    return this.payload.profile.quickies_profile_id ?? null
  }
}
