import { FC, ReactNode, useEffect, useRef, useState } from "react"
import dynamic from "next/dynamic"
import styled, { useTheme } from "styled-components"
import UploadInput from "./UploadInput"
import CloseIcon from "../UI/CloseIcon"
import PhotoDataModel from "@hornet-web-react/core/models/photo-data-model"
import { getCroppedImg } from "@hornet-web-react/core/utils/crop-image"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import { TYPES as CORE_TYPES } from "@hornet-web-react/core/services/types"
import { CropData, ImageData } from "../Modals/CropPhotoModal"
import LoggerService from "@hornet-web-react/core/services/LoggerService"
import useTranslation from "next-translate/useTranslation"
import Message from "../UI/Message"
import { PlusCircleIcon } from "@heroicons/react/24/outline"
import Image from "next/image"

const CropPhotoModal = dynamic(() => import("../Modals/CropPhotoModal"))

const Wrapper = styled.div<{ isCircle: boolean }>`
  width: 100%;
  height: 100%;
  border-radius: ${({ theme, isCircle }) =>
    isCircle ? "100%" : theme.borderRadius.default};
  position: relative;
`

const AddPhoto = styled.div<{ isCircle: boolean }>`
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: ${({ theme, isCircle }) =>
    isCircle ? "100%" : theme.borderRadius.default};
  // border: 2px dashed ${({ theme }) => theme.color.border.default};
  border: 2px dashed #b7b6bb;
  position: relative;
`

const AddPhotoIcon = styled(PlusCircleIcon)`
  position: relative;
  z-index: 1;
  opacity: 1;
`

const PlaceholderImage = styled.img<{ isCircle: boolean }>`
  position: absolute;
  top: 2px;
  left: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: ${({ theme, isCircle }) =>
    isCircle ? "100%" : theme.borderRadius.default};

  filter: grayscale(1);
  opacity: 0.5;
`

const ErrorMessage = styled(Message)`
  ${({ theme }) => theme.font.regular.footnote};
  position: absolute;
  top: 10px;
  left: 50%;
  width: 80%;
  transform: translate(-50%, 0);
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacing.half};
  font-size: 13px;
`

const PreviewPhoto = styled(AddPhoto)`
  position: relative;
  border-style: solid;
  border-color: ${({ theme }) => theme.color.border.default};

  img {
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    z-index: 5;
    border-radius: ${({ theme, isCircle }) =>
      isCircle ? "100%" : theme.borderRadius.default};
  }
`

const RemoveImageIcon = styled(CloseIcon)<{ isCircle: boolean }>`
  top: ${({ isCircle }) => (isCircle ? "0px" : "-12px")};
  right: ${({ isCircle }) => (isCircle ? "0px" : "-12px")};
  position: absolute;
  z-index: 10;
  box-shadow: inset 0 0 0 2px ${({ theme }) => theme.color.bg.light01};
`

type ImageUploadProps = {
  children: (element: ReactNode, openPictureUpload: () => void) => ReactNode
  onPhotoChange: (
    thumbData: PhotoDataModel,
    cropData: PhotoDataModel,
    file: File
  ) => void
  onPhotoRemove?: () => void
  onCtaClick?: () => void
  className?: string
  hasHeroCrop?: boolean
  heroCropAspectRatio?: number
  previewUrl?: string
  placeholderUrl?: string
  thumbCropTitle?: string
  heroCropTitle?: string
  isCircle?: boolean
}

const ImageUpload: FC<ImageUploadProps> = ({
  children,
  className = undefined,
  hasHeroCrop = true,
  heroCropAspectRatio = 2,
  onPhotoChange,
  onPhotoRemove,
  onCtaClick,
  previewUrl,
  placeholderUrl,
  thumbCropTitle,
  heroCropTitle,
  isCircle = false,
}) => {
  const theme = useTheme()
  const loggerService = useCoreService<LoggerService>(CORE_TYPES.LoggerService)

  const { t } = useTranslation()

  const uploadInputRef = useRef<UploadInput>(null)
  const [thumbData, setThumbData] = useState(
    PhotoDataModel.createEmpty("thumb")
  ) // square
  const [heroData, setHeroData] = useState(PhotoDataModel.createEmpty("crop")) // widescreen
  const [showThumbCropModal, setShowThumbCropModal] = useState(false)
  const [showHeroCropModal, setShowHeroCropModal] = useState(false)
  const [photoPreviewSrc, setPhotoPreviewSrc] = useState("")
  const [isImageError, setIsImageError] = useState(false)

  // set preview URL
  useEffect(() => {
    if (previewUrl) {
      setPhotoPreviewSrc(previewUrl)
    }
  }, [previewUrl])

  const updatePhoto = () => {
    if (!uploadInputRef.current || !uploadInputRef.current.getFile()) {
      return
    }

    // first, check for filesize (<10MB)
    if (uploadInputRef.current.getFile().size > 10 * 1000 * 1000) {
      setIsImageError(true)
      return
    }

    setIsImageError(false)

    const reader = new FileReader()

    reader.addEventListener(
      "error",
      function photoFileReaderOnError() {
        // oops, shouldn't happen
      },
      { once: true }
    )

    reader.addEventListener(
      "load",
      function photoFileReaderOnLoad(event) {
        if (!event?.target?.result) {
          return
        }

        setThumbData(
          new PhotoDataModel({
            ...thumbData.serialize(),
            url: String(event.target.result),
          })
        )
        setHeroData(
          new PhotoDataModel({
            ...heroData.serialize(),
            url: String(event.target.result),
          })
        )

        setShowThumbCropModal(true)
      },
      { once: true }
    )

    reader.readAsDataURL(uploadInputRef.current.getFile())
  }

  const openPictureUpload = () => {
    if (typeof onCtaClick === "function") {
      onCtaClick()
    }

    uploadInputRef.current && uploadInputRef.current.openDialog()
  }

  const cancelThumbCropModal = () => {
    setShowThumbCropModal(false)
    uploadInputRef.current && uploadInputRef.current.resetValue()
  }

  const confirmThumbCropModal = (cropData: CropData, imageData: ImageData) => {
    const { width: cropWidth, height: cropHeight, x: x1, y: y1 } = cropData
    const { width, height } = imageData

    const finalThumbData = new PhotoDataModel({
      ...thumbData.serialize(),
      width,
      height,
      cropWidth,
      cropHeight,
      x1,
      y1,
    })

    // save the values
    setThumbData(finalThumbData)

    // close the modal
    setShowThumbCropModal(false)

    // update preview
    const showCroppedImage = async (imgUrl: string, cropData: CropData) => {
      try {
        return await getCroppedImg(imgUrl, cropData)
      } catch (error) {
        if (error instanceof Error) {
          loggerService.logExceptionWithSentry(
            error,
            loggerService.createLoggingContext({
              component: "ImageUpload",
              step: "showCroppedImage",
            })
          )
        }
      }
    }

    showCroppedImage(thumbData.url, cropData).then((croppedImage) => {
      setPhotoPreviewSrc(croppedImage)
    })

    // continue with HeroCrop
    if (hasHeroCrop) {
      // open the next crop modal
      setShowHeroCropModal(true)
    }
    // or finish
    else {
      // final callback
      uploadInputRef.current &&
        onPhotoChange(
          finalThumbData,
          heroData,
          uploadInputRef.current.getFile()
        )
    }
  }

  const cancelHeroCropModal = () => {
    // close modal
    setShowHeroCropModal(false)

    // reset preview
    setPhotoPreviewSrc("")

    // reset input
    uploadInputRef.current && uploadInputRef.current.resetValue()

    // reset current crop state
    setThumbData(PhotoDataModel.createEmpty("thumb"))
  }

  const confirmHeroCropModal = (cropData: CropData, imageData: ImageData) => {
    const { width: cropWidth, height: cropHeight, x: x1, y: y1 } = cropData
    const { width, height } = imageData

    const finalHeroData = new PhotoDataModel({
      ...heroData.serialize(),
      width,
      height,
      cropWidth,
      cropHeight,
      x1,
      y1,
    })

    // save the values
    setHeroData(finalHeroData)

    // close the modal
    setShowHeroCropModal(false)

    // final callback
    if (uploadInputRef.current && uploadInputRef.current.getFile()) {
      onPhotoChange(thumbData, finalHeroData, uploadInputRef.current.getFile())
    }
  }

  const removePicture = () => {
    // reset preview
    setPhotoPreviewSrc("")

    // reset input
    uploadInputRef.current && uploadInputRef.current.resetValue()

    // reset current crop states
    setThumbData(PhotoDataModel.createEmpty("thumb"))
    setHeroData(PhotoDataModel.createEmpty("crop"))

    if (typeof onPhotoRemove === "function") {
      onPhotoRemove()
    }
  }

  const element = (
    <Wrapper className={className} isCircle={isCircle}>
      {isImageError && (
        <ErrorMessage context="error">
          {t("core:components.form.image_upload.image_too_big")}
        </ErrorMessage>
      )}

      {photoPreviewSrc && (
        <PreviewPhoto isCircle={isCircle}>
          <Image src={photoPreviewSrc} alt={""} width={60} height={60} />
          <RemoveImageIcon
            onClick={removePicture}
            size={20}
            isCircle={isCircle}
          />
        </PreviewPhoto>
      )}

      {/*{!photoPreviewSrc && placeholderUrl && (*/}
      {/*  <PreviewPhoto role="button" onClick={openPictureUpload}>*/}
      {/*    <img alt="" width={60} height={60} src={placeholderUrl} />*/}
      {/*  </PreviewPhoto>*/}
      {/*)}*/}

      {!photoPreviewSrc && (
        <AddPhoto role="button" onClick={openPictureUpload} isCircle={isCircle}>
          <AddPhotoIcon
            width={60}
            height={60}
            color={theme.color.text.tertiary}
          />
          {placeholderUrl && (
            <PlaceholderImage
              alt=""
              width={260}
              height={260}
              src={placeholderUrl}
              isCircle={isCircle}
            />
          )}
        </AddPhoto>
      )}

      <UploadInput ref={uploadInputRef} onChange={updatePhoto} />

      {showThumbCropModal && (
        <CropPhotoModal
          isOpen={showThumbCropModal}
          confirmAction={confirmThumbCropModal}
          cancelAction={cancelThumbCropModal}
          aspectRatio={1}
          photoUrl={thumbData.url || ""}
          modalTitle={thumbCropTitle}
        />
      )}
      {showHeroCropModal && (
        <CropPhotoModal
          isOpen={showHeroCropModal}
          confirmAction={confirmHeroCropModal}
          cancelAction={cancelHeroCropModal}
          aspectRatio={heroCropAspectRatio}
          photoUrl={heroData.url || ""}
          modalTitle={heroCropTitle}
        />
      )}
    </Wrapper>
  )

  return <>{children(element, openPictureUpload)}</>
}

export default ImageUpload
