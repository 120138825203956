import Trans from "next-translate/Trans"
import { FC } from "react"
import styled from "styled-components"
import NiceModal from "@ebay/nice-modal-react"
import { useLazyLoaded } from "@hornet-web-react/core/hooks/use-lazy-loaded"

type CustomPhotoPolicyProps = {
  //
}

const i18nKey = "quickies:components.edit_quickies_profile.custom_photo_policy"

const CustomPhotoPolicy: FC<CustomPhotoPolicyProps> = () => {
  const { get: getPhotoPolicyModal } = useLazyLoaded(
    () => import("../Modals/PhotoPolicyModal")
  )

  const showPhotoPolicyModal = () =>
    getPhotoPolicyModal((modal) => NiceModal.show(modal))

  return (
    <Wrapper role={"button"} onClick={showPhotoPolicyModal}>
      <Trans
        i18nKey={`${i18nKey}.label`}
        components={[
          /* eslint-disable-next-line react/jsx-key */
          <em />,
        ]}
      />
    </Wrapper>
  )
}

export default CustomPhotoPolicy

const Wrapper = styled.div`
  ${({ theme }) => theme.font.regular.footnote};
  color: ${({ theme }) => theme.color.text.primary};
  text-align: center;
  cursor: pointer;
  pointer-events: auto;

  em {
    font-style: normal;
    color: ${({ theme }) => theme.color.tint.default};
  }
`
