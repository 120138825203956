import { FC } from "react"
import { isToday, isTomorrow } from "date-fns"
import { QuickiesGroupApiPayload } from "../../models/quickies-group.model"
import { useLocalizedDateFormat } from "@hornet-web-react/core/hooks/use-localized-date-format"
import useTranslation from "next-translate/useTranslation"

type GroupStartsAtProps = {
  startsAt: QuickiesGroupApiPayload["hangout"]["starts_at"]
}

const i18nKey = "quickies:components.groups.group_starts_at"

const GroupStartsAt: FC<GroupStartsAtProps> = ({ startsAt }) => {
  const { t } = useTranslation()
  const { dateFormat } = useLocalizedDateFormat()

  return getStartsAtFormatted()

  function getStartsAtFormatted() {
    if (isToday(startsAt)) {
      if (startsAt.getHours() >= 18) {
        return t(`${i18nKey}.tonight`, {
          time: dateFormat(startsAt, "H:mm"),
        })
      }

      return t(`${i18nKey}.today`, {
        time: dateFormat(startsAt, "H:mm"),
      })
    }

    if (isTomorrow(startsAt)) {
      return t(`${i18nKey}.tomorrow`, {
        time: dateFormat(startsAt, "H:mm"),
      })
    }

    return t(`${i18nKey}.on_date`, {
      time: dateFormat(startsAt, "H:mm"),
      date: dateFormat(startsAt, "MMM d, yyyy"),
    })
  }
}

export default GroupStartsAt
