import { FC } from "react"
import styled from "styled-components"
import {
  useQuickiesVanillaMode,
  vanillaModeImageStyle,
} from "../../hooks/use-quickies-vanilla-mode"
import Image from "next/image"

type GroupProfilePhotoProps = {
  photoUrl: string
}

const GroupProfilePhoto: FC<GroupProfilePhotoProps> = ({ photoUrl }) => {
  const { state: isVanillaMode } = useQuickiesVanillaMode()

  return (
    <Wrapper>
      <PhotoWrapper isVanillaMode={isVanillaMode}>
        <Photo src={photoUrl} alt={""} width={138} height={138} />
      </PhotoWrapper>
    </Wrapper>
  )
}

export default GroupProfilePhoto

const Wrapper = styled.div`
  padding: 0 0 ${({ theme }) => theme.spacing.double};
  display: flex;
  align-items: center;
  justify-content: center;
`

const PhotoWrapper = styled.div<{
  isVanillaMode?: boolean
}>`
  width: 138px;
  height: 138px;
  overflow: hidden;
  border-radius: 8px;

  img {
    ${vanillaModeImageStyle};
  }
`

const Photo = styled(Image)`
  display: block;
  border-radius: 8px;
  width: 138px;
  height: 138px;
`
