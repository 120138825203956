import { FC, useEffect, useState } from "react"
import styled from "styled-components"
import Spinner from "./Spinner"

type OverlaySpinnerProps = {
  isVisible: boolean
}

const OverlaySpinner: FC<OverlaySpinnerProps> = ({ isVisible }) => {
  const [isActive, setIsActive] = useState(false)

  // to have fade-in and fade-out effect
  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setIsActive(true)
      }, 1)
    } else {
      setTimeout(() => {
        setIsActive(false)
      }, 300)
    }
  }, [isVisible])

  if (!isVisible && !isActive) {
    return null
  }

  return (
    <>
      <LoadingSpinnerWrapper isVisible={isVisible && isActive}>
        <Spinner />
      </LoadingSpinnerWrapper>
      <LoadingOverlay isVisible={isVisible && isActive} />
    </>
  )
}

export default OverlaySpinner

const LoadingOverlay = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${({ isVisible }) => (isVisible ? 100 : -1)};
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(${({ isVisible }) => (isVisible ? "4px" : "0px")});
  transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  pointer-events: none;
`

const LoadingSpinnerWrapper = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: ${({ isVisible }) => (isVisible ? 101 : -1)};
  transform: translate(-50%, -50%);
  transition: opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1);
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  pointer-events: none;
`
