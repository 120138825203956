import { useSessionUser } from "@hornet-web-react/core/contexts/session"
import { useLoggerService } from "@hornet-web-react/core/hooks/use-logger-service"
import { useApi } from "@hornet-web-react/core/hooks/use-api"
import useImmutableSWR from "swr"
import { ApiServiceEndpoint } from "@hornet-web-react/core/services/API/ApiServiceEndpoint"
import { QuickiesUserInvitesApiPayload } from "../models/quickies-user-invites.model"
import { useMemo } from "react"
import { useQuickiesPremium } from "./use-quickies-premium"

export function useQuickiesUserInvites() {
  const { isAuthenticated } = useSessionUser()
  const { hasPremium } = useQuickiesPremium()
  const { logExceptionWithSentry, createLoggingContext } = useLoggerService()
  const { getEndpoint } = useApi()

  const { data, error } = useImmutableSWR<QuickiesUserInvitesApiPayload>(
    isAuthenticated && hasPremium
      ? getEndpoint(ApiServiceEndpoint.QuickiesUserInvitesGet)
      : null,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  const userInvites: QuickiesUserInvitesApiPayload | undefined = useMemo(() => {
    try {
      return data && QuickiesUserInvitesApiPayload.parse(data)
    } catch (error) {
      if (error instanceof Error) {
        // invalid data payload
        logExceptionWithSentry(
          error,
          createLoggingContext({
            hook: "useQuickiesUserInvites",
            step: "validateAndCreateModel",
          })
        )
      }
    }
  }, [createLoggingContext, data, logExceptionWithSentry])

  return {
    data: userInvites,
    isLoading: !error && !data,
    error,
  }
}
