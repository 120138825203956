import { FC } from "react"
import styled from "styled-components"
import IconButton from "../UI/IconButton"
import { XMarkIcon } from "@heroicons/react/24/solid"

type CloseModalButtonV2Props = {
  onClick: () => void
}

const CloseModalButtonV2: FC<CloseModalButtonV2Props> = ({ onClick }) => {
  return (
    <Button data-test-id={"modal-close-btn"} type={"button"} onClick={onClick}>
      <XMarkIcon width={24} />
    </Button>
  )
}

export default CloseModalButtonV2

const Button = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 60;
`
