import React, { FC, useCallback, useState } from "react"
import styled from "styled-components"
import useTranslation from "next-translate/useTranslation"
import QuickiesMemberAvatar from "../QuickiesMemberAvatar"
import {
  PlusIcon,
  UserMinusIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid"
import {
  QuickiesGroupMembership,
  QuickiesGroupMembershipStatus,
} from "../../models/quickies-group.model"
import NiceModal from "@ebay/nice-modal-react"
import {
  ActionSheetModalItemType,
  QuickiesGroupId,
} from "@hornet-web-react/core/types"
import { useQuickiesProfilePreview } from "../../hooks/use-quickies-profile-preview"
import { useQuickiesSession } from "../../hooks/use-quickies-session"
import { useApi } from "@hornet-web-react/core/hooks/use-api"
import { ApiServiceEndpoint } from "@hornet-web-react/core/services/API/ApiServiceEndpoint"
import { useFlashMessage } from "@hornet-web-react/core/hooks/use-flash-message"
import invariant from "tiny-invariant"
import { isRight, unwrapEither } from "@hornet-web-react/core/utils"
import OverlaySpinner from "@hornet-web-react/core/components/UI/OverlaySpinner"
import { reloadQuickiesGroup } from "../../hooks/use-quickies-group"
import { FaUserClock } from "react-icons/fa"
import { useLazyLoaded } from "@hornet-web-react/core/hooks/use-lazy-loaded"
import QuickiesAnalyticsEvent from "../../models/quickies-analytics-event"
import { useEventTrackerService } from "../../hooks/use-event-tracker-service"

type GroupAttendeesProps = {
  attendees: QuickiesGroupMembership[]
  groupId?: QuickiesGroupId
  isPreview?: boolean
  isCurrentUserHosting?: boolean
}

const i18nKey = "quickies:components.groups.group_attendees"

const GroupAttendees: FC<GroupAttendeesProps> = ({
  groupId,
  attendees,
  isPreview = false,
  isCurrentUserHosting = false,
}) => {
  const { t } = useTranslation()
  const { reportEvent } = useEventTrackerService()
  const { data: quickieSession } = useQuickiesSession()
  const { openProfilePreview } = useQuickiesProfilePreview()
  const { getEndpoint, makeApiRequest } = useApi()
  const { showOops } = useFlashMessage()
  const [isRemovingAttendee, setIsRemovingAttendee] = useState(false)

  const { get: getGroupInviteModal } = useLazyLoaded(
    () => import("../Modals/GroupInviteModal")
  )
  const { get: getActionSheetModal } = useLazyLoaded(
    () => import("@hornet-web-react/core/components-lazy/ActionSheetModal")
  )
  const { get: getConfirmModal } = useLazyLoaded(
    () => import("@hornet-web-react/core/components-lazy/ConfirmModal")
  )

  const hasInviteButton =
    groupId !== undefined && isCurrentUserHosting && !isPreview
  const hasAttendeeActions =
    groupId !== undefined && isCurrentUserHosting && !isPreview

  const handleInviteOnClick = useCallback(async () => {
    if (!groupId) {
      return
    }

    void reportEvent(
      QuickiesAnalyticsEvent.groupPreviewModalTapOnInvite(groupId)
    )

    void getGroupInviteModal((modal) =>
      NiceModal.show(modal, {
        quickiesGroupId: groupId,
      }).then(() => reloadQuickiesGroup(groupId))
    )
  }, [getGroupInviteModal, groupId, reportEvent])

  const removeAttendeeAction = useCallback(
    async (attendee: QuickiesGroupMembership) => {
      invariant(groupId, "groupId is required")
      invariant(isCurrentUserHosting, "user must be hosting the group")

      setIsRemovingAttendee(true)

      const apiResult = await makeApiRequest(
        getEndpoint(ApiServiceEndpoint.QuickiesLeaveGroupDelete, [
          groupId,
          attendee.groupMembershipId,
        ])
      )

      if (isRight(apiResult)) {
        await reloadQuickiesGroup(groupId)
        setIsRemovingAttendee(false)
        return
      }

      const error = unwrapEither(apiResult)
      void showOops(error)
      setIsRemovingAttendee(false)
    },
    [getEndpoint, groupId, isCurrentUserHosting, makeApiRequest, showOops]
  )

  const createHandleAttendeeOnClick = useCallback(
    (hasAttendeeActions: boolean, attendee: QuickiesGroupMembership) => {
      if (!hasAttendeeActions) {
        return () => openProfilePreview(attendee.quickiesProfileId)
      }

      // follows action that happens when current user is hosting the group
      if (!quickieSession) {
        return undefined
      }

      return async () => {
        const actionSheetModalItems: ActionSheetModalItemType[] = [
          {
            id: "view_profile",
            icon: <UserCircleIcon width={20} />,
            label: t(`${i18nKey}.actions.view_profile`),
          },
        ]

        if (attendee.quickiesProfileId !== quickieSession?.profile.profileId) {
          actionSheetModalItems.push({
            id: "remove_from_group",
            icon: <UserMinusIcon width={20} />,
            label: t(`${i18nKey}.actions.remove_from_group`),
            isDanger: true,
          })
        }

        // actually, if there's only single action, there's no reason to show the
        // whole action sheet, so just go ahead and show that one profile
        // which is the only action here in such case
        if (actionSheetModalItems.length === 1) {
          void openProfilePreview(attendee.quickiesProfileId)
          return
        }

        void getActionSheetModal((modal) =>
          NiceModal.show(modal, {
            items: actionSheetModalItems,
          }).then(async (result) => {
            if (result === "view_profile") {
              void openProfilePreview(attendee.quickiesProfileId)
              return
            }

            if (result === "remove_from_group") {
              void getConfirmModal((modal) =>
                NiceModal.show(modal, {
                  isDangerous: true,
                  question: t(`${i18nKey}.remove_attendee.question`),
                  confirmLabel: t(`${i18nKey}.remove_attendee.confirm`),
                  cancelLabel: t(`${i18nKey}.remove_attendee.cancel`),
                }).then(() => removeAttendeeAction(attendee))
              )
            }
          })
        )
      }
    },
    [
      quickieSession,
      openProfilePreview,
      t,
      getActionSheetModal,
      getConfirmModal,
      removeAttendeeAction,
    ]
  )

  return (
    <Wrapper>
      <Headline>{t(`${i18nKey}.headline`)}</Headline>

      <AttendeesList>
        <OverlaySpinner isVisible={isRemovingAttendee} />

        {hasInviteButton ? (
          <InviteButton
            avatarUrl={null}
            onClick={handleInviteOnClick}
            size={68}
            isPlaceholderAnimated={false}
          >
            <InvitePlusIconWrapper>
              <PlusIcon width={32} />
            </InvitePlusIconWrapper>
          </InviteButton>
        ) : null}

        {isPreview && attendees.length === 0 ? (
          <QuickiesMemberAvatar
            avatarUrl={quickieSession?.profile.profilePhoto?.squareUrl}
            size={68}
          />
        ) : null}

        {attendees.map((attendee, index) => (
          <QuickiesMemberAvatar
            key={index}
            avatarUrl={attendee.thumbnailUrl}
            onClick={
              !isPreview
                ? createHandleAttendeeOnClick(hasAttendeeActions, attendee)
                : undefined
            }
            size={68}
          >
            {attendee.status === QuickiesGroupMembershipStatus.enum.invited ? (
              <MemberInvitedOverlay>
                <FaUserClock size={20} />
              </MemberInvitedOverlay>
            ) : null}
          </QuickiesMemberAvatar>
        ))}
      </AttendeesList>
    </Wrapper>
  )
}

export default GroupAttendees

const Wrapper = styled.div``

const Headline = styled.div`
  ${({ theme }) => theme.font.bold.title2};
  // override
  font-size: 25px;
  margin-bottom: ${({ theme }) => theme.spacing.more};
`

const AttendeesList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding: 2px;
`
//   padding: ${({ theme }) => theme.spacing.less} 2px
//     ${({ theme }) => theme.spacing.regular} 2px;

const InviteButton = styled(QuickiesMemberAvatar)`
  box-shadow: 0 0 0 2px ${({ theme }) => theme.color.tint.default};
  position: relative;
`

const InvitePlusIconWrapper = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.color.tint.default};
`

const MemberInvitedOverlay = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.color.text.primary};
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
`
