import { FC } from "react"
import styled from "styled-components"
import { MemberOnlineStatus } from "@hornet-web-react/core/models/member.model"
import useTranslation from "next-translate/useTranslation"
import { useSessionDevice } from "@hornet-web-react/core/contexts/session"
import dynamic from "next/dynamic"

const ClientOnlyTimeAgo = dynamic(
  () => import("@hornet-web-react/core/components-lazy/ClientOnlyTimeAgo")
)

type UserLastOnlineProps = {
  status: MemberOnlineStatus
  className?: string
}

const Wrapper = styled.div`
  color: #2b2b2b;
  position: relative;
`

const Dot = styled.span`
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #4cd964;
  margin-right: 9px;
  position: relative;
`

const TimeAgoLabel = styled.span`
  display: inline-block;
  margin-right: 3px;
`

const i18nKey = "profile:components.user_last_online"

const UserLastOnline: FC<UserLastOnlineProps> = ({ status, className }) => {
  const { t } = useTranslation()
  const { locale } = useSessionDevice()

  return (
    <Wrapper className={className}>
      {status.isOnline && (
        <>
          <Dot />
          {t(`${i18nKey}.title`)}
        </>
      )}

      {!status.isOnline && status.lastOnline && (
        <>
          <TimeAgoLabel>{t(`${i18nKey}.title`)}</TimeAgoLabel>
          <ClientOnlyTimeAgo datetime={status.lastOnline} locale={locale} />
        </>
      )}
    </Wrapper>
  )
}

export default UserLastOnline
