import { SVGProps } from "react"

const ShieldWIthLockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 33}
    height={props.height || 32}
    viewBox={`0 0 33 32`}
    fill="none"
    {...props}
  >
    <path
      fill="#DBDBDB"
      d="M16.487 10.474c-.83 0-1.53.674-1.53 1.529v1.503h3.06v-1.503c0-.83-.7-1.53-1.53-1.53Z"
    />
    <path
      fill="#DBDBDB"
      d="m26.726 6.249-10.11-2.23a.36.36 0 0 0-.233 0L6.274 6.25a.555.555 0 0 0-.44.544v5.6c0 5.365 2.54 10.394 6.869 13.556l3.473 2.566a.545.545 0 0 0 .674 0l3.473-2.566c4.303-3.162 6.844-8.217 6.844-13.557V6.793a.555.555 0 0 0-.441-.544Zm-5.884 12.986a.548.548 0 0 1-.544.545h-7.621a.548.548 0 0 1-.545-.545v-5.184c0-.285.234-.544.545-.544h.777v-1.504a3.064 3.064 0 0 1 3.059-3.058c1.685 0 3.033 1.4 3.033 3.058v1.504h.777c.286 0 .545.233.545.544v5.184h-.026Z"
    />
  </svg>
)
export default ShieldWIthLockIcon
