import * as React from "react"
import { FC, useId } from "react"

type QuickiesXLogoProps = {
  size?: number
  alt?: string
}

const QuickiesXLogo: FC<QuickiesXLogoProps> = (props) => {
  // get a unique ID for the gradient defs on every render
  // otherwise Safari fails to fill the path on subsequent renders
  const gradientId = useId()

  const width = props.size || 101
  const height = width * (83 / 101)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 101 83"}
      width={width}
      height={height}
      fill="none"
      {...props}
    >
      <path
        fill={`url(#quickiesXLogo-${gradientId}-1)`}
        fillRule="evenodd"
        d="m62.272 50.407-3.916 5.266L68.54 70.205h8.062l-14.33-19.797Zm-29.93 19.798h-7.583l22.283-30.01-20.491-27.993h8.286L50.849 35.27l16.46-23.067h8.17L50.978 45.147l-.017-.024-.991 1.38L32.34 70.204ZM100.5 82.407 70.03 40.314 100.185 0H61.026l-10.04 14.069L41.22 0H2.496l29.386 40.144L.5 82.407h38.69l11.646-16.203 11.357 16.203H100.5Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id={`quickiesXLogo-${gradientId}-1`}
          x1={50.5}
          x2={50.5}
          y1={0}
          y2={82.407}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F75932" />
          <stop offset={1} stopColor="#FFAA48" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default QuickiesXLogo
