import { QuickiesSessionAccountApiPayload } from "../models/quickies-session.model"
import { isAndroid, isIOS } from "@hornet-web-react/core/utils"

export const getFeedbackMailtoLink = (
  locale: string,
  account: QuickiesSessionAccountApiPayload
) => {
  const accountId = account.id

  const platform = isIOS() ? "iOS" : isAndroid() ? "Android" : "other"

  const address = "feedback@hornet.com"
  const subject = "HornetX"

  const body = [
    "\n\n\n\n",
    encodeURIComponent(`[${locale}] [${platform}] (Account ID: ${accountId})`),
  ].join("\n")

  return (
    "mailto:" +
    encodeURIComponent(address) +
    "?subject=" +
    encodeURIComponent(subject) +
    "&body=" +
    body
  )
}
