import React, { useCallback, useContext, useEffect } from "react"
import useTranslation from "next-translate/useTranslation"
import NiceModal, { useModal } from "@ebay/nice-modal-react"
import { isRight, makeRight } from "@hornet-web-react/core/utils"
import { useEventTrackerService } from "../../hooks/use-event-tracker-service"
import ProfilePhotoUploadModal from "@hornet-web-react/profile/components/Modals/ProfilePhotoUploadModal"
import { useQuickiesSession } from "../../hooks/use-quickies-session"
import { ProfilePhotoUploadModel } from "@hornet-web-react/profile/models/profile-photo-upload.model"
import invariant from "tiny-invariant"
import QuickiesAnalyticsEvent from "../../models/quickies-analytics-event"
import { getCookie, setCookie } from "typescript-cookie"
import { CookieName } from "@hornet-web-react/core/types"
import { useQuickiesProfilePhotoActions } from "../../hooks/use-quickies-profile-photo-actions"
import appConfig from "../../app-config"
import {
  useUploadNudesReminderApi,
  useUploadNudesReminderState,
} from "../../contexts/upload-nudes-reminder-context"
import getRandomPhotoPlaceholderUrl from "../../utils/get-random-photo-placeholder-url"
import { useSessionUser } from "@hornet-web-react/core/contexts/session"
import CustomPhotoPolicy from "../EditQuickiesProfile/CustomPhotoPolicy"

type ShouldUploadPhotoReminderProps = {
  //
}

export const i18nKey = "quickies:components.map.should_upload_photo_reminder"

// this also handles outcome of ReachedProfileLimitModal via the UploadNudesReminderContext
const ShouldUploadPhotoReminder = (props: ShouldUploadPhotoReminderProps) => {
  const { t } = useTranslation()
  const { reportEvent } = useEventTrackerService()
  const { data: session, hasActiveQuickiesProfile } = useQuickiesSession()
  const { isAuthenticated } = useSessionUser()
  const profile = session?.profile
  const modals = useContext(NiceModal.NiceModalContext)
  const profilePhotoModal = useModal(ProfilePhotoUploadModal)
  const { handleFeaturedPhotoUpload } = useQuickiesProfilePhotoActions()
  const { isReminderShown } = useUploadNudesReminderState()
  const { hideReminder } = useUploadNudesReminderApi()

  const onUpload = useCallback(
    (formData: ProfilePhotoUploadModel) => {
      invariant(profile, "Profile should be defined")

      const slot = profile.customPhotos.length || 0

      void reportEvent(
        QuickiesAnalyticsEvent.photoUploadReminderTapOnUploadPhoto(slot)
      )

      return handleFeaturedPhotoUpload(profile, slot, formData)
    },
    [handleFeaturedPhotoUpload, profile, reportEvent]
  )

  const hideProfilePhotoUploadModal = useCallback(() => {
    hideReminder()
    void profilePhotoModal.hide()
  }, [profilePhotoModal, hideReminder])

  // TODO: refactor and unify with ProfileGalleryUploadTile
  const handleContinue = useCallback(
    async (formData: ProfilePhotoUploadModel | undefined) => {
      // wait for upload, close in case of success
      if (typeof formData !== "undefined") {
        const result = await onUpload(formData)
        if (isRight(result)) {
          void reportEvent(
            QuickiesAnalyticsEvent.photoUploadReminderPhotoUploaded()
          )

          hideProfilePhotoUploadModal()
        }
        return result
      }

      // just close it
      hideProfilePhotoUploadModal()
      return makeRight(true)
    },
    [hideProfilePhotoUploadModal, onUpload, reportEvent]
  )

  const showProfilePhotoUploadModal = useCallback(() => {
    void profilePhotoModal.show({
      type: "profile",
      onClose: handleContinue,
      // onSelect: reportPhotoSelected,
      customPhotoPolicy: <CustomPhotoPolicy />,
      photoPlaceholderUrl: getRandomPhotoPlaceholderUrl(),
      hasHeroCrop: false,
      i18nOverride: {
        title: t(`${i18nKey}.title`),
        text: t(`${i18nKey}.text`),
        closeButton: t(`${i18nKey}.close_button`),
        uploadButton: t(`${i18nKey}.upload_button`),
        thumbCropTitle: " ",
        heroCropTitle: " ",
      },
    })
  }, [handleContinue, profilePhotoModal, t])

  // check if user has a photo in their profile
  // and if they don't, show them incentive
  useEffect(() => {
    const visibleModalsCount = Object.values(modals).filter(
      (modal) => modal.visible
    ).length

    if (
      isAuthenticated &&
      profile &&
      !profile.hasCustomProfilePhoto &&
      visibleModalsCount === 0 &&
      !!session &&
      hasActiveQuickiesProfile &&
      (!getCookie(CookieName.PostNudes) || isReminderShown)
    ) {
      void reportEvent(QuickiesAnalyticsEvent.mapPhotoUploadReminderShown())

      void showProfilePhotoUploadModal()

      setCookie(
        CookieName.PostNudes,
        "y",
        appConfig.simpleCookieConfig({
          // ask again in 12 hours
          expires: new Date(new Date().getTime() + 60 * 60 * 12 * 1000),
        })
      )
    }
  }, [
    profile,
    modals,
    reportEvent,
    showProfilePhotoUploadModal,
    isReminderShown,
    isAuthenticated,
    session,
    hasActiveQuickiesProfile,
  ])

  return null
}

export default ShouldUploadPhotoReminder
