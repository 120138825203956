import useImmutableSWR, { KeyedMutator, mutate } from "swr"
import {
  useSessionApi,
  useSessionUser,
} from "@hornet-web-react/core/contexts/session"
import { useCallback, useMemo } from "react"
import {
  createQuickiesSessionModel,
  QuickiesSessionApiPayload,
  QuickiesSessionInterface,
  QuickiesSessionStatusInfo,
} from "../models/quickies-session.model"
import { debug, isRight, unwrapEither } from "@hornet-web-react/core/utils"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import LoginService from "@hornet-web-react/core/services/LoginService"
import { TYPES as CORE_TYPES } from "@hornet-web-react/core/services/types"
import { LoginApiPayload } from "@hornet-web-react/core/types/session"

type UseQuickiesSessionResult = {
  data: QuickiesSessionInterface | undefined
  isLoading: boolean
  hasActiveQuickiesProfile: boolean
  error: Error | undefined
  mutate: KeyedMutator<QuickiesSessionApiPayload>
  markAccountAsVerified: (isVerified: boolean) => void
  updatePhoneNumber: (phoneNumber: string | null) => void
  updateSessionStatusInfo: (statusInfo: QuickiesSessionStatusInfo) => void
}

export function useQuickiesSession(): UseQuickiesSessionResult {
  const user = useSessionUser()
  const loginService = useCoreService<LoginService>(CORE_TYPES.LoginService)
  const {
    user: { storeUser },
  } = useSessionApi()
  const { data, error, mutate } = useImmutableSWR<QuickiesSessionApiPayload>(
    user.isAuthenticated ? "/session" : null,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  const [session, isMissingQuickiesProfile, isInactiveQuickiesProfile] =
    useMemo(() => {
      const sessionEither = createQuickiesSessionModel(data)

      if (isRight(sessionEither)) {
        const profileData = unwrapEither(sessionEither)
        return [profileData, false, profileData.profile.isActive !== true]
      }

      const error = unwrapEither(sessionEither)

      // if user doesn't yet have a quickies profile
      const isMissingQuickiesProfile = error.issues.some(
        (issue) => issue.path.length === 2 && issue.path[1] === "quickies"
      )

      if (!isMissingQuickiesProfile && data) {
        console.log("QuickiesSessionModel error", { error })
      }

      return [undefined, isMissingQuickiesProfile, false]
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

  return {
    data: session,
    hasActiveQuickiesProfile:
      !isMissingQuickiesProfile && !isInactiveQuickiesProfile,
    isLoading: !error && !data,
    error,

    mutate: async () => {
      const data = await mutate()

      // also, keep user cookie in sync when session is updated
      if (data) {
        // validate first
        const session = LoginApiPayload.safeParse(data)
        debug(
          `useQuickiesSession: storeUser: ${session.success ? "yes" : "no"} `
        )
        if (session.success) {
          storeUser(loginService.transformSessionToLoginPayload(session.data))
        } else {
          console.log(
            "useQuickiesSession: storeUser error",
            session.error.issues
          )
        }
      }

      return data
    },

    markAccountAsVerified: useCallback(
      (isVerified: boolean) => {
        if (!data) {
          return
        }

        void mutate(
          {
            ...data,
            session: {
              ...data.session,
              account: {
                ...data.session.account,
                email_verified: isVerified,
              },
            },
          },
          { revalidate: false }
        )
      },
      [data, mutate]
    ),

    updatePhoneNumber: useCallback(
      (phoneNumber: string | null) => {
        if (!data) {
          return
        }

        void mutate(
          {
            ...data,
            session: {
              ...data.session,
              account: {
                ...data.session.account,
                phone_number: phoneNumber,
              },
            },
          },
          { revalidate: false }
        )
      },
      [data, mutate]
    ),

    updateSessionStatusInfo: useCallback(
      (statusInfo: QuickiesSessionStatusInfo) => {
        if (!data) {
          return
        }

        void mutate(
          {
            ...data,
            session: {
              ...data.session,
              quickies: {
                ...data.session.quickies,
                session_status_info: statusInfo,
              },
            },
          },
          { revalidate: false }
        )
      },
      [data, mutate]
    ),
  }
}

export function updateQuickiesSessionLocally(
  session: QuickiesSessionInterface
): Promise<unknown> {
  const serializedData = session.serialize()

  return mutate("/session", serializedData, {
    revalidate: false,
    optimisticData: serializedData,
    rollbackOnError: false,
  })
}
