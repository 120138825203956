import { FC } from "react"
import styled from "styled-components"

type GroupSectionDividerProps = {
  //
}

const GroupSectionDivider: FC<GroupSectionDividerProps> = () => {
  return (
    <SectionDivider>
      <SectionDividerLine />
    </SectionDivider>
  )
}

export default GroupSectionDivider

const SectionDivider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  margin: 10px 0;
`

const SectionDividerLine = styled.div`
  flex: 1 1 auto;
  height: 0;
  border-bottom: 2px solid #ddd;
`
