import { useCoreService } from "@hornet-web-react/core/contexts/services"
import LocalStorageService, {
  StorageKey,
} from "@hornet-web-react/core/services/LocalStorageService"
import { CORE_TYPES } from "@hornet-web-react/core/services/types"
import useSWRImmutable from "swr/immutable"
import { useCallback } from "react"
import { useSWRConfig } from "swr"
import { css } from "styled-components"

const SWR_KEY = "/_services/local_storage/quickies_vanilla_mode"

// vanilla mode = censored mode
export function useQuickiesVanillaMode() {
  const localStorageService = useCoreService<LocalStorageService>(
    CORE_TYPES.LocalStorageService
  )

  const localStorageFetcher = async () => {
    return localStorageService.getItem<{ state: boolean }>(
      StorageKey.quickiesVanillaMode
    )
  }

  const { mutate } = useSWRConfig()
  const { data } = useSWRImmutable(SWR_KEY, localStorageFetcher)

  return {
    state: data?.state || false,

    toggle: useCallback(() => {
      localStorageService.setItem(StorageKey.quickiesVanillaMode, {
        state: !data?.state,
      })
      void mutate(SWR_KEY)
    }, [data?.state, localStorageService, mutate]),
  }
}

export const vanillaModeImageStyle = css<{
  isVanillaMode?: boolean
}>`
  filter: ${({ isVanillaMode }) => (isVanillaMode ? "blur(4px)" : "none")};
  transform: ${({ isVanillaMode }) =>
    isVanillaMode ? "scale(1.1)" : "scale(1)"};
  transition: filter 250ms ease-in-out, transform 250ms ease-in-out;
`
