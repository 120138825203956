import { useCookieConsent } from "@hornet-web-react/core/hooks/use-cookie-consent"
import { useCallback } from "react"
import NiceModal from "@ebay/nice-modal-react"
import { useLazyLoaded } from "@hornet-web-react/core/hooks/use-lazy-loaded"

export const useQuickiesCookieConsent = () => {
  const props = useCookieConsent()
  const { get: getPrivacyManagerModal } = useLazyLoaded(
    () => import("../components/Modals/PrivacyManagerModal")
  )

  return {
    ...props,

    handleCookieConsentModal: useCallback(async () => {
      if (!props.isSet) {
        return getPrivacyManagerModal((modal) => NiceModal.show(modal))
      }

      return Promise.resolve()
    }, [getPrivacyManagerModal, props.isSet]),
  }
}
