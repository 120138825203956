import { FC } from "react"
import styled, { useTheme } from "styled-components"
import Image from "next/image"
import useTranslation from "next-translate/useTranslation"

const Photo = styled(Image)`
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadius.default};
`

const BlurredPhoto = styled(Photo)`
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  filter: blur(10px);
`

const Mask = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  overflow: hidden;
`

type ProfileGalleryPhotoProps = {
  photoUrl: string
  onClick: () => void
  isBlurred?: boolean
}

const i18nKey = "profile:components.profile_gallery_photo"

const ProfileGalleryPhoto: FC<ProfileGalleryPhotoProps> = ({
  photoUrl,
  onClick,
  isBlurred,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  if (isBlurred === true) {
    return (
      <Mask>
        <BlurredPhoto
          src={photoUrl}
          fill={true}
          alt={t(`${i18nKey}.image_alt`)}
          role={"button"}
          onClick={onClick}
          sizes={`${theme.breakpoints.device.xs} 525px, 371px`} // HACK: only because it's single tile
          quality={75} // HACK: only because it's single tile
          // sizes={`${theme.breakpoints.device.xs} 150px, 90px`}
          // quality={25} // it's blurred anyway
        />
      </Mask>
    )
  }

  return (
    <Photo
      src={photoUrl}
      fill={true}
      alt={t(`${i18nKey}.image_alt`)}
      role={"button"}
      onClick={onClick}
      sizes={`${theme.breakpoints.device.xs} 525px, 371px`} // HACK: only because it's single tile
      // sizes={`${theme.breakpoints.device.xs} 150px, 90px`}
    />
  )
}

export default ProfileGalleryPhoto
