import { FC } from "react"
import ShieldWIthLockIcon from "@hornet-web-react/core/components/UI/ShieldWIthLockIcon"
import styled from "styled-components"
import useTranslation from "next-translate/useTranslation"

type GroupPrivateSectionDividerProps = {
  //
}

const i18nKey = "quickies:components.groups.group_private_section_divider"

const GroupPrivateSectionDivider: FC<GroupPrivateSectionDividerProps> = () => {
  const { t } = useTranslation()

  return (
    <>
      <PrivateSectionDivider>
        <PrivateSectionDividerLine />
        <PrivateSectionDividerIcon>
          <ShieldWIthLockIcon />
        </PrivateSectionDividerIcon>
        <PrivateSectionDividerLine />
      </PrivateSectionDivider>

      <PrivateSectionDividerHeadline>
        {t(`${i18nKey}.headline`)}
      </PrivateSectionDividerHeadline>
    </>
  )
}

export default GroupPrivateSectionDivider

const PrivateSectionDivider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.regular} 0
    ${({ theme }) => theme.spacing.less};
`

const PrivateSectionDividerLine = styled.div`
  flex: 1 1 auto;
  height: 0;
  border-bottom: 2px solid #ddd;
`

const PrivateSectionDividerIcon = styled.div`
  width: 32px;
  height: 32px;
  margin: 0 8px;
`

const PrivateSectionDividerHeadline = styled.div`
  ${({ theme }) => theme.font.regular.footnote};
  color: ${({ theme }) => theme.color.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing.more};
  padding: 0 ${({ theme }) => theme.spacing.regular};
  text-align: center;
`
