import styled from "styled-components"
import device from "../../styles/breakpoints"

const Container = styled.div<{ isInApp?: boolean; noPadding?: boolean }>`
  margin: 0 auto;
  padding: ${(props) =>
    props.isInApp || props.noPadding
      ? `0`
      : `0 ${props.theme.spacing.regular}`};

  width: 100%;
  max-width: ${({ theme }) => theme.width.containerMaxWidthMobile};

  @media screen and ${device.gtSm} {
    max-width: ${({ theme }) => theme.width.containerMaxWidthDesktop};

    padding: ${(props) =>
      props.isInApp || props.noPadding
        ? `0`
        : `0 ${props.theme.spacing.double}`};
  }
`

export default Container
