import { FC, PropsWithChildren, useEffect, useRef } from "react"
import NiceModal from "@ebay/nice-modal-react"
import {
  useProfileVerificationApi,
  useProfileVerificationState,
} from "@hornet-web-react/core/contexts/profile-verification-context"
import { useApi } from "@hornet-web-react/core/hooks/use-api"
import ApiService from "@hornet-web-react/core/services/API/ApiService"
import { ApiServiceEndpoint } from "@hornet-web-react/core/services/API/ApiServiceEndpoint"
import { isRight } from "@hornet-web-react/core/utils"
import {
  useSessionEnvironment,
  useSessionUser,
} from "@hornet-web-react/core/contexts/session"
import appConfig from "../app-config"
import { useQuickiesSession } from "../hooks/use-quickies-session"
import { useHornetIpQualityCheck } from "@hornet-web-react/core/hooks/use-hornet-ip-quality-check"
import { useQuickiesLogout } from "../hooks/use-quickies-logout"
import { useQuickiesNavigation } from "../hooks/use-quickies-navigation"
import { useQuickiesPremium } from "../hooks/use-quickies-premium"
import { useRouter } from "next/router"

type ProfileVerificationProps = PropsWithChildren

const ProfileVerification: FC<ProfileVerificationProps> = ({ children }) => {
  const {
    isEmailProfileVerificationOpen,
    isSmsProfileVerificationOpen,
    isRecaptchaProfileVerificationOpen,
    isIpQualityProfileVerificationOpen,
    ipQualityRedirectTo,
  } = useProfileVerificationState()
  const {
    closeEmailProfileVerification,
    closeSmsProfileVerification,
    closeRecaptchaProfileVerification,
    closeIpQualityProfileVerification,
  } = useProfileVerificationApi()
  const { makeApiRequest } = useApi()
  const { currentUser, isAuthenticated } = useSessionUser()
  const { data: quickiesSession, updatePhoneNumber } = useQuickiesSession()
  const { appUrl } = useSessionEnvironment()
  const { logoutAction } = useQuickiesLogout()
  const { getRouteToTrafficFailure } = useQuickiesNavigation()
  const { hasPremium, isPremiumLoading } = useQuickiesPremium()
  const { executeCheckAndHandleFailure } = useHornetIpQualityCheck({
    logoutAction,
    getRouteToTrafficFailure,
    hasPremium,
  })
  const router = useRouter()

  const hasRunHornetIpQualityCheck = useRef(false)

  useEffect(() => {
    if (
      isAuthenticated &&
      !isPremiumLoading &&
      !hasRunHornetIpQualityCheck.current
    ) {
      hasRunHornetIpQualityCheck.current = true

      // kinda hacky solution, but better than undefined
      void executeCheckAndHandleFailure(router.pathname)
    }
    // on purpose do not include `router.pathname` here because it should only
    // trigger once with whatever the route is user initially on
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executeCheckAndHandleFailure, isAuthenticated, isPremiumLoading])

  const openEmailProfileVerification = async (
    isAccountEmailVerified: boolean,
    isVerificationEmailResent = false
  ) => {
    const EmailVerificationModal = (
      await import(
        "@hornet-web-react/core/components-lazy/EmailVerificationModal"
      )
    ).default

    NiceModal.show(EmailVerificationModal, {
      isVerificationEmailResent,
      isAccountEmailVerified,
      isAllowedToChangeEmail: false,
    })
      .then(async (action: unknown) => {
        if (action === "change_email") {
          closeEmailProfileVerification()
          // TODO: we don't have account page
          // void navigateToAccount()
          return
        }

        if (action === "resend") {
          const apiResult = await makeApiRequest(
            ApiService.getEndpoint(
              ApiServiceEndpoint.ResendEmailVerificationPost
            )
          )

          if (isRight(apiResult)) {
            void openEmailProfileVerification(isAccountEmailVerified, true)
          }
        }
      })
      .catch(closeEmailProfileVerification)
  }

  useEffect(() => {
    if (isEmailProfileVerificationOpen && quickiesSession) {
      void openEmailProfileVerification(quickiesSession.isAccountEmailVerified)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmailProfileVerificationOpen, quickiesSession])

  useEffect(() => {
    const openSmsProfileVerification = async (phoneNumber: string | null) => {
      const SmsVerificationModal = (
        await import(
          "@hornet-web-react/core/components-lazy/SmsVerificationModal"
        )
      ).default

      NiceModal.show(SmsVerificationModal, {
        sessionPhoneNumber: phoneNumber,
        updatePhoneNumber,
      }).catch(closeSmsProfileVerification)
    }

    if (isSmsProfileVerificationOpen && quickiesSession) {
      void openSmsProfileVerification(quickiesSession.account.phone_number)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmsProfileVerificationOpen])

  const openRecaptchaProfileVerification = async () => {
    const RecaptchaVerificationModal = (
      await import(
        "@hornet-web-react/core/components-lazy/RecaptchaVerificationModal"
      )
    ).default

    NiceModal.show(RecaptchaVerificationModal, {
      googleRecaptchaSiteKey: appConfig.googleRecaptcha.siteKey,
      googleRecaptchaV3SiteKey: appConfig.googleRecaptchaV3.siteKey,
    })
      .then(closeRecaptchaProfileVerification)
      // could try to refresh the page here instead
      .catch(closeRecaptchaProfileVerification)
  }

  useEffect(() => {
    if (isRecaptchaProfileVerificationOpen) {
      void openRecaptchaProfileVerification()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecaptchaProfileVerificationOpen])

  const openIpQualityProfileVerification = async (
    redirectTo: string | undefined
  ) => {
    const IpQualityVerificationModal = (
      await import(
        "@hornet-web-react/core/components-lazy/IpQualityVerificationModal"
      )
    ).default

    const ipQualityScriptSrc = appConfig.ipQualityScore.hornetScriptSrc

    const successIpqHandler = () => {
      closeIpQualityProfileVerification()

      if (redirectTo) {
        // cannot router push, but need to do hard redirect because the CSP update
        // needs to be loaded fresh (otherwise the page still allows the unsafe-eval)
        window.location.href = `${appUrl}/${redirectTo}`
      }
    }

    const errorIpqHandler = () => {
      closeIpQualityProfileVerification()
      window.location.href = `${appUrl}/404`
    }

    NiceModal.show(IpQualityVerificationModal, {
      profileId: currentUser?.profileId,
      ipQualityScriptSrc,
    })
      .then(successIpqHandler)
      // could try to refresh the page here instead
      .catch(errorIpqHandler)
  }

  useEffect(() => {
    if (isIpQualityProfileVerificationOpen) {
      void openIpQualityProfileVerification(ipQualityRedirectTo)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIpQualityProfileVerificationOpen, ipQualityRedirectTo])

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export default ProfileVerification
