import { FC } from "react"
import styled from "styled-components"
import Link from "next/link"
import useTranslation from "next-translate/useTranslation"
import { useRouter } from "next/router"

type FooterProps = {
  //
}

export const FOOTER_HEIGHT = "26px"

const i18nKey = "quickies:components.layout.footer"

const Footer: FC<FooterProps> = () => {
  const { t } = useTranslation()
  const router = useRouter()

  // is handled by Landing itself
  if (router.pathname === "/") {
    return null
  }

  return (
    <Wrapper>
      <ContentWrapper>&copy; Queer Networks, Inc. 2024</ContentWrapper>
      <ContentWrapper>
        <FooterLink href={"/terms-of-service"}>
          {t(`${i18nKey}.terms_of_service`)}
        </FooterLink>{" "}
        •{" "}
        <FooterLink href={"/privacy-policy"}>
          {t(`${i18nKey}.privacy_policy`)}
        </FooterLink>{" "}
        •{" "}
        <FooterLink href={"/guidelines"}>
          {t(`${i18nKey}.usage_guidelines`)}
        </FooterLink>{" "}
        •{" "}
        <FooterLink href={"/guidelines"}>
          {t(`${i18nKey}.photo_guidelines`)}
        </FooterLink>{" "}
        •{" "}
        <FooterLink href={"/impressum"}>{t(`${i18nKey}.impressum`)}</FooterLink>
      </ContentWrapper>
    </Wrapper>
  )
}

export default Footer

const Wrapper = styled.div`
  background: #2b2b2b; // not in theme
  width: 100%;
  height: ${FOOTER_HEIGHT};
  line-height: ${FOOTER_HEIGHT};
  align-items: center;
  justify-content: space-between;
  font-size: 11px;
  color: #8a8a8a;
  display: none;
  padding: 0 ${({ theme }) => theme.spacing.regular};

  @media screen and ${({ theme }) =>
      theme.breakpoints.mediaQueryHasVisibleLeftPanel} {
    display: flex;
  }
`

const ContentWrapper = styled.div``

const FooterLink = styled(Link)`
  color: #8a8a8a;
  text-decoration: none;

  &:hover,
  &:focus {
    color: #8a8a8a;
  }
`
