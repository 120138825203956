import Link from "next/link"
import useTranslation from "next-translate/useTranslation"
import styled from "styled-components"
import Container from "./Container"
import QuickiesLogo from "./QuickiesLogo"
import { UserSignedUpOrigin } from "@hornet-web-react/login/models/login-analytics-event"
import G7LoginButton from "./G7LoginButton"
import G7SignupButton from "./G7SignupButton"

type TopNavBarPublicProps = {
  redirectTo: string | null
  userSignedUpOrigin: UserSignedUpOrigin
}

function TopNavBarPublic({
  redirectTo,
  userSignedUpOrigin,
}: TopNavBarPublicProps) {
  const { t } = useTranslation()

  return (
    <div>
      <Wrapper>
        <TopNavBarPublicContainer isAuthenticated={false}>
          <Content hasNavBar={true}>
            <Logo href={"/"}>
              <QuickiesLogo
                alt={t("quickies:alt.quickies_logo")}
                height={24}
                data-test={"webAppLogo"}
              />
            </Logo>

            <AuthOptions>
              <G7LoginButton
                redirectTo={redirectTo}
                userSignedUpOrigin={userSignedUpOrigin}
                size={"small"}
              />

              <G7SignupButton
                redirectTo={redirectTo}
                userSignedUpOrigin={userSignedUpOrigin}
                size={"small"}
              />
            </AuthOptions>
          </Content>
        </TopNavBarPublicContainer>
      </Wrapper>
      <BarWithShadow />
    </div>
  )
}

export default TopNavBarPublic

const Wrapper = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.height.topNavBar};
  background: ${({ theme }) => theme.color.bg.topNavBar};
  color: ${({ theme }) => theme.color.text.primary};
  z-index: 300;
  position: fixed;
`

const BarWithShadow = styled(Wrapper)`
  box-shadow: 0px 4px 4px 0px #0e0c1b4d;
  z-index: 100;
`

const TopNavBarPublicContainer = styled(Container)<{
  isAuthenticated: boolean
}>`
  height: ${({ theme }) => theme.height.topNavBar};
  position: relative;
  z-index: 200;
`

const Content = styled.div<{
  hasNavBar: boolean
}>`
  height: 100%;
  flex: 1 1 auto;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.color.bg.topNavBar};
  position: relative;

  @media screen and ${({ theme }) =>
      theme.breakpoints.mediaQueryHasVisibleLeftPanel} {
    justify-content: ${({ hasNavBar }) =>
      hasNavBar ? "space-between" : "center"};
  }
`

const Logo = styled(Link)`
  display: block;
  position: relative;
`

const AuthOptions = styled.div`
  display: none;

  @media screen and ${({ theme }) =>
      theme.breakpoints.mediaQueryHasVisibleLeftPanel} {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
  }
`
