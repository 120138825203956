import { FC, PropsWithChildren, useState } from "react"
import Image, { StaticImageData } from "next/image"
import { MemberOnlineStatus } from "@hornet-web-react/core/models/member.model"
import styled, { css, useTheme } from "styled-components"
import UserLastOnline from "./UserLastOnline"
import ProfilePhotoPill from "./ProfilePhotoPill"
import ProfilePhotoUploadCta from "./ProfilePhotoUploadCta"
import { EyeSlashIcon } from "@heroicons/react/24/solid"

type ProfilePhotoProps = PropsWithChildren & {
  photoUrl: string | StaticImageData
  onlineStatus?: MemberOnlineStatus
  isVanillaMode?: boolean
  photoOnClick?: () => void
}

const ProfilePhoto: FC<ProfilePhotoProps> = ({
  children,
  photoUrl,
  onlineStatus,
  isVanillaMode,
  photoOnClick,
}) => {
  const theme = useTheme()
  const [isUncensored, setIsUncensored] = useState(false)
  const isEmpty = typeof photoUrl !== "string"

  let wrapperOnClick =
    typeof photoOnClick === "function"
      ? photoOnClick
      : () => {
          // noop
        }

  if (isVanillaMode && !isUncensored) {
    wrapperOnClick = () => setIsUncensored(!isUncensored)
  }

  return (
    <Wrapper
      isEmpty={isEmpty}
      isVanillaMode={isVanillaMode}
      role={wrapperOnClick !== undefined ? "button" : "presentation"}
      onClick={(event) => {
        event.stopPropagation()
        wrapperOnClick()
      }}
    >
      {children}

      {isEmpty ? (
        <EmptyImage src={photoUrl.src} alt={"Photo"} width={210} height={210} />
      ) : (
        <>
          {isVanillaMode && !isUncensored ? (
            <VanillaModeIconWrapper>
              <EyeSlashIcon height={70} color={"black"} />
            </VanillaModeIconWrapper>
          ) : null}
          <ImageWrapper isVanillaMode={isVanillaMode && !isUncensored}>
            <Image
              src={photoUrl}
              alt={"Photo"}
              fill={true}
              priority={true}
              sizes={`${theme.breakpoints.device.xs} 100vw, 840px`}
            />
          </ImageWrapper>
        </>
      )}

      {onlineStatus && <LastOnline status={onlineStatus} />}
    </Wrapper>
  )
}

const isEmptyStyle = css`
  background: ${({ theme }) => theme.color.bg.profilePhoto};
`

const Wrapper = styled.div<{
  isEmpty: boolean
  isVanillaMode?: boolean
}>`
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-bottom: 100%;

  cursor: ${({ isEmpty, isVanillaMode }) =>
    !isEmpty && isVanillaMode ? "pointer" : "default"};

  ${(props) => props.isEmpty && isEmptyStyle}
`

const EmptyImage = styled.img`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const ImageWrapper = styled.div<{
  isVanillaMode?: boolean
}>`
  width: 100%;
  height: 100%;
  position: absolute;
  filter: ${({ isVanillaMode }) => (isVanillaMode ? "blur(25px)" : "none")};
  transform: ${({ isVanillaMode }) =>
    isVanillaMode ? "scale(1.5)" : "scale(1)"};
  transition: filter 250ms ease-in-out, transform 250ms ease-in-out;
`

const LastOnline = styled(UserLastOnline)`
  position: absolute;
  bottom: 17px;
  left: 15px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  z-index: 10;
`

const ProfilePhotoPills = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing.regular};
  right: ${({ theme }) => theme.spacing.regular};
  left: ${({ theme }) => theme.spacing.regular};
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const ProfilePhotoActions = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing.regular};
  right: ${({ theme }) => theme.spacing.regular};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 44px;
  z-index: 1;
`

const ProfilePhotoTopLeftCorner = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing.half};
  left: ${({ theme }) => theme.spacing.half};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 44px;
  z-index: 1;
`

const ProfilePhotoBottomLeftCorner = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing.half};
  left: ${({ theme }) => theme.spacing.half};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 44px;
  z-index: 1;
`

const VanillaModeIconWrapper = styled.span`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  opacity: 0.25;
`

export default Object.assign(ProfilePhoto, {
  Pills: ProfilePhotoPills,
  Pill: ProfilePhotoPill,
  UploadCta: ProfilePhotoUploadCta,
  Actions: ProfilePhotoActions,
  TopLeftCorner: ProfilePhotoTopLeftCorner,
  BottomLeftCorner: ProfilePhotoBottomLeftCorner,
})
