import { FC } from "react"
import useTranslation from "next-translate/useTranslation"
import ProfileContentCard from "@hornet-web-react/profile/components/ProfileContentCard"
import QuickiesMemberModel from "../../models/quickies-member.model"
import ProfileGalleryPhoto from "@hornet-web-react/profile/components/ProfileGalleryPhoto"
import styled from "styled-components"
import { useQuickiesVanillaMode } from "../../hooks/use-quickies-vanilla-mode"
import VerifyDickButton from "./VerifyDickButton"
import { useQuickiesSession } from "../../hooks/use-quickies-session"
import VerifiedDickBadge from "./VerifiedDickBadge"
import { useQuickiesFeatureFlags } from "../../hooks/use-quickies-feature-flags"

type QuickiesProfilePhotosProps = {
  member: QuickiesMemberModel
  isCurrentUser: boolean
  onClick: (photoIndex: number) => void
}

const i18nKey = "quickies:components.quickies_profile.quickies_profile_photos"

const QuickiesQuickiesProfilePhotos: FC<QuickiesProfilePhotosProps> = ({
  member,
  isCurrentUser,
  onClick,
}) => {
  const { t } = useTranslation()
  const { state: isVanillaMode } = useQuickiesVanillaMode()
  const { data: quickiesSession } = useQuickiesSession()
  const { isDickVerificationEnabled } = useQuickiesFeatureFlags()

  // first picture is the profile pic, so list here only if there's more
  if (member.featuredPhotos.length <= 1) {
    return null
  }

  const isEligibleForDickVerification =
    isDickVerificationEnabled &&
    !!quickiesSession?.isEligibleForDickVerification

  // current user has either listing of all featured photos, or shown
  // exactly 4 tiles (if they have less than 4 featured photos) with
  // the option to add more
  const tiles = member.featuredPhotos.slice(1).map((photo, index) => (
    <PhotoTile key={photo.id}>
      <ProfileGalleryPhoto
        photoUrl={photo.squareUrl}
        onClick={() => onClick(index)}
        isBlurred={isVanillaMode}
      />

      {isDickVerificationEnabled && photo.isVerifiedDick && (
        <VerifiedDickIcon>
          <VerifiedDickBadge size={24} verifiedDickPhotoUrl={photo.squareUrl} />
        </VerifiedDickIcon>
      )}
    </PhotoTile>
  ))

  return (
    <ProfileContentCard>
      <ProfileContentCard.Header>
        {t(`${i18nKey}.heading`)}
      </ProfileContentCard.Header>
      <ProfileContentCard.Content>
        <PhotosGrid>{tiles}</PhotosGrid>

        {isCurrentUser &&
          isDickVerificationEnabled &&
          isEligibleForDickVerification && (
            <DickVerificationButtonWrapper>
              <VerifyDickButton isLongButton={true} />
            </DickVerificationButtonWrapper>
          )}
      </ProfileContentCard.Content>
    </ProfileContentCard>
  )
}

export default QuickiesQuickiesProfilePhotos

const PhotosGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: ${({ theme }) => theme.spacing.less};
`

const PhotoTile = styled.div`
  width: 100%;
  padding-bottom: 100%;
  position: relative;
`

const DickVerificationButtonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.regular};
`

const VerifiedDickIcon = styled.span`
  position: absolute;
  bottom: 2px;
  right: 2px;
`
