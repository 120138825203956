import { z } from "zod"

const PhotoDataKey = z.enum(["thumb", "crop"])
type PhotoDataKey = z.infer<typeof PhotoDataKey>

export const PhotoDataInput = z.object({
  url: z.string(),
  width: z.number().min(0),
  height: z.number().min(0),
  key: PhotoDataKey,
  cropWidth: z.number().min(0),
  cropHeight: z.number().min(0),
  x1: z.number().min(0),
  y1: z.number().min(0),
})

export type PhotoDataInput = z.infer<typeof PhotoDataInput>

export const PhotoData = PhotoDataInput.extend({
  x2: z.number().min(0),
  y2: z.number().min(0),
})

export type PhotoData = z.infer<typeof PhotoData>

export default class PhotoDataModel implements PhotoData {
  url = "" // image URL
  width = 0 // image width
  height = 0 // image height
  key: PhotoDataKey

  cropWidth = 0 // crop width
  cropHeight = 0 // crop height
  x1 = 0 // crop start X
  y1 = 0 // crop start Y

  constructor(data: PhotoDataInput) {
    this.key = data.key
    this.url = data.url
    this.width = data.width
    this.height = data.height
    this.cropWidth = data.cropWidth
    this.cropHeight = data.cropHeight
    this.x1 = data.x1
    this.y1 = data.y1
  }

  static createEmpty(key: PhotoDataKey): PhotoDataModel {
    return new PhotoDataModel({
      key,
      url: "",
      width: 0,
      height: 0,
      cropWidth: 0,
      cropHeight: 0,
      x1: 0,
      y1: 0,
    })
  }

  get x2() {
    return this.x1 + this.cropWidth
  }

  get y2() {
    return this.y1 + this.cropHeight
  }

  serialize(): PhotoData {
    return {
      key: this.key,
      url: this.url,
      width: this.width,
      height: this.height,
      cropWidth: this.cropWidth,
      cropHeight: this.cropHeight,
      x1: this.x1,
      x2: this.x2,
      y1: this.y1,
      y2: this.y2,
    }
  }
}
