import {
  EntitlementFeature,
  EntitlementInterface,
} from "@hornet-web-react/core/types/entitlements"
import { QuickiesEntitlementState } from "../models/types"

export const getQuickiesEntitlementStateFromSessionEntitlements = (
  entitlements?: EntitlementInterface[]
): [QuickiesEntitlementState, EntitlementInterface | undefined] => {
  if (entitlements === undefined) {
    return [QuickiesEntitlementState.enum.unknown, undefined]
  }

  // docs:
  // https://www.notion.so/hornet-engineering/Onboarding-Invitation-to-Hornet-X-flow-0efcd45fb5154296970f67f4bb5d2ea8?pvs=4#ef00ca0046d24607b574343d69cca3eb
  const quickiesEntitlement = entitlements.find(
    (entl) => entl.feature === EntitlementFeature.Quickies
  )

  if (quickiesEntitlement) {
    switch (quickiesEntitlement.metadata.state) {
      case "free":
        return [QuickiesEntitlementState.enum.free, quickiesEntitlement]

      case "purchased":
        return [QuickiesEntitlementState.enum.purchased, quickiesEntitlement]

      case "freemium":
        return [QuickiesEntitlementState.enum.freemium, quickiesEntitlement]
    }
  }

  // when entitlement is missing, app is basically unreachable
  // eg it's invite-only and this user is not invited
  return [QuickiesEntitlementState.enum.disabled, undefined]
}
