import useTranslation from "next-translate/useTranslation"
import {
  useSessionEnvironment,
  useSessionUser,
} from "@hornet-web-react/core/contexts/session"
import { DefaultButtonLink } from "@hornet-web-react/core/components/UI/TextButton"
import WebViewScreen from "@hornet-web-react/core/components/Layout/WebViewScreen"
import { useQuickiesNavigation } from "../../hooks/use-quickies-navigation"
import InfoPage from "./InfoPage"

export default function PageError({
  statusCode,
  message,
}: {
  statusCode?: number
  message?: string
}) {
  const { t } = useTranslation()
  const { isInApp } = useSessionEnvironment()
  const { isAuthenticated } = useSessionUser()
  const { routeForLanding, routeForHome } = useQuickiesNavigation()

  const text = isInApp
    ? t("quickies:error_page.subtitle")
    : message && statusCode // TODO: pretty i18n message if it's not translated
    ? `${message} (${statusCode})`
    : null

  const component = (
    <InfoPage>
      <InfoPage.Logo />
      <InfoPage.Header>{t("quickies:error_page.headline")}</InfoPage.Header>
      {text && <InfoPage.SubHeader>{text}</InfoPage.SubHeader>}

      {!isInApp && (
        <InfoPage.CtaWrapper>
          <DefaultButtonLink
            href={isAuthenticated ? routeForHome : routeForLanding}
          >
            {t("quickies:error_page.go_to_homepage")}
          </DefaultButtonLink>
        </InfoPage.CtaWrapper>
      )}
    </InfoPage>
  )

  if (isInApp) {
    return <WebViewScreen>{component}</WebViewScreen>
  }

  return component
}
