import MemberAvatar, {
  MemberAvatarProps,
} from "@hornet-web-react/core/components/UI/MemberAvatar"
import { FC } from "react"
import { useQuickiesVanillaMode } from "../hooks/use-quickies-vanilla-mode"
import styled from "styled-components"

type QuickiesMemberAvatarProps = MemberAvatarProps & {
  isPlaceholderAnimated?: boolean
}

const QuickiesMemberAvatar: FC<QuickiesMemberAvatarProps> = (props) => {
  const { state: isVanillaMode } = useQuickiesVanillaMode()

  return (
    <StyledMemberAvatar
      {...props}
      isVanillaMode={isVanillaMode}
      isPlaceholderAnimated={
        typeof props.isPlaceholderAnimated === "boolean"
          ? props.isPlaceholderAnimated
          : true
      }
    />
  )
}

export default QuickiesMemberAvatar

const StyledMemberAvatar = styled(MemberAvatar)`
  border: 2px solid ${({ theme }) => theme.color.bg.light01};
  box-shadow: 0 0 0 2px
    ${({ theme, avatarUrl }) =>
      avatarUrl ? theme.color.tint.default : theme.color.bg.light04};
  transition: box-shadow 300ms ease-in-out;

  img {
    // otherwise the image is kinda not filling the whole space
    transform: translate(-1px, -1px) scale(1.05);
  }
`
