import React, { FC, useCallback, useState } from "react"
import styled from "styled-components"
import useTranslation from "next-translate/useTranslation"
import { QuickiesGroupMembership } from "../../models/quickies-group.model"
import { QuickiesGroupId } from "@hornet-web-react/core/types"
import { useApi } from "@hornet-web-react/core/hooks/use-api"
import { ApiServiceEndpoint } from "@hornet-web-react/core/services/API/ApiServiceEndpoint"
import { useFlashMessage } from "@hornet-web-react/core/hooks/use-flash-message"
import { isRight, unwrapEither } from "@hornet-web-react/core/utils"
import { reloadQuickiesGroup } from "../../hooks/use-quickies-group"
import TextButton, {
  BlackButtonStyle,
} from "@hornet-web-react/core/components/UI/TextButton"
import GroupRequest from "./GroupRequest"
import OverlaySpinner from "@hornet-web-react/core/components/UI/OverlaySpinner"
import { useEventTrackerService } from "../../hooks/use-event-tracker-service"
import QuickiesAnalyticsEvent from "../../models/quickies-analytics-event"

type GroupRequestsProps = {
  requests: QuickiesGroupMembership[]
  groupId: QuickiesGroupId
}

const i18nKey = "quickies:components.groups.group_requests"

const GroupRequests: FC<GroupRequestsProps> = ({ groupId, requests }) => {
  const { t } = useTranslation()
  const { getEndpoint, makeApiRequest } = useApi()
  const { showOops } = useFlashMessage()
  const { reportEvent } = useEventTrackerService()

  const [isAcceptingAll, setIsAcceptingAll] = useState(false)
  const [isAcceptingAllDone, setIsAcceptingAllDone] = useState(false)

  const handleAcceptAllOnClick = useCallback(async () => {
    void reportEvent(
      QuickiesAnalyticsEvent.groupPreviewModalTapOnAcceptAllRequests(groupId)
    )

    setIsAcceptingAll(true)

    const apiResult = await makeApiRequest(
      getEndpoint(ApiServiceEndpoint.QuickiesAcceptAllRequestToJoinGroupPut, [
        groupId,
      ])
    )

    if (isRight(apiResult)) {
      setIsAcceptingAll(false)
      setIsAcceptingAllDone(true)

      await new Promise((resolve) => setTimeout(resolve, 2000))

      await reloadQuickiesGroup(groupId)
      setIsAcceptingAllDone(false)
      return
    }

    const error = unwrapEither(apiResult)
    void showOops(error)
    setIsAcceptingAll(false)
  }, [getEndpoint, groupId, makeApiRequest, reportEvent, showOops])

  return (
    <Wrapper>
      <Heading>
        <div>
          <Headline>{t(`${i18nKey}.headline`)}</Headline>
          <Text>{t(`${i18nKey}.text`)}</Text>
        </div>

        <AcceptAlLButton
          isLoading={isAcceptingAll}
          isDone={isAcceptingAllDone}
          onClick={handleAcceptAllOnClick}
          size={"small"}
        >
          {t(`${i18nKey}.actions.accept_all`)}
        </AcceptAlLButton>
      </Heading>

      <RequestsList>
        <OverlaySpinner isVisible={isAcceptingAll || isAcceptingAllDone} />

        {requests.map((membership) => (
          <GroupRequest
            key={membership.groupMembershipId}
            groupId={groupId}
            membership={membership}
          />
        ))}
      </RequestsList>
    </Wrapper>
  )
}

export default GroupRequests

const Wrapper = styled.div``

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.double};
`

const Headline = styled.div`
  ${({ theme }) => theme.font.bold.title2};
  // override
  font-size: 25px;
  margin-bottom: 4px;
`

const Text = styled.div`
  ${({ theme }) => theme.font.regular.subheadline};
`

const RequestsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding: 2px;
`

const AcceptAlLButton = styled(TextButton)`
  flex-shrink: 0;
  ${BlackButtonStyle};
`
