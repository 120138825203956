import { FC, PropsWithChildren, useEffect } from "react"
import appConfig from "../app-config"
import AppStart from "@hornet-web-react/core/components/Utils/AppStart"
import { UseAppStartEmitter } from "@hornet-web-react/core/hooks/use-app-start"
import { useSessionUser } from "@hornet-web-react/core/contexts/session"
import { getQuickiesEntitlementStateFromSessionEntitlements } from "../utils/get-quickies-entitlement-state-from-session-entitlements"
import { createEntitlement } from "@hornet-web-react/core/models/entitlement-model"
import { QuickiesEntitlementState } from "../models/types"
import { useRouter } from "next/router"
import EventTrackerService from "@hornet-web-react/core/services/EventTrackerService"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import { CORE_TYPES } from "@hornet-web-react/core/services/types"
import TrackEvent from "@hornet-web-react/core/models/track-event"
import { useAppContainerContextApi } from "../contexts/app-container-context"

type QuickiesAppStartProps = PropsWithChildren

const QuickiesAppStart: FC<QuickiesAppStartProps> = ({ children }) => {
  const eventTrackerService = useCoreService<EventTrackerService>(
    CORE_TYPES.EventTrackerService
  )
  const { enableSafeBottomPadding } = useAppContainerContextApi()

  const { currentUser } = useSessionUser()
  const router = useRouter()

  useEffect(() => {
    const [entitlementState] =
      getQuickiesEntitlementStateFromSessionEntitlements(
        (currentUser?.entitlements || []).map(createEntitlement)
      )

    const hasPremium =
      entitlementState === QuickiesEntitlementState.enum.purchased

    const changeAppStartDoneChangedHandler = (appStartDone: boolean) => {
      if (appStartDone) {
        void eventTrackerService.reportPageLoadedOnce(
          router.pathname,
          hasPremium
        )
      }
    }

    const handleRouteComplete = (routerPathname: string) => {
      void eventTrackerService.report(
        TrackEvent.pageViewed(routerPathname, hasPremium)
      )

      // fixes the situation when user opens the keyboard from chat input (on
      // inbox conversation screen) - which disables the safe bottom padding - and
      // then leaves *via swiping to the left* (which doesn't trigger the keyboard
      // blur event captured by `handleChatInputOnBlur`)
      enableSafeBottomPadding()
    }

    UseAppStartEmitter.on(
      "appStartDoneChanged",
      changeAppStartDoneChangedHandler
    )

    router.events.on("routeChangeComplete", handleRouteComplete)

    return () => {
      UseAppStartEmitter.off(
        "appStartDoneChanged",
        changeAppStartDoneChangedHandler
      )
      router.events.off("routeChangeComplete", handleRouteComplete)
    }
  }, [
    currentUser,
    enableSafeBottomPadding,
    eventTrackerService,
    router.events,
    router.pathname,
  ])

  return (
    <AppStart appConfig={appConfig} hasPushNotifications={true}>
      {children}
    </AppStart>
  )
}

export default QuickiesAppStart
