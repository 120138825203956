import { useCallback } from "react"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import { TYPES as CORE_TYPES } from "@hornet-web-react/core/services/types"
import ApiService from "@hornet-web-react/core/services/API/ApiService"
import { RecaptchaToken, useGoogleRecaptchaV3 } from "./use-google-recaptcha-v3"
import EventTrackerService from "@hornet-web-react/core/services/EventTrackerService"
import TrackEvent from "@hornet-web-react/core/models/track-event"
import {
  Either,
  isLeft,
  isRight,
  makeRight,
} from "@hornet-web-react/core/utils"
import { EventEmitter } from "eventemitter3"
import { ApiRequestError, useApi } from "./use-api"
import { ApiServiceEndpoint } from "@hornet-web-react/core/services/API/ApiServiceEndpoint"
import {
  HornetProfileId,
  QuickiesProfileId,
} from "@hornet-web-react/core/types"

type SocialInteractionResult = Either<ApiRequestError | undefined, boolean>

const eventEmitter = new EventEmitter()

export function useSocialInteractions(googleRecaptchaV3SiteKey: string) {
  const { makeApiRequest } = useApi()
  const eventTrackerService = useCoreService<EventTrackerService>(
    CORE_TYPES.EventTrackerService
  )
  const { getToken } = useGoogleRecaptchaV3({
    googleRecaptchaV3SiteKey,
  })

  return {
    eventEmitter,
    toggleFollow: useCallback(
      async (
        profileId: HornetProfileId,
        isFavourite: boolean
      ): Promise<SocialInteractionResult> => {
        void eventTrackerService.report(
          isFavourite
            ? TrackEvent.guyTapOnUnfollow(profileId)
            : TrackEvent.guyTapOnFollow(profileId)
        )

        // optimistic update = first update the UI, then call the API
        // and revert on failure

        if (isFavourite) {
          eventEmitter.emit("memberUnfollowed", profileId)

          const apiResult = await makeApiRequest(
            ApiService.getEndpoint(ApiServiceEndpoint.FavouritesDelete, [
              profileId,
            ])
          )

          if (isLeft(apiResult)) {
            // revert
            eventEmitter.emit("memberFollowed", profileId)

            return apiResult
          }

          return makeRight(true)
        }

        eventEmitter.emit("memberFollowed", profileId)

        const apiResult = await makeApiRequest(
          ApiService.getEndpoint(ApiServiceEndpoint.FavouritesPost),
          {
            favourite_id: profileId,
            "v3-recaptcha-response": await getToken(
              RecaptchaToken.CreateFavourite
            ),
          }
        )

        if (isLeft(apiResult)) {
          // revert
          eventEmitter.emit("memberUnfollowed", profileId)

          return apiResult
        }

        return makeRight(true)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    ),

    blockMember: useCallback(
      async (profileId: HornetProfileId): Promise<SocialInteractionResult> => {
        void eventTrackerService.report(TrackEvent.guyTapOnBlock())

        const apiResult = await makeApiRequest(
          ApiService.getEndpoint(ApiServiceEndpoint.BlocksPost),
          {
            member_id: profileId,
          }
        )

        if (isRight(apiResult)) {
          eventEmitter.emit("memberBlocked", profileId)

          return makeRight(true)
        }

        return apiResult
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    ),

    unblockMember: useCallback(
      async (profileId: HornetProfileId): Promise<SocialInteractionResult> => {
        const apiResult = await makeApiRequest(
          ApiService.getEndpoint(ApiServiceEndpoint.BlocksDelete, [profileId])
        )

        if (isRight(apiResult)) {
          eventEmitter.emit("memberUnblocked", profileId)

          return makeRight(true)
        }

        return apiResult
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    ),

    reportMember: useCallback(
      async (
        memberProfileId: HornetProfileId,
        reasonId: string,
        comments?: string
      ): Promise<SocialInteractionResult> => {
        type ReportPayload = {
          report: {
            report_reason_id: string
            member_id: string
            detail?: string
          }
        }

        const params: ReportPayload = {
          report: {
            report_reason_id: reasonId,
            member_id: memberProfileId,
          },
        }

        if (comments) {
          params.report.detail = comments
        }

        const apiResult = await makeApiRequest(
          ApiService.getEndpoint(ApiServiceEndpoint.ReportsPost),
          params
        )

        if (isRight(apiResult)) {
          eventEmitter.emit("memberReported", memberProfileId)

          return makeRight(true)
        }

        return apiResult
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    ),

    reportQuickiesMember: useCallback(
      async (
        memberProfileId: QuickiesProfileId,
        reasonId: string,
        comments?: string
      ): Promise<SocialInteractionResult> => {
        type ReportPayload = {
          report: {
            report_reason_id: string
            detail?: string
          }
        }

        const params: ReportPayload = {
          report: {
            report_reason_id: reasonId,
          },
        }

        if (comments) {
          params.report.detail = comments
        }

        const apiResult = await makeApiRequest(
          ApiService.getEndpoint(ApiServiceEndpoint.QuickiesReportsPost, [
            memberProfileId,
          ]),
          params
        )

        if (isRight(apiResult)) {
          eventEmitter.emit("memberReported", memberProfileId)

          return makeRight(true)
        }

        return apiResult
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    ),
  }
}
