import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useReducer,
} from "react"

type AppContainerContextState = {
  isSafeBottomPaddingDisabled: boolean
}

type AppContainerContextApi = {
  disableSafeBottomPadding: () => void
  enableSafeBottomPadding: () => void
}

const AppContainerContextStateContext = createContext<AppContainerContextState>(
  {
    isSafeBottomPaddingDisabled: false,
  }
)
const AppContainerContextApiContext = createContext<AppContainerContextApi>(
  {} as AppContainerContextApi
)

type Actions =
  | { type: "disableSafeBottomPadding" }
  | { type: "enableSafeBottomPadding" }

const reducer = (
  state: AppContainerContextState,
  action: Actions
): AppContainerContextState => {
  switch (action.type) {
    case "disableSafeBottomPadding":
      return {
        ...state,
        isSafeBottomPaddingDisabled: true,
      }

    case "enableSafeBottomPadding":
      return {
        ...state,
        isSafeBottomPaddingDisabled: false,
      }
  }
}

export const AppContainerContextContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducer, {} as AppContainerContextState)

  const api = useMemo(() => {
    const disableSafeBottomPadding = () => {
      dispatch({ type: "disableSafeBottomPadding" })
    }

    const enableSafeBottomPadding = () => {
      dispatch({ type: "enableSafeBottomPadding" })
    }

    return {
      disableSafeBottomPadding,
      enableSafeBottomPadding,
    }
  }, [])

  return (
    <AppContainerContextApiContext.Provider value={api}>
      <AppContainerContextStateContext.Provider value={state}>
        {children}
      </AppContainerContextStateContext.Provider>
    </AppContainerContextApiContext.Provider>
  )
}

export const useAppContainerContextApi = () =>
  useContext(AppContainerContextApiContext)
export const useAppContainerContextState = () =>
  useContext(AppContainerContextStateContext)
