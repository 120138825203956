import { z } from "zod"

export const HornetPrivatePhotoId = z.coerce
  .string()
  .brand<"HornetPrivatePhotoId">()
export type HornetPrivatePhotoId = z.infer<typeof HornetPrivatePhotoId>

export const QuickiesPrivatePhotosFromHornetApiPayload = z.object({
  private_photos: z.array(
    z.object({
      id: HornetPrivatePhotoId,
      square_url: z.string().url(),
    })
  ),
})
export type QuickiesPrivatePhotosFromHornetApiPayload = z.infer<
  typeof QuickiesPrivatePhotosFromHornetApiPayload
>

export const QuickiesPrivatePhotosFromHornetCreateApiPayload = z.object({
  photo_id: z.number(),
  set_as_profile_photo: z.boolean(),
})
export type QuickiesPrivatePhotosFromHornetCreateApiPayload = z.infer<
  typeof QuickiesPrivatePhotosFromHornetCreateApiPayload
>
