import "@abraham/reflection"
import appConfig from "../app-config"
import { createContainer as createServiceContainer } from "@hornet-web-react/core/services/ioc"
import getCurrentAppUrl from "@hornet-web-react/core/utils/get-current-app-url"
import { CreateContainer } from "@hornet-web-react/core/types/services"
import { UNKNOWN_DEVICE_ID } from "@hornet-web-react/core/utils/constants"
import {
  defaultServerSessionAnalytics,
  DeviceLocation,
} from "@hornet-web-react/core/types/session"

const createContainer: CreateContainer = (context) => {
  if (typeof context === "undefined") {
    return createServiceContainer(appConfig, {
      appUrl: getCurrentAppUrl(appConfig.appUrlProtocol, appConfig.appUrl),
      publicAppUrl: appConfig.appUrl,
      locale: appConfig.defaultLocale,
      deviceId: UNKNOWN_DEVICE_ID,
      profileId: null,
      accessToken: null,
      communityToken: null,
      deviceLocation: DeviceLocation.parse(""),
      isInApp: false,
      setCommunityToken: () => {
        // no-op
      },
      sessionQueryParams: new URLSearchParams(),
      appName: "Quickies",
      serverSessionAnalytics: defaultServerSessionAnalytics,
    })
  }

  // add app name there manually
  context.appName = "Quickies"

  return createServiceContainer(appConfig, context)
}

export default createContainer
