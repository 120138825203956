import React, { FC } from "react"
import styled, { useTheme } from "styled-components"
import { FaCrosshairs } from "react-icons/fa"
import invariant from "tiny-invariant"
import { useQuickiesMapApi } from "../../contexts/quickies-map-context"
import { useQuickiesNavigation } from "../../hooks/use-quickies-navigation"
import TextButton from "@hornet-web-react/core/components/UI/TextButton"
import useTranslation from "next-translate/useTranslation"
import { ChatBubbleLeftRightIcon } from "@heroicons/react/24/solid"
import { MemberAvatarProps } from "@hornet-web-react/core/components/UI/MemberAvatar"
import { QuickiesGroupApiPayload } from "../../models/quickies-group.model"
import GroupStartsAt from "./GroupStartsAt"
import GroupHostedBy from "./GroupHostedBy"
import RichTextBlock from "@hornet-web-react/core/components/UI/RichTextBlock"
import { QuickiesGroupChatId } from "@hornet-web-react/core/types"
import { useQuickiesGroupDefaultName } from "../../hooks/use-quickies-group"

type GroupAboutProps = {
  title: QuickiesGroupApiPayload["hangout"]["title"]
  description: QuickiesGroupApiPayload["hangout"]["description"]
  startsAt: QuickiesGroupApiPayload["hangout"]["starts_at"]
  hostedBy:
    | {
        displayName: string
        thumbnailUrl: MemberAvatarProps["avatarUrl"]
        onClick?: MemberAvatarProps["onClick"]
      }
    | undefined
  lat?: number
  lng?: number
  onShowOnMap?: () => void
  groupChatId?: QuickiesGroupChatId
}

const i18nKey = "quickies:components.groups.group_about"

const GroupAbout: FC<GroupAboutProps> = ({
  title,
  description,
  startsAt,
  hostedBy,
  lat,
  lng,
  onShowOnMap,
  groupChatId,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { setMapCenterFromUserInteraction } = useQuickiesMapApi()
  const { navigateHome, navigateToGroupChat } = useQuickiesNavigation()
  const defaultGroupName = useQuickiesGroupDefaultName()

  const handleShowOnMapOnClick = async () => {
    invariant(lat, "lat is required")
    invariant(lng, "lng is required")
    invariant(typeof onShowOnMap === "function", "onShowOnMap must be function")

    onShowOnMap()

    await navigateHome()

    setMapCenterFromUserInteraction({
      lat,
      lng,
    })
  }

  const handleStartChatOnClick = (groupChatId: QuickiesGroupChatId) =>
    navigateToGroupChat(groupChatId)

  const hasShowOnMap =
    typeof lat === "number" &&
    typeof lng === "number" &&
    typeof onShowOnMap === "function"

  return (
    <Wrapper>
      <HeadingWithChat>
        <Headline>
          <HeadlineText>{title || defaultGroupName}</HeadlineText>

          <StartsAtText
            role={hasShowOnMap ? "button" : "presentation"}
            onClick={hasShowOnMap ? handleShowOnMapOnClick : undefined}
          >
            <GroupStartsAt startsAt={startsAt} />

            {hasShowOnMap && (
              <FaCrosshairs size={24} color={theme.color.text.primary} />
            )}
          </StartsAtText>
        </Headline>

        {groupChatId !== undefined && (
          <HeadlineChatButton
            primary={true}
            onClick={() => handleStartChatOnClick(groupChatId)}
          >
            <ButtonLabelWithIcon>
              {t(`${i18nKey}.chat_button_label`)}
              <ChatBubbleLeftRightIcon height={20} />
            </ButtonLabelWithIcon>
          </HeadlineChatButton>
        )}
      </HeadingWithChat>

      {description ? (
        <Description>
          <RichTextBlock text={description} />
        </Description>
      ) : null}

      {hostedBy !== undefined ? <GroupHostedBy {...hostedBy} /> : null}
    </Wrapper>
  )
}

export default GroupAbout

const Wrapper = styled.div``

const HeadingWithChat = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.color.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing.regular};
`

const Headline = styled.div`
  flex: 1;
`

const HeadlineText = styled.div`
  ${({ theme }) => theme.font.bold.title2};
  // override
  font-size: 24px;
  margin-bottom: 4px;
`

const StartsAtText = styled.div`
  ${({ theme }) => theme.font.bold.headline};
  // override
  font-size: 17px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`

const HeadlineChatButton = styled(TextButton)`
  flex-shrink: 0;
  padding-left: 24px;
  padding-right: 24px;
`

const ButtonLabelWithIcon = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
`

const Description = styled.div`
  ${({ theme }) => theme.font.regular.body};
  color: ${({ theme }) => theme.color.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing.more};
  user-select: text;

  & > * {
    user-select: text;
  }
`
