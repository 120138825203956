import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useReducer,
} from "react"

type UploadNudesReminderState = {
  isReminderShown: boolean
}

type UploadNudesReminderApi = {
  showReminder: () => void
  hideReminder: () => void
}

const UploadNudesReminderStateContext = createContext<UploadNudesReminderState>(
  {
    isReminderShown: false,
  }
)
const UploadNudesReminderApiContext = createContext<UploadNudesReminderApi>(
  {} as UploadNudesReminderApi
)

type Actions = { type: "showReminder" } | { type: "hideReminder" }

const reducer = (
  state: UploadNudesReminderState,
  action: Actions
): UploadNudesReminderState => {
  switch (action.type) {
    case "showReminder":
      return {
        ...state,
        isReminderShown: true,
      }

    case "hideReminder":
      return {
        ...state,
        isReminderShown: false,
      }
  }
}

export const UploadNudesReminderContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducer, {} as UploadNudesReminderState)

  const api = useMemo(() => {
    const showReminder = () => {
      dispatch({ type: "showReminder" })
    }

    const hideReminder = () => {
      dispatch({ type: "hideReminder" })
    }

    return {
      showReminder,
      hideReminder,
    }
  }, [])

  return (
    <UploadNudesReminderApiContext.Provider value={api}>
      <UploadNudesReminderStateContext.Provider value={state}>
        {children}
      </UploadNudesReminderStateContext.Provider>
    </UploadNudesReminderApiContext.Provider>
  )
}

export const useUploadNudesReminderApi = () =>
  useContext(UploadNudesReminderApiContext)
export const useUploadNudesReminderState = () =>
  useContext(UploadNudesReminderStateContext)
