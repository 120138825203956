import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from "react"
import { debug } from "@hornet-web-react/core/utils"
import LocationModel from "@hornet-web-react/core/models/locations"
import { GpsCoords } from "@hornet-web-react/core/types/session"
import EventEmitter from "eventemitter3"

type QuickiesMapUserLocation = LocationModel

// TODO: refactor this with dumb strings like DeviceLocation
// for easier effects deps
export type QuickiesMapConfig = {
  userLocation: QuickiesMapUserLocation | null
  mapCenter: GpsCoords
  mapZoom: number
  hasUserMovedMap: boolean
}

type QuickiesMapApi = {
  setMapUserLocation: (location: QuickiesMapUserLocation) => void
  setMapCenterAndZoom: (mapCenter: GpsCoords, mapZoom: number) => void // driven by user interaction
  setMapCenterFromUserInteraction: (mapCenter: GpsCoords) => void
}

type QuickiesMapState = {
  mapConfig: QuickiesMapConfig
}

export const QuickiesMapContextEventEmitter = new EventEmitter()

export const INITIAL_MAP_ZOOM = 14

const initialQuickiesMapState: QuickiesMapState = {
  mapConfig: {
    userLocation: null,
    mapCenter: {
      lat: 0,
      lng: 0,
    },
    mapZoom: INITIAL_MAP_ZOOM,
    hasUserMovedMap: false,
  },
}

const QuickiesMapStateContext = createContext<QuickiesMapState>(
  initialQuickiesMapState
)
const QuickiesMapApiContext = createContext<QuickiesMapApi>(
  {} as QuickiesMapApi
)

type Actions =
  | {
      type: "setMapCenterFromUserInteraction"
      mapCenter: GpsCoords
    }
  | {
      type: "setMapUserLocation"
      userLocation: QuickiesMapUserLocation
    }
  | {
      type: "setMapCenterAndZoom"
      mapCenter: GpsCoords
      mapZoom: number
    }

const reducer = (
  state: QuickiesMapState,
  action: Actions
): QuickiesMapState => {
  debug(`Quickies Map Context reducer action: ${action.type}`)

  switch (action.type) {
    case "setMapCenterFromUserInteraction":
      return {
        ...state,
        mapConfig: {
          ...state.mapConfig,
          mapCenter: action.mapCenter,
          hasUserMovedMap: true,
        },
      }

    case "setMapUserLocation":
      // sets both location and map center
      return {
        ...state,
        mapConfig: {
          ...state.mapConfig,
          userLocation: action.userLocation,
          mapCenter: {
            lat: action.userLocation.lat,
            lng: action.userLocation.lng,
          },
        },
      }

    case "setMapCenterAndZoom":
      return {
        ...state,
        mapConfig: {
          ...state.mapConfig,
          mapCenter: action.mapCenter,
          mapZoom: action.mapZoom,
          hasUserMovedMap: true,
        },
      }
  }
}

type QuickiesMapProviderProps = {
  children: ReactNode
}

export const QuickiesMapProvider = ({ children }: QuickiesMapProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialQuickiesMapState)

  const quickiesMapApi: QuickiesMapApi = useMemo(() => {
    const setMapCenterFromUserInteraction = (mapCenter: GpsCoords) => {
      dispatch({ type: "setMapCenterFromUserInteraction", mapCenter })

      // delay this event to allow the map to set up its listeners
      setTimeout(() => {
        QuickiesMapContextEventEmitter.emit("changeMapCenter", mapCenter)
      }, 200)
    }

    const setMapUserLocation = (userLocation: QuickiesMapUserLocation) => {
      dispatch({ type: "setMapUserLocation", userLocation })
    }

    const setMapCenterAndZoom = (mapCenter: GpsCoords, mapZoom: number) => {
      dispatch({ type: "setMapCenterAndZoom", mapCenter, mapZoom })
    }

    return {
      setMapCenterFromUserInteraction,
      setMapUserLocation,
      setMapCenterAndZoom,
    }
  }, [])

  return (
    <QuickiesMapApiContext.Provider value={quickiesMapApi}>
      <QuickiesMapStateContext.Provider value={state}>
        {children}
      </QuickiesMapStateContext.Provider>
    </QuickiesMapApiContext.Provider>
  )
}

export const useQuickiesMapApi = () => useContext(QuickiesMapApiContext)
export const useQuickiesMapState = () => useContext(QuickiesMapStateContext)
