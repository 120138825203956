import { FC, MouseEvent } from "react"
import VerifiedDickIcon from "@hornet-web-react/core/components/Icons/VerifiedDickIcon"
import NiceModal from "@ebay/nice-modal-react"
import { useLazyLoaded } from "@hornet-web-react/core/hooks/use-lazy-loaded"

type VerifiedDickBadgeProps = {
  verifiedDickPhotoUrl?: string | null
  size?: number
  isClickable?: boolean
}

const VerifiedDickBadge: FC<VerifiedDickBadgeProps> = ({
  verifiedDickPhotoUrl,
  size,
  isClickable = true,
}) => {
  const { get: getThisDickIsVerifiedModal } = useLazyLoaded(
    () => import("../Modals/ThisDickIsVerifiedModal")
  )

  const hasOnClick = isClickable && !!verifiedDickPhotoUrl

  const handleOnClick = hasOnClick
    ? (event: MouseEvent) => {
        event.stopPropagation()
        event.preventDefault()

        void getThisDickIsVerifiedModal((modal) =>
          NiceModal.show(modal, { verifiedDickPhotoUrl })
        )
      }
    : () => {
        // noop
      }

  return (
    <VerifiedDickIcon
      width={size ?? 24}
      height={size ?? 24}
      style={{
        cursor: hasOnClick ? "pointer" : "default",
        display: "inline-block",
      }}
      role={"button"}
      onClick={handleOnClick}
    />
  )
}

export default VerifiedDickBadge
