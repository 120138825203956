import { useCallback, useMemo, useState } from "react"
import Router from "next/router"
import { useSessionDevice } from "@hornet-web-react/core/contexts/session"
import { InboxType } from "@hornet-web-react/chat/models/messages-conversation.model"
import appConfig from "../app-config"
import {
  InviteCode,
  QuickiesGroupChatId,
  QuickiesGroupId,
} from "@hornet-web-react/core/types"
import { useEffectOnce } from "react-use"

const hasTrafficFailureCheckQueryParam = appConfig.environment !== "production"

export function useQuickiesNavigation() {
  const { localeRoute } = useSessionDevice()
  const [searchParams, setSearchParams] = useState("")

  // set search params
  useEffectOnce(() => {
    // little hack: remove the `f=` query param from the URL (if present)
    // so we don't keep adding more and more IPQ failure reason to URL
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.delete("f")
    setSearchParams(
      searchParams.toString() ? `?${searchParams.toString()}` : ""
    )
  })

  const routes = useMemo(() => {
    return {
      routeForLanding: `${localeRoute ? localeRoute : "/"}${searchParams}`,
      routeForHome: `${localeRoute}/map${searchParams}`,
      routeForOnboarding: `${localeRoute}/onboarding${searchParams}`,
      routeForAcceptInvite: `${localeRoute}/accept-invite${searchParams}`,
      routeForInviteOnly: `${localeRoute}/invite-only${searchParams}`,
      routeForTrafficFailure: `${localeRoute}/uh-oh${searchParams}`,
      routeForUnavailable: `${localeRoute}/unavailable${searchParams}`,
      routeForAccount: `${localeRoute}/account${searchParams}`,
      routeForInviteExpired: `${localeRoute}/invites/expired${searchParams}`,
      routeForInviteRequired: `${localeRoute}/invites/missing${searchParams}`,
      nextRouterPathnameForAcceptInvite: `/accept-invite`,
      nextRouterPathnameForAcceptInviteWithInviteCode: `/invites/accept/[accept_invite_code]`,
      routeForGroupsIndex: `${localeRoute}/groups${searchParams}`,
    }
  }, [localeRoute, searchParams])

  return {
    ...routes,

    getRouteForAcceptInviteWithInviteCode: useCallback(
      (inviteCode: InviteCode) =>
        `${localeRoute}/invites/accept/${inviteCode}${searchParams}`,
      [localeRoute, searchParams]
    ),

    getRouteForGroupDetail: useCallback(
      (groupId: QuickiesGroupId) =>
        `${localeRoute}/groups/${groupId}${searchParams}`,
      [localeRoute, searchParams]
    ),

    navigateToLanding: useCallback(
      () => Router.push(routes.routeForLanding),
      [routes.routeForLanding]
    ),

    navigateHome: useCallback(
      () => Router.push(routes.routeForHome),
      [routes.routeForHome]
    ),

    navigateToOnboarding: useCallback(
      () => Router.push(routes.routeForOnboarding),
      [routes.routeForOnboarding]
    ),

    navigateToTrafficFailure: useCallback(
      (failedCheck: string) =>
        Router.push(
          `${routes.routeForTrafficFailure}${
            hasTrafficFailureCheckQueryParam ? `?f=${failedCheck}` : ""
          }`
        ),
      [routes.routeForTrafficFailure]
    ),

    getRouteToTrafficFailure: useCallback(
      (failedCheck: string) =>
        `${routes.routeForTrafficFailure}${
          hasTrafficFailureCheckQueryParam ? `?f=${failedCheck}` : ""
        }`,
      [routes.routeForTrafficFailure]
    ),

    navigateToUnavailable: useCallback(
      () => Router.push(routes.routeForUnavailable),
      [routes.routeForUnavailable]
    ),

    navigateToAccount: useCallback(
      () => Router.push(routes.routeForAccount),
      [routes.routeForAccount]
    ),

    navigateToInbox: useCallback(
      (inboxType: InboxType) =>
        Router.push(`${localeRoute}/inbox/${inboxType}`),
      [localeRoute]
    ),

    navigateToConversation: useCallback(
      (inboxType: InboxType, conversationId: string) =>
        Router.push(`${localeRoute}/inbox/${inboxType}/${conversationId}`),
      [localeRoute]
    ),

    navigateToAcceptInviteWithInviteCode: useCallback(
      (inviteCode: InviteCode) =>
        Router.push(
          `${localeRoute}/invites/accept/${inviteCode}${searchParams}`
        ),
      [localeRoute, searchParams]
    ),

    navigateToInviteExpired: useCallback(
      () => Router.push(routes.routeForInviteExpired),
      [routes.routeForInviteExpired]
    ),

    navigateToInviteRequired: useCallback(
      () => Router.push(routes.routeForInviteRequired),
      [routes.routeForInviteRequired]
    ),

    navigateToGroupsIndex: useCallback(
      () => Router.push(routes.routeForGroupsIndex),
      [routes.routeForGroupsIndex]
    ),

    navigateToGroupDetail: useCallback(
      (groupId: QuickiesGroupId) =>
        Router.push(`${localeRoute}/groups/${groupId}${searchParams}`),
      [localeRoute, searchParams]
    ),

    navigateToGroupChat: useCallback(
      (groupChatId: QuickiesGroupChatId) =>
        Router.push(`${localeRoute}/groups/chat/${groupChatId}${searchParams}`),
      [localeRoute, searchParams]
    ),

    navigateToGroupsInbox: useCallback(
      () => Router.push(`${localeRoute}/inbox/groups${searchParams}`),
      [localeRoute, searchParams]
    ),
  }
}
