import { GpsCoords } from "@hornet-web-react/core/types/session"
import { getCookie } from "typescript-cookie"

export const IS_CUSTOM_GPS_COOKIE_NAME = "hrnt_is_cstm_gps"

export function isValidCustomGps(lat: string | null, lng: string | null) {
  if (lat && lng) {
    const latNum = parseFloat(lat)
    const lngNum = parseFloat(lng)

    return latNum >= -90 && latNum <= 90 && lngNum >= -180 && lngNum <= 180
  }

  return false
}

export function isCoordsCustomGps(coords: GpsCoords) {
  const customGps = getCustomGps()

  if (!customGps) {
    return false
  }

  return coords.lat === customGps.lat && coords.lng === customGps.lng
}

export function serializeCustomGps(lat: string | null, lng: string | null) {
  return `${lat ?? 0},${lng ?? 0}`
}

export function getCustomGps(): GpsCoords | null {
  if (typeof window === "undefined" || typeof document === "undefined") {
    return null
  }

  const [lat, lng] = getCookie(IS_CUSTOM_GPS_COOKIE_NAME)?.split(",") ?? []

  if (!lat || !lng) {
    return null
  }

  return {
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  }
}
