import { FC, ReactNode } from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  background: #fff;
  padding: 0 8px;
  border-radius: 20px;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  min-width: 55px;
`

const IconContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`

const TextContent = styled.span`
  margin-left: 6px;
  font-size: 18px;
  font-weight: 500;
`

type ProfilePhotoPillProps = {
  onClick: () => void
  icon: ReactNode
  text: ReactNode
  className?: string
}

const ProfilePhotoPill: FC<ProfilePhotoPillProps> = ({
  onClick,
  icon,
  text,
  className,
}) => {
  return (
    <Wrapper className={className} role={"button"} onClick={onClick}>
      <IconContent>{icon}</IconContent>
      {text && <TextContent>{text}</TextContent>}
    </Wrapper>
  )
}

export default ProfilePhotoPill
