import { FC } from "react"
import styled, { useTheme } from "styled-components"
import useTranslation from "next-translate/useTranslation"
import {
  BoltIcon,
  BuildingStorefrontIcon,
  FireIcon,
  HomeIcon,
  MagnifyingGlassCircleIcon,
  PlayCircleIcon,
  ShieldCheckIcon,
  SparklesIcon,
  UserIcon,
} from "@heroicons/react/24/solid"
import QuickiesMemberModel from "../../models/quickies-member.model"
import ProfileContentCard from "@hornet-web-react/profile/components/ProfileContentCard"
import { FaRibbon } from "react-icons/fa"
import { useLocalizedDateFormat } from "@hornet-web-react/core/hooks/use-localized-date-format"

type QuickiesProfileAboutProps = {
  member: QuickiesMemberModel
}

const i18nKey = "quickies:components.quickies_profile.quickies_profile_about"

const QuickiesProfileAbout: FC<QuickiesProfileAboutProps> = ({ member }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { dateFormat } = useLocalizedDateFormat()

  const memberHasAnyAboutText =
    member.titleSexuality ||
    member.titleSceneLocations ||
    member.titleSceneIntoPublics ||
    member.titleSceneLookingFors ||
    member.titleSceneFetishes ||
    member.titleSceneKinks ||
    member.titleSceneIntos ||
    member.titleSceneInteractions ||
    member.hasHealthProtectionTitle ||
    member.hasHealthHivStatusTitle

  if (!memberHasAnyAboutText) {
    return null
  }

  return (
    <ProfileContentCard>
      <ProfileContentCard.Header>
        {t(`${i18nKey}.heading`)}
      </ProfileContentCard.Header>
      <ProfileContentCard.Content>
        <InfoList>
          {member.titleSexuality && (
            <InfoListItem>
              <InfoListItemIcon>
                <UserIcon width={20} color={theme.color.text.primary} />
              </InfoListItemIcon>
              <span>{member.titleSexuality}</span>
            </InfoListItem>
          )}
          {member.titleSceneLocations && (
            <InfoListItem>
              <InfoListItemIcon>
                <HomeIcon width={20} color={theme.color.text.primary} />
              </InfoListItemIcon>
              <span>{member.titleSceneLocations}</span>
            </InfoListItem>
          )}
          {member.titleSceneIntoPublics && (
            <InfoListItem>
              <InfoListItemIcon>
                <BuildingStorefrontIcon
                  width={20}
                  color={theme.color.text.primary}
                />
              </InfoListItemIcon>
              <span>{member.titleSceneIntoPublics}</span>
            </InfoListItem>
          )}
          {member.titleSceneLookingFors && (
            <InfoListItem>
              <InfoListItemIcon>
                <MagnifyingGlassCircleIcon
                  width={20}
                  color={theme.color.text.primary}
                />
              </InfoListItemIcon>
              <span>{member.titleSceneLookingFors}</span>
            </InfoListItem>
          )}
          {member.titleSceneFetishes && (
            <InfoListItem>
              <InfoListItemIcon>
                <SparklesIcon width={20} color={theme.color.text.primary} />
              </InfoListItemIcon>
              <span>{member.titleSceneFetishes}</span>
            </InfoListItem>
          )}
          {member.titleSceneKinks && (
            <InfoListItem>
              <InfoListItemIcon>
                <FireIcon width={20} color={theme.color.text.primary} />
              </InfoListItemIcon>
              <span>{member.titleSceneKinks}</span>
            </InfoListItem>
          )}
          {member.titleSceneIntos && (
            <InfoListItem>
              <InfoListItemIcon>
                <BoltIcon width={20} color={theme.color.text.primary} />
              </InfoListItemIcon>
              <span>{member.titleSceneIntos}</span>
            </InfoListItem>
          )}
          {member.titleSceneInteractions && (
            <InfoListItem>
              <InfoListItemIcon>
                <PlayCircleIcon width={20} color={theme.color.text.primary} />
              </InfoListItemIcon>
              <span>{member.titleSceneInteractions}</span>
            </InfoListItem>
          )}
          {member.hasHealthProtectionTitle && (
            <InfoListItem>
              <InfoListItemIcon>
                <ShieldCheckIcon width={20} color={theme.color.text.primary} />
              </InfoListItemIcon>
              <span>{member.getHealthProtectionTitle(dateFormat)}</span>
            </InfoListItem>
          )}
          {member.hasHealthHivStatusTitle && (
            <InfoListItem>
              <InfoListItemIcon>
                <FaRibbon width={20} color={theme.color.text.primary} />
              </InfoListItemIcon>
              <span>{member.getHealthHivStatusTitle(dateFormat)}</span>
            </InfoListItem>
          )}
        </InfoList>
      </ProfileContentCard.Content>
    </ProfileContentCard>
  )
}

export default QuickiesProfileAbout

const InfoList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  color: #1b1b1b;
`

const InfoListItem = styled.li`
  ${({ theme }) => theme.font.regular.body};
  color: ${({ theme }) => theme.color.text.primary};

  margin-bottom: 14px;
  display: flex;
  align-items: flex-start;

  &:last-child {
    margin-bottom: 0;
  }
`

const InfoListItemIcon = styled.span`
  margin: 1px 12px 0 0;
  color: #1b1b1b;
  width: 20px;
  display: flex;
  justify-content: center;
`
