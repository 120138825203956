import React, { FC, useMemo } from "react"
import {
  QuickiesGroupMembershipStatus,
  QuickiesGroupModel,
} from "../../models/quickies-group.model"
import invariant from "tiny-invariant"
import styled from "styled-components"
import TextButton from "@hornet-web-react/core/components/UI/TextButton"
import { useQuickiesGroupActions } from "../../hooks/use-quickies-group-actions"
import useTranslation from "next-translate/useTranslation"

const i18nKey = "quickies:components.groups.group_action_button"

type GroupActionButtonProps = {
  quickiesGroup: QuickiesGroupModel
}

const GroupActionButton: FC<GroupActionButtonProps> = ({ quickiesGroup }) => {
  const { t } = useTranslation()

  const {
    handleAskToJoin,
    handleCancelRequest,
    handleAcceptInvite,
    handleDeclineInvite,
    handleLeaveGroup,
    isAskingToJoin,
    isCancellingRequest,
    isAcceptingInvite,
    isDecliningInvite,
    isLeavingGroup,
    isAskingToJoinDone,
    isCancellingRequestDone,
    isAcceptingInviteDone,
    isDecliningInviteDone,
    isLeavingGroupDone,
  } = useQuickiesGroupActions()

  const groupAction = useMemo(() => {
    switch (quickiesGroup.currentUserStatus) {
      case QuickiesGroupMembershipStatus.enum.hosting:
        return null

      case QuickiesGroupMembershipStatus.enum.not_invited:
        return (
          <FirstGroupActionButton
            primary={true}
            isLoading={isAskingToJoin}
            isDone={isAskingToJoinDone}
            onClick={() => handleAskToJoin(quickiesGroup.groupId)}
          >
            {t(`${i18nKey}.actions.ask_to_join`)}
          </FirstGroupActionButton>
        )

      case QuickiesGroupMembershipStatus.enum.invited:
        return (
          <>
            <FirstGroupActionButton
              primary={true}
              disabled={isDecliningInvite || isDecliningInviteDone}
              isLoading={isAcceptingInvite}
              isDone={isAcceptingInviteDone}
              onClick={() => {
                invariant(
                  quickiesGroup.currentUserMembershipId,
                  "currentUserMembershipId is undefined"
                )

                return handleAcceptInvite(
                  quickiesGroup.groupId,
                  quickiesGroup.currentUserMembershipId
                )
              }}
            >
              {t(`${i18nKey}.actions.accept_invite`)}
            </FirstGroupActionButton>

            <SecondGroupActionButton
              disabled={isAcceptingInvite || isAcceptingInviteDone}
              isLoading={isDecliningInvite}
              isDone={isDecliningInviteDone}
              onClick={() => {
                invariant(
                  quickiesGroup.currentUserMembershipId,
                  "currentUserMembershipId is undefined"
                )

                return handleDeclineInvite(
                  quickiesGroup.groupId,
                  quickiesGroup.currentUserMembershipId
                )
              }}
            >
              {t(`${i18nKey}.actions.decline_invite`)}
            </SecondGroupActionButton>
          </>
        )

      case QuickiesGroupMembershipStatus.enum.requested:
        return (
          <FirstGroupActionButton
            isLoading={isCancellingRequest}
            isDone={isCancellingRequestDone}
            onClick={() => {
              invariant(
                quickiesGroup.currentUserMembershipId,
                "currentUserMembershipId is undefined"
              )

              return handleCancelRequest(
                quickiesGroup.groupId,
                quickiesGroup.currentUserMembershipId
              )
            }}
          >
            {t(`${i18nKey}.actions.cancel_request`)}
          </FirstGroupActionButton>
        )

      case QuickiesGroupMembershipStatus.enum.accepted:
        return (
          <FirstGroupActionButton
            isLoading={isLeavingGroup}
            isDone={isLeavingGroupDone}
            onClick={() => {
              invariant(
                quickiesGroup.currentUserMembershipId,
                "currentUserMembershipId is undefined"
              )

              return handleLeaveGroup(
                quickiesGroup.groupId,
                quickiesGroup.currentUserMembershipId
              )
            }}
          >
            {t(`${i18nKey}.actions.leave_group`)}
          </FirstGroupActionButton>
        )

      default:
        return null
    }
  }, [
    quickiesGroup.currentUserStatus,
    quickiesGroup.currentUserMembershipId,
    isAskingToJoin,
    isAskingToJoinDone,
    t,
    isDecliningInvite,
    isDecliningInviteDone,
    isAcceptingInvite,
    isAcceptingInviteDone,
    isCancellingRequest,
    isCancellingRequestDone,
    isLeavingGroup,
    isLeavingGroupDone,
    handleAskToJoin,
    quickiesGroup.groupId,
    handleAcceptInvite,
    handleDeclineInvite,
    handleCancelRequest,
    handleLeaveGroup,
  ])

  if (!groupAction) {
    return null
  }

  return <GroupActionButtonWrapper>{groupAction}</GroupActionButtonWrapper>
}

export default GroupActionButton

const FirstGroupActionButton = styled(TextButton)`
  width: 100%;
`

const SecondGroupActionButton = styled(TextButton)`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.regular};
`

const GroupActionButtonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.double};
`
