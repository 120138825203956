import React, { FC } from "react"
import {
  QuickiesGroupMembershipStatus,
  QuickiesGroupModel,
} from "../../models/quickies-group.model"
import GroupProfilePhoto from "./GroupProfilePhoto"
import GroupAbout from "./GroupAbout"
import QuickiesAnalyticsEvent from "../../models/quickies-analytics-event"
import GroupActionButton from "./GroupActionButton"
import GroupSectionDivider from "./GroupSectionDivider"
import GroupRequests from "./GroupRequests"
import GroupAttendees from "./GroupAttendees"
import GroupPrivateSection from "./GroupPrivateSection"
import { useQuickiesProfilePreview } from "../../hooks/use-quickies-profile-preview"
import { useEventTrackerService } from "../../hooks/use-event-tracker-service"

type GroupDetailsProps = {
  quickiesGroup: QuickiesGroupModel
  onShowOnMap?: () => void
}

const GroupDetails: FC<GroupDetailsProps> = ({
  quickiesGroup,
  onShowOnMap,
}) => {
  const { openProfilePreview } = useQuickiesProfilePreview()
  const { reportEvent } = useEventTrackerService()

  return (
    <>
      <GroupProfilePhoto photoUrl={quickiesGroup.thumbnailUrl} />

      <GroupAbout
        title={quickiesGroup.title}
        description={quickiesGroup.description}
        startsAt={quickiesGroup.startsAt}
        hostedBy={
          quickiesGroup.hostedBy !== undefined
            ? {
                ...quickiesGroup.hostedBy,
                onClick: () =>
                  quickiesGroup.hostedBy
                    ? openProfilePreview(quickiesGroup.hostedBy.id)
                    : {
                        // noop
                      },
              }
            : undefined
        }
        lat={quickiesGroup.lat}
        lng={quickiesGroup.lng}
        onShowOnMap={() => {
          void reportEvent(
            QuickiesAnalyticsEvent.groupPreviewModalTapOnShowOnMap(
              quickiesGroup.groupId
            )
          )

          if (typeof onShowOnMap === "function") {
            onShowOnMap()
          }
        }}
        groupChatId={quickiesGroup.groupChatId}
      />

      {quickiesGroup.currentUserStatus ===
      QuickiesGroupMembershipStatus.enum.invited ? (
        <GroupActionButton quickiesGroup={quickiesGroup} />
      ) : null}

      <GroupSectionDivider />

      {quickiesGroup.requests.length > 0 ? (
        <>
          <GroupRequests
            requests={quickiesGroup.requests}
            groupId={quickiesGroup.groupId}
          />

          <GroupSectionDivider />
        </>
      ) : null}

      <GroupAttendees
        attendees={quickiesGroup.attendees}
        groupId={quickiesGroup.groupId}
        isCurrentUserHosting={quickiesGroup.isCurrentUserHosting}
      />

      <GroupPrivateSection
        address={quickiesGroup.address}
        additionalInfo={quickiesGroup.additionalInfo}
      />

      {quickiesGroup.currentUserStatus !==
      QuickiesGroupMembershipStatus.enum.invited ? (
        <GroupActionButton quickiesGroup={quickiesGroup} />
      ) : null}
    </>
  )
}

export default GroupDetails
