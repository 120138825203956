// this doesn't handle the full functionality, it's currently a helper hook
// to provide reportable closeAction/rightAction for the modal
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import EventTrackerService from "@hornet-web-react/core/services/EventTrackerService"
import { TYPES as CORE_TYPES } from "@hornet-web-react/core/services/types"
import type { NavigationAction } from "@hornet-web-react/core/types"
import TrackEvent from "@hornet-web-react/core/models/track-event"
import { useCallback } from "react"

export const useModal = () => {
  const eventTrackerService = useCoreService<EventTrackerService>(
    CORE_TYPES.EventTrackerService
  )

  return {
    createAction: useCallback(
      (action: NavigationAction, modalName: string) => {
        return {
          ...action,
          onClick: () => {
            if (typeof action.onClick === "function") {
              action.onClick()
            }
            void eventTrackerService.report(TrackEvent.modalClosed(modalName))
          },
        }
      },
      [eventTrackerService]
    ),
  }
}
