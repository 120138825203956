import { FC, ReactNode } from "react"
import PropTypes from "prop-types"
import styled, { keyframes } from "styled-components"
import Spinner from "../UI/Spinner"

interface InteractiveComponentWrapperProps {
  children: ReactNode
  isInteractive: boolean
  className?: string
  role?: string
  onClick?: () => void
}

interface WrappersProps {
  isInteractive: boolean
}

const HiddenWrapper = styled.div<WrappersProps>`
  opacity: ${(props) => (props.isInteractive ? "1" : "0.5")};
  pointer-events: ${(props) => (props.isInteractive ? "auto" : "none")};
  position: relative;
  transition: opacity 250ms ease-in-out;
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const SpinnerWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  opacity: 0;
  animation: ${fadeIn} 1s;
  animation-fill-mode: forwards;
  animation-delay: 2s;
`

const InteractiveComponentWrapper: FC<InteractiveComponentWrapperProps> = (
  props
) => {
  const { className, isInteractive, children, ...attrs } = props

  return (
    <HiddenWrapper
      className={className}
      isInteractive={isInteractive}
      {...attrs}
    >
      {isInteractive ? (
        children
      ) : (
        <SpinnerWrapper>
          <Spinner size={"auto"} />
        </SpinnerWrapper>
      )}
    </HiddenWrapper>
  )
}

InteractiveComponentWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  isInteractive: PropTypes.bool.isRequired,
}

export default InteractiveComponentWrapper
