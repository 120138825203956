import React, { FC } from "react"
import QuickiesMemberAvatar from "../QuickiesMemberAvatar"
import styled from "styled-components"
import { MemberAvatarProps } from "@hornet-web-react/core/components/UI/MemberAvatar"
import useTranslation from "next-translate/useTranslation"

type GroupHostedByProps = {
  displayName: string
  thumbnailUrl: MemberAvatarProps["avatarUrl"]
  onClick?: MemberAvatarProps["onClick"]
}

const i18nKey = "quickies:components.groups.group_hosted_by"

const GroupHostedBy: FC<GroupHostedByProps> = ({
  displayName,
  onClick,
  thumbnailUrl,
}) => {
  const { t } = useTranslation()

  return (
    <HostedBy>
      <QuickiesMemberAvatar
        size={44}
        avatarUrl={thumbnailUrl}
        onClick={onClick}
      />

      <HostedByLabel>
        {t(`${i18nKey}.hosted_by_label`)}

        <HostedByDisplayName>{displayName}</HostedByDisplayName>
      </HostedByLabel>
    </HostedBy>
  )
}

export default GroupHostedBy

const HostedBy = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
`

const HostedByLabel = styled.div`
  ${({ theme }) => theme.font.regular.footnote};
  color: ${({ theme }) => theme.color.text.secondary};
`

const HostedByDisplayName = styled.div`
  ${({ theme }) => theme.font.regular.subheadline};
`
