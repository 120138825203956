import { FC, PropsWithChildren } from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  margin: 0 0 ${({ theme }) => theme.spacing.regular};
  max-width: 100%;

  @media screen and ${({ theme }) => theme.breakpoints.device.gtXs} {
    max-width: ${({ theme }) => theme.width.containerMaxWidthMobile};
  }
`

const ContentCardContent = styled.div`
  ${({ theme }) => theme.font.regular.body};
  color: ${({ theme }) => theme.color.text.primary};
  background: ${({ theme }) => theme.color.bg.light01};
  border: 1px solid ${({ theme }) => theme.color.border.default};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  position: relative;
  padding: ${({ theme }) => theme.spacing.regular};

  p:not([class]) {
    margin-top: 0;
  }
`

export const ContentCardHeader = styled.div`
  ${({ theme }) => theme.font.regular.footnote};
  color: ${({ theme }) => theme.color.text.tertiary};
  margin-top: ${({ theme }) => theme.spacing.regular};
  margin-bottom: 4px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.spacing.half};

  @media screen and ${({ theme }) => theme.breakpoints.device.gtXs} {
    padding: 0 0 0 2px;
  }
`

export const ContentCardSeparator = styled.hr`
  padding: 0;
  margin: 0 ${({ theme }) => theme.spacing.regular};
  height: 0;
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.border.default};
  overflow: hidden;
`

type ContentCardProps = PropsWithChildren & {
  className?: string
}

const ContentCard: FC<ContentCardProps> = ({ children, className }) => {
  return <Wrapper className={className}>{children}</Wrapper>
}

export default Object.assign(ContentCard, {
  Content: ContentCardContent,
  Header: ContentCardHeader,
  Separator: ContentCardSeparator,
})

export const ModalContentCard = Object.assign(
  styled(ContentCard)`
    &:last-child {
      margin-bottom: 0;
    }

    @media screen and ${({ theme }) => theme.breakpoints.device.gtXs} {
      max-width: 100%;

      &:last-child > div {
        border-bottom: 0;
      }
    }
  `,
  {
    Content: styled(ContentCardContent)`
      padding: 0;
      border-radius: 0;
      border-right: 0;
      border-left: 0;
    `,
    Header: styled(ContentCardHeader)`
      margin-top: 0;

      @media screen and ${({ theme }) => theme.breakpoints.device.gtXs} {
        padding: 0 ${({ theme }) => theme.spacing.half};
      }
    `,
    Separator: ContentCardSeparator,
  }
)

export const ModalContentCardV2 = Object.assign(
  styled(ContentCard)`
    &:last-child {
      margin-bottom: 0;
    }

    @media screen and ${({ theme }) => theme.breakpoints.device.gtXs} {
      max-width: 100%;

      &:last-child > div {
        border-bottom: 0;
      }
    }
  `,
  {
    Content: styled(ContentCardContent)`
      padding: 0;
      border-radius: 0;
      background: transparent;
      border: 0;
    `,
    Header: styled(ContentCardHeader)`
      margin-top: 0;

      @media screen and ${({ theme }) => theme.breakpoints.device.gtXs} {
        padding: 0 ${({ theme }) => theme.spacing.half};
      }
    `,
    Separator: ContentCardSeparator,
  }
)
