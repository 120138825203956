import * as React from "react"
import { SVGProps } from "react"

const VerifiedDickIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ?? 75}
    height={props.height ?? 75}
    viewBox="0 0 75 75"
    fill="none"
    {...props}
  >
    <path
      fill={props.color ?? "#F75932"}
      d="M73 37c0 5.155-6.2 8.02-8.151 12.453-1.874 4.257.567 10.12-2.717 13.323-3.24 3.16-9.566 1.666-13.811 3.431C44.068 67.977 41.893 73 37 73s-7.294-5.93-11.547-7.698c-4.245-1.765-10.344.634-13.585-2.526C8.584 59.573 10.346 53.257 8.472 49 6.52 44.566 1 42.155 1 37s5.52-7.312 7.472-11.746c1.874-4.257.112-10.827 3.396-14.03 3.24-3.16 7.981-1.666 12.226-3.432C28.347 6.024 32.107 1 37 1c4.893 0 6.841 5.024 11.094 6.792 4.246 1.766 10.797.272 14.038 3.432 3.284 3.203 1.296 9.066 3.17 13.323C67.254 28.981 73 31.845 73 37Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M20.04 38.473c-3.501 2.189-2.636 4.843-.659 8.675 1.551 3.008 2.273 3.469 2.697 3.74.31.198.461.295.662 1.212.475 2.17 3.902 4.832 10.109 4.61 6.207-.221 11.244-1.893 11.04-4.123-.02-.21-.032-.447-.045-.7-.123-2.43-.331-6.542-7.886-6.947-7.263-.39 2.891-7.825 6.919-10.774.599-.439 1.063-.778 1.313-.98.788-.637 1.523-1.736 2.236-2.8 1.034-1.545 2.02-3.019 3.054-2.909 1.747.187 4.68-3.3 2.619-8.82-1.778-4.758-9.61.474-12.394 2.334-.445.297-.76.508-.902.578-1.026.51-.458 1.806-.127 2.018.385.247-2.25 2.35-5.899 5.26a816.08 816.08 0 0 0-2.879 2.305c-3.96 3.185-6.471 5.205-9.857 7.321Zm30.032-20.379c-2.996-.135-6.206 1.975-8.698 3.614-.375.246-.733.482-1.072.698.37.393 3.602 1.468 7.075.707 3.473-.76 3.031-3.481 2.695-5.019ZM41.7 49.085c-1.608-2.15-6.832-2.2-9.243-1.957-1.96 1.33-.121 1.293 1.866 1.253.523-.01 1.057-.02 1.534-.007 1.832.054 4.654 2.204 5.835 3.272.673.042 1.616-.41.008-2.56Z"
      clipRule="evenodd"
    />
  </svg>
)

export default VerifiedDickIcon
