import styled, { css } from "styled-components"
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { ExclamationCircleIcon } from "@heroicons/react/24/solid"

export const Wrapper = styled.div`
  position: relative;
  text-align: left;
  margin-bottom: 20px;
`

export const Background = styled.div<{ hasError: boolean }>`
  background: ${({ theme }) => theme.color.bg.input};
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacing.regular};

  border: 1px solid
    ${({ theme, hasError }) =>
      hasError ? theme.color.text.error : theme.color.border.input};
`

const isRequiredLabelStyle = css`
  &:after {
    content: " *";
    color: red;
  }
`

export const FormInputStyles = css`
  ${({ theme }) => theme.font.regular.body}
  display: block;
  width: 100%;
  color: ${({ theme }) => theme.color.text.primary};
  font-family: ${({ theme }) => theme.font.family};
  appearance: none;
  border: 0;
  background: transparent;
  flex: 1;
  padding: 2px 1px;
  resize: none;
  min-height: 27px;

  &::-webkit-date-and-time-value {
    text-align: left !important;
  }

  &:focus {
    outline: 1px none ${({ theme }) => theme.color.text.tertiary};
  }
`

export const Label = styled.label<{ isRequired?: boolean }>`
  ${({ theme }) => theme.font.bold.title3};
  //styleName: heading 02;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  //styleName: heading 02;

  display: block;
  color: ${({ theme }) => theme.color.text.secondary};
  margin-bottom: 10px;

  overflow: hidden;
  text-overflow: ellipsis;

  ${(props) => props.isRequired && isRequiredLabelStyle}
`

export const InputContainer = styled.div``

export const FormInput = styled.input<{ hasError: boolean }>`
  ${FormInputStyles};
`

export const AppendText = styled.div`
  padding-bottom: 2px;
`

export const FormError = styled.div`
  ${({ theme }) => theme.font.regular.caption1};
  line-height: 18px; // override to line up with the info icon
  color: ${({ theme }) => theme.color.text.error};
  display: flex;
  align-items: flex-start;
  padding: 0 ${({ theme }) => theme.spacing.less};
  margin-top: ${({ theme }) => theme.spacing.less};
  margin-bottom: ${({ theme }) => theme.spacing.less};

  @media screen and ${({ theme }) => theme.breakpoints.device.gtXs} {
    padding: 0 ${({ theme }) => theme.spacing.regular};
  }
`

export const FormInfo = styled(FormError)`
  color: ${({ theme }) => theme.color.text.secondary};
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 6px;
`

export const InfoIcon = styled(InformationCircleIcon)`
  width: 18px;
  height: 18px;
  margin-right: 3px;
  flex-shrink: 0;
`

export const ErrorIcon = styled(ExclamationCircleIcon)`
  width: 18px;
  height: 18px;
  margin-right: 3px;
`
