import { FC, useEffect } from "react"
import styled, { useTheme } from "styled-components"
import {
  BlackButton,
  smallSizeStyle,
} from "@hornet-web-react/core/components/UI/TextButton"
import useTranslation from "next-translate/useTranslation"
import NiceModal from "@ebay/nice-modal-react"
import { ModalContentCard } from "@hornet-web-react/core/components/UI/ContentCard"
import {
  MapPinIcon,
  StarIcon as StarIconSolid,
} from "@heroicons/react/24/solid"
import { useQuickiesMapApi } from "../../contexts/quickies-map-context"
import { useQuickiesProfilePreview } from "../../hooks/use-quickies-profile-preview"
import invariant from "tiny-invariant"
import { FaCrosshairs } from "react-icons/fa"
import { useQuickiesNavigation } from "../../hooks/use-quickies-navigation"
import { differenceInMinutes } from "date-fns"
import { useSocialInteractions } from "@hornet-web-react/core/hooks/use-social-interactions"
import { useEffectOnce } from "usehooks-ts"
import appConfig from "../../app-config"
import IconButton from "@hornet-web-react/core/components/UI/IconButton"
import {
  HornetProfileId,
  QuickiesProfileId,
} from "@hornet-web-react/core/types"
import { StarIcon } from "@heroicons/react/24/outline"
import QuickiesMemberModel from "../../models/quickies-member.model"
import {
  setQuickiesMemberIsFavouriteLocally,
  useQuickiesMemberDefaultDisplayName,
} from "../../hooks/use-quickies-member"
import VerifiedDickBadge from "./VerifiedDickBadge"
import { useQuickiesFeatureFlags } from "../../hooks/use-quickies-feature-flags"
import { useLazyLoaded } from "@hornet-web-react/core/hooks/use-lazy-loaded"

type QuickiesProfileIntroProps = {
  member: QuickiesMemberModel
  isCurrentUser?: boolean
}

const i18nKey = "quickies:components.quickies_profile.quickies_profile_intro"

const QuickiesProfileIntro: FC<QuickiesProfileIntroProps> = ({
  member,
  isCurrentUser,
}) => {
  const { t } = useTranslation()
  const { setMapCenterFromUserInteraction } = useQuickiesMapApi()
  const { closeProfilePreview } = useQuickiesProfilePreview()
  const { navigateHome } = useQuickiesNavigation()
  const { eventEmitter, toggleFollow } = useSocialInteractions(
    appConfig.googleRecaptchaV3.siteKey
  )
  const { isDickVerificationEnabled } = useQuickiesFeatureFlags()
  const theme = useTheme()
  const quickiesMemberDefaultDisplayName = useQuickiesMemberDefaultDisplayName()
  const { get: getEditQuickiesProfileModal } = useLazyLoaded(
    () => import("../Modals/EditQuickiesProfileModal")
  )

  const profileLat = member.lat ?? undefined
  const profileLng = member.lng ?? undefined

  useEffectOnce(() => {
    const handleBlockOrReportEvent = async () => {
      // navigate away from the profile
      void closeProfilePreview()
    }

    eventEmitter.on("memberBlocked", handleBlockOrReportEvent)
    eventEmitter.on("memberReported", handleBlockOrReportEvent)

    return () => {
      eventEmitter.off("memberBlocked", handleBlockOrReportEvent)
      eventEmitter.off("memberReported", handleBlockOrReportEvent)
    }
  })

  useEffect(() => {
    const handleFollowEvent = () =>
      setQuickiesMemberIsFavouriteLocally(member, true)
    const handleUnfollowEvent = () =>
      setQuickiesMemberIsFavouriteLocally(member, false)

    eventEmitter.on("memberFollowed", handleFollowEvent)
    eventEmitter.on("memberUnfollowed", handleUnfollowEvent)

    return () => {
      eventEmitter.off("memberFollowed", handleFollowEvent)
      eventEmitter.off("memberUnfollowed", handleUnfollowEvent)
    }
  }, [eventEmitter, member, member.profileId])

  const toggleMemberFollow = async (
    profileId: HornetProfileId,
    isFavorite: boolean
  ) => {
    await toggleFollow(profileId, isFavorite)
  }

  const handleEditProfileClick = (profileId: QuickiesProfileId) =>
    getEditQuickiesProfileModal((modal) =>
      NiceModal.show(modal, {
        quickiesProfileId: profileId,
      })
    )

  const handleShowOnMapOnClick = async () => {
    invariant(profileLat, "profileLat is required")
    invariant(profileLng, "profileLng is required")

    void closeProfilePreview()

    await navigateHome()

    setMapCenterFromUserInteraction({
      lat: profileLat,
      lng: profileLng,
    })
  }

  // registered users are on the map for 12 hours
  // anon users are on the map for 2.5 hours
  const lastOnlineInterval =
    typeof member.onlineStatus?.lastOnline !== "undefined" &&
    member.onlineStatus.lastOnline
      ? differenceInMinutes(new Date(), member.onlineStatus.lastOnline)
      : null

  const isProbablyOnTheMap =
    !!member.onlineStatus?.isOnline ||
    (lastOnlineInterval !== null &&
      ((member.isAnonymous && lastOnlineInterval <= 150) ||
        (!member.isAnonymous && lastOnlineInterval <= 720)))

  const hasShowOnMapButton =
    !!profileLat &&
    !!profileLng &&
    !!member.profileId &&
    !isCurrentUser &&
    isProbablyOnTheMap

  const hasFavoriteButton = !isCurrentUser && !!member.hornetProfileId

  return (
    <ModalContentCard>
      <ModalContentCard.Content>
        <InnerPadding>
          <Heading>
            <ProfileStatsAsName>
              {member.displayName || quickiesMemberDefaultDisplayName}{" "}
              {isDickVerificationEnabled && member.hasVerifiedDick && (
                <VerifiedDickBadge
                  verifiedDickPhotoUrl={member.firstVerifiedDickUrl}
                />
              )}
            </ProfileStatsAsName>

            <HeadingActions>
              {!!isCurrentUser && member.profileId ? (
                <EditProfileButton
                  onClick={() => handleEditProfileClick(member.profileId)}
                >
                  {t(`${i18nKey}.buttons.edit`)}
                </EditProfileButton>
              ) : null}

              {hasFavoriteButton && (
                <>
                  {member.isFavourite ? (
                    <FavIconButton
                      type={"button"}
                      onClick={() =>
                        toggleMemberFollow(
                          member.hornetProfileId,
                          member.isFavourite
                        )
                      }
                    >
                      <StarIconSolid
                        width={24}
                        color={theme.color.tint.default}
                      />
                    </FavIconButton>
                  ) : (
                    <NotFavIconButton
                      type={"button"}
                      onClick={() =>
                        toggleMemberFollow(
                          member.hornetProfileId,
                          member.isFavourite
                        )
                      }
                    >
                      <StarIcon width={24} color={theme.color.text.primary} />
                    </NotFavIconButton>
                  )}
                </>
              )}
            </HeadingActions>
          </Heading>

          {member.displayDistance && (
            <Distance
              hasShowOnMapButton={hasShowOnMapButton}
              role={hasShowOnMapButton ? "button" : "presentation"}
              onClick={
                hasShowOnMapButton
                  ? handleShowOnMapOnClick
                  : () => {
                      // no-op
                    }
              }
            >
              <MapPinIcon width={20} height={20} />
              {member.displayDistance}

              {hasShowOnMapButton && (
                <FaCrosshairs width={20} color={theme.color.tint.default} />
              )}
            </Distance>
          )}

          {member.headline && <Subheading>{member.headline}</Subheading>}
        </InnerPadding>
      </ModalContentCard.Content>
    </ModalContentCard>
  )
}

export default QuickiesProfileIntro

const Heading = styled.h1`
  ${({ theme }) => theme.font.bold.title3};
  color: ${({ theme }) => theme.color.text.primary};
  padding: 0;
  margin: 0 0 ${({ theme }) => theme.spacing.half} 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
`

const ProfileStatsAsName = styled.div``

const HeadingActions = styled.div`
  display: flex;
`

const EditProfileButton = styled(BlackButton)`
  ${smallSizeStyle};
`

const Distance = styled.h2<{ hasShowOnMapButton: boolean }>`
  ${({ theme }) => theme.font.regular.subheadline};
  margin: 0 0 ${({ theme }) => theme.spacing.less} 0;
  color: ${({ theme }) => theme.color.text.secondary};
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  cursor: ${({ hasShowOnMapButton }) =>
    hasShowOnMapButton ? "pointer" : "default"};
`

const Subheading = styled.h2`
  ${({ theme }) => theme.font.bold.subheadline};
  margin: 0;
  color: ${({ theme }) => theme.color.text.secondary};
  overflow: hidden;
  user-select: text;
`

const FavIconButton = styled(IconButton)`
  background: white;
`

const NotFavIconButton = styled(IconButton)`
  background: black;
`

const InnerPadding = styled.div`
  padding: ${({ theme }) => theme.spacing.regular};
`
