import appConfig from "../app-config"
import useImmutableSWR from "swr"
import { ApiServiceEndpoint } from "@hornet-web-react/core/services/API/ApiServiceEndpoint"
import ApiService from "@hornet-web-react/core/services/API/ApiService"
import { getCookie } from "typescript-cookie"
import { isCountryCodeAllowed } from "../utils/is-country-code-allowed"
import {
  useSessionApi,
  useSessionDevice,
} from "@hornet-web-react/core/contexts/session"
import { startTransition, useEffect, useMemo, useState } from "react"
import { Country } from "@hornet-web-react/core/types/session"
import { UseAppStartEmitter } from "@hornet-web-react/core/hooks/use-app-start"

export const useQuickiesIpLocation = () => {
  const {
    device: { storeCountry },
  } = useSessionApi()
  const { country } = useSessionDevice()

  const [shouldExecuteIpCountryDetection, setShouldExecuteIpCountryDetection] =
    useState(false)

  const { data } = useImmutableSWR<{ country_code: string }>(
    shouldExecuteIpCountryDetection
      ? ApiService.getEndpoint(ApiServiceEndpoint.PublicIpCountryGet, [
          appConfig.publicApiIpCountryKey,
        ])
      : null,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
    }
  )

  useEffect(() => {
    if (!data?.country_code) {
      return
    }

    const parsedCountry = Country.safeParse(data.country_code.toLowerCase())
    if (!parsedCountry.success || parsedCountry.data === "unknown") {
      return
    }

    startTransition(() => {
      storeCountry(parsedCountry.data)
    })
  }, [data, storeCountry])

  useEffect(() => {
    const changeAppStartDoneChangedHandler = (appStartDone: boolean) => {
      if (
        appStartDone &&
        (!country || country === Country.unwrap().enum.unknown)
      ) {
        setShouldExecuteIpCountryDetection(true)
      }
    }

    UseAppStartEmitter.on(
      "appStartDoneChanged",
      changeAppStartDoneChangedHandler
    )

    return () => {
      UseAppStartEmitter.off(
        "appStartDoneChanged",
        changeAppStartDoneChangedHandler
      )
    }
  }, [country])

  const isAllowed = useMemo(() => {
    // wait for the country code to be fetched, otherwise it would look like
    // it's always unavailable
    return (
      data?.country_code === undefined ||
      isCountryCodeAllowed(data?.country_code || "", (cookieName: string) => {
        if (typeof window !== "undefined" && typeof document !== "undefined") {
          return getCookie(cookieName)
        }

        return
      })
    )
  }, [data?.country_code])

  return {
    isAllowed,
    countryCode: data?.country_code,
  }
}
