import styled from "styled-components"

export default styled.div`
  overflow: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 50;
`
