import { FC } from "react"
import {
  BareMessageModel,
  MessageModel,
  MessageType,
} from "../../models/messages-conversation.model"
import { isStrictNever } from "@hornet-web-react/core/utils"
import useTranslation from "next-translate/useTranslation"
import { sanitize } from "@hornet-web-react/core/utils/sanitize"
import { Translate } from "next-translate"

type ConversationMessageBodyPreviewProps = {
  message: BareMessageModel | MessageModel
}

const i18nKey = "chat:components.inbox.conversation_message_body_preview"

export const getConversationMessageBodyPreview = (
  message: BareMessageModel | MessageModel,
  t: Translate
) => {
  const { type } = message.payload

  switch (type) {
    case MessageType.enum.chat: {
      return sanitize(message.payload.data).substring(0, 50)
    }
    case MessageType.enum.fav_forward:
    case MessageType.enum.heart:
    case MessageType.enum.location:
    case MessageType.enum.share_photo:
    case MessageType.enum.voice_note:
    case MessageType.enum.sticker:
      return t(`${i18nKey}.${message.messageType}`)

    case MessageType.enum.permission_response:
      if (message.isSent) {
        return t(
          `${i18nKey}.${
            message.isPermissionGranted
              ? "you_have_granted"
              : "you_have_revoked"
          }`
        )
      }

      return t(
        `${i18nKey}.${
          message.isPermissionGranted
            ? "they_have_granted"
            : "they_have_revoked"
        }`
      )

    case MessageType.enum.permission_request:
      return t(`${i18nKey}.access_requested`)

    case MessageType.enum.award:
      return t(
        `${i18nKey}.${message.isSent ? "you_sent_award" : "they_sent_award"}`
      )

    case MessageType.enum.web_view:
      return message.webViewTitle || ""

    case MessageType.enum.deleted_message:
      return t(
        `${i18nKey}.${
          message.isSent
            ? "you_deleted_this_message"
            : "this_message_has_been_deleted"
        }`
      )

    default:
      isStrictNever(type)
      return t(`${i18nKey}.new_message`)
  }
}

const ConversationMessageBodyPreview: FC<
  ConversationMessageBodyPreviewProps
> = ({ message }) => {
  const { t } = useTranslation()

  return <>{getConversationMessageBodyPreview(message, t)}</>
}

export default ConversationMessageBodyPreview
