import { z } from "zod"
import {
  GroupFormData,
  GroupFormDataAdditionalInfo,
  GroupFormDataAddress,
} from "./group-form.model"
import {
  QuickiesGroupChatId,
  QuickiesGroupId,
  QuickiesGroupMembershipId,
  QuickiesProfileId,
} from "@hornet-web-react/core/types"
import { printZodValidationError } from "@hornet-web-react/core/utils/print-zod-validation-error"
import { identity } from "ramda"
import appConfig from "../app-config"

export const GROUP_DEFAULT_PROFILE_PHOTO_URL = `${appConfig.appUrl}/assets/images/photo_placeholders/group1.png`

export const QuickiesMicroProfileApiPayload = z.object({
  id: QuickiesProfileId,
  display_name: z.string(),
  thumbnail_url: z.string(),
})
export type QuickiesMicroProfileApiPayload = z.infer<
  typeof QuickiesMicroProfileApiPayload
>

export const QuickiesGroupPhotoApiPayload = z.object({
  hangout_photo: z.object({
    id: z.coerce.string(),
    photo: z.object({
      // we can discuss needed versions
      square_retina: z.object({
        url: z.string(),
      }),
      desktop: z.object({
        url: z.string(),
      }),
    }),
  }),
})
export type QuickiesGroupPhotoApiPayload = z.infer<
  typeof QuickiesGroupPhotoApiPayload
>

export const QuickiesGroupMembershipRole = z.enum(["attendee", "host"])
export type QuickiesGroupMembershipRole = z.infer<
  typeof QuickiesGroupMembershipRole
>

export const QuickiesGroupMembershipStatus = z.enum([
  "invited",
  "requested",
  "accepted",
  "not_invited",
  "hosting",
])
export type QuickiesGroupMembershipStatus = z.infer<
  typeof QuickiesGroupMembershipStatus
>

export const QuickiesGroupMembershipApiPayload = z.object({
  hangout_membership: z.object({
    id: QuickiesGroupMembershipId,
    role: QuickiesGroupMembershipRole, // [attendee, host]
    status: QuickiesGroupMembershipStatus.exclude(["not_invited", "hosting"]), // [invited, requested, accepted]
    quickies_profile: QuickiesMicroProfileApiPayload,
    created_at: z.coerce.date(),
  }),
})
export type QuickiesGroupMembershipApiPayload = z.infer<
  typeof QuickiesGroupMembershipApiPayload
>

export const QuickiesGroupApiPayload = z.object({
  hangout: GroupFormData.extend({
    address: GroupFormDataAddress.optional(),
    additional_info: GroupFormDataAdditionalInfo.optional(),
    id: QuickiesGroupId,
    members_count: z.number(), // number of accepted members
    requests_count: z.number().optional(), // optional, number of outstanding requested members, only visible to host
    hangout_photo: QuickiesGroupPhotoApiPayload.shape.hangout_photo
      .nullable()
      .optional(), // optional
    host: QuickiesMicroProfileApiPayload.optional(), // optional, not present on the map
    attendees: z.array(QuickiesGroupMembershipApiPayload).optional(), // optional, invited + accepted visible to host, accepted visible to attendee
    requests: z.array(QuickiesGroupMembershipApiPayload).optional(), // optional, only visible to host
    hangout_membership:
      QuickiesGroupMembershipApiPayload.shape.hangout_membership.optional(), // optional, visible when hangout membership with status [invited, requested, accepted],
    group_chat: z
      .object({
        id: QuickiesGroupChatId,
      })
      .optional(),
  }),
})
export type QuickiesGroupApiPayload = z.infer<typeof QuickiesGroupApiPayload>

export const QuickiesGroupsDashboardApiPayload = z.object({
  hosting_hangouts: z.array(QuickiesGroupApiPayload),
  upcoming_hangouts: z.array(QuickiesGroupApiPayload),
  invited_hangouts: z.array(QuickiesGroupApiPayload),
})
export type QuickiesGroupsDashboardApiPayload = z.infer<
  typeof QuickiesGroupsDashboardApiPayload
>

export function createQuickiesGroupPhotoModel(
  payload: QuickiesGroupPhotoApiPayload
) {
  const model = QuickiesGroupPhotoApiPayload.safeParse(payload)
  if (model.success) {
    return new QuickiesGroupPhotoModel(payload)
  }

  payload && printZodValidationError(model.error)
}

export class QuickiesGroupPhotoModel {
  private readonly photo: QuickiesGroupPhotoApiPayload["hangout_photo"]

  constructor(payload: QuickiesGroupPhotoApiPayload) {
    this.photo = payload.hangout_photo
  }

  get id() {
    return this.photo.id
  }

  get squareUrl() {
    return this.photo.photo.square_retina.url
  }

  serialize(): QuickiesGroupPhotoApiPayload {
    return {
      hangout_photo: {
        ...this.photo,
      },
    }
  }
}

export const createQuickiesGroupModel = (payload?: QuickiesGroupApiPayload) => {
  const model = QuickiesGroupApiPayload.safeParse(payload)
  if (model.success) {
    return new QuickiesGroupModel(model.data)
  }

  payload && printZodValidationError(model.error)
}

export type QuickiesGroupMembership = {
  groupMembershipId: QuickiesGroupMembershipId
  quickiesProfileId: QuickiesProfileId
  displayName: string
  thumbnailUrl: string | undefined
  status: QuickiesGroupMembershipStatus
}

const transformQuickiesGroupMembershipApiPayloadToQuickiesGroupMembership = (
  payload: QuickiesGroupMembershipApiPayload
): QuickiesGroupMembership => ({
  groupMembershipId: payload.hangout_membership.id,
  quickiesProfileId: payload.hangout_membership.quickies_profile.id,
  displayName: payload.hangout_membership.quickies_profile.display_name,
  thumbnailUrl: payload.hangout_membership.quickies_profile.thumbnail_url,
  status: payload.hangout_membership.status,
})

export class QuickiesGroupModel {
  protected readonly group: QuickiesGroupApiPayload["hangout"]
  readonly photos: QuickiesGroupPhotoModel[]

  constructor(payload: QuickiesGroupApiPayload) {
    this.group = payload.hangout

    this.photos = this.group.hangout_photo
      ? ([
          createQuickiesGroupPhotoModel({
            hangout_photo: this.group.hangout_photo,
          }),
        ].filter(identity) as QuickiesGroupPhotoModel[])
      : []
  }

  get thumbnailUrl(): string {
    if (this.photos.length) {
      return this.photos[0].squareUrl
    }

    return GROUP_DEFAULT_PROFILE_PHOTO_URL
  }

  get hasPhoto() {
    return this.photos.length > 0
  }

  get groupId(): QuickiesGroupId {
    return this.group.id
  }

  get title() {
    return this.group.title
  }

  get description() {
    return this.group.description
  }

  get membersCount() {
    return this.group.members_count
  }

  get requestsCount() {
    return this.group.requests_count
  }

  get requests(): QuickiesGroupMembership[] {
    return this.group.requests !== undefined
      ? this.group.requests.map(
          transformQuickiesGroupMembershipApiPayloadToQuickiesGroupMembership
        )
      : []
  }

  get startsAt(): Date {
    return this.group.starts_at
  }

  get duration(): number {
    return this.group.duration
  }

  get hostedBy() {
    if (!this.group.host) {
      return undefined
    }

    return {
      id: this.group.host.id,
      displayName: this.group.host.display_name,
      thumbnailUrl: this.group.host.thumbnail_url,
    }
  }

  get attendees(): QuickiesGroupMembership[] {
    return this.group.attendees !== undefined
      ? this.group.attendees.map(
          transformQuickiesGroupMembershipApiPayloadToQuickiesGroupMembership
        )
      : []
  }

  get address() {
    return this.group.address ?? null
  }

  get additionalInfo() {
    return this.group.additional_info ?? null
  }

  get lat() {
    return this.group.lat
  }

  get lng() {
    return this.group.lng
  }

  get currentUserStatus(): QuickiesGroupMembershipStatus {
    if (this.group.hangout_membership === undefined) {
      return QuickiesGroupMembershipStatus.enum.not_invited
    }

    if (
      this.group.hangout_membership.role ===
      QuickiesGroupMembershipRole.enum.host
    ) {
      return QuickiesGroupMembershipStatus.enum.hosting
    }

    return this.group.hangout_membership.status
  }

  get isCurrentUserHosting() {
    return this.currentUserStatus === QuickiesGroupMembershipStatus.enum.hosting
  }

  get currentUserMembershipId(): QuickiesGroupMembershipId | undefined {
    return this.group.hangout_membership?.id
  }

  get hasGroupChat() {
    return this.group.group_chat?.id !== undefined
  }

  get groupChatId(): QuickiesGroupChatId | undefined {
    return this.group.group_chat?.id
  }

  get isHappeningInHours(): number {
    const now = new Date()
    const diff = this.startsAt.getTime() - now.getTime()

    return Math.floor(diff / (1000 * 60 * 60))
  }

  serialize(): QuickiesGroupApiPayload {
    const hangoutPhoto =
      this.photos.length > 0
        ? this.photos.map((p) => p.serialize())[0]
        : undefined

    return {
      hangout: {
        ...this.group,
        hangout_photo: hangoutPhoto ? hangoutPhoto.hangout_photo : undefined,
      },
    }
  }
}
