import { ZodError } from "zod"

export const printZodValidationError = (error: ZodError) => {
  if (
    typeof process !== "undefined" &&
    process.env["NEXT_PUBLIC_APP_ENV"] === "production"
  ) {
    // in production, the stack could get cut off
    console.log("ZodError", JSON.stringify(error.issues))
    return
  }

  // in development, alpha, beta, pretty print the error
  console.log("ZodError", JSON.stringify(error.issues, null, 2))
}
