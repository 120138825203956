import styled, { css } from "styled-components"
import device from "../../styles/breakpoints"
import Container from "../Layout/Container"
import { PropsWithChildren } from "react"
import Link from "next/link"
import useTranslation from "next-translate/useTranslation"
import { useSessionUser } from "@hornet-web-react/core/contexts/session"
import { useQuickiesNavigation } from "../../hooks/use-quickies-navigation"
import { ChevronLeftIcon } from "@heroicons/react/24/solid"
import QuickiesXLogo from "../Layout/QuickiesXLogo"
import OverlaySpinner from "@hornet-web-react/core/components/UI/OverlaySpinner"

type InfoPageProps = PropsWithChildren & {
  className?: string
  isEmbedded?: boolean
  isLoading?: boolean
}

const InfoPage = (props: InfoPageProps) => {
  const content = (
    <>
      <OverlaySpinner isVisible={!!props.isLoading} />
      {props.children}
    </>
  )

  if (props.isEmbedded) {
    return (
      <CenterContent>
        <ContentBox className={props.className} isEmbedded={true}>
          {content}
        </ContentBox>
      </CenterContent>
    )
  }

  return (
    <CenterContent>
      <Container>
        <ContentBox className={props.className}>{content}</ContentBox>
      </Container>
    </CenterContent>
  )
}

const paragraphStyles = css`
  margin-bottom: ${({ theme }) => theme.spacing.regular};
`

const ContentBox = styled.div<{ isEmbedded?: boolean }>`
  width: 100%;
  color: ${({ theme }) => theme.color.text.primary};
  padding: 0;
  overflow: ${({ isEmbedded }) => (isEmbedded ? "auto" : "hidden")};
  min-height: 200px;
  border-radius: ${({ theme, isEmbedded }) =>
    isEmbedded ? 0 : theme.borderRadius.default};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // @media screen and ${device.gtSm} {
  //   padding: ${({ theme }) => theme.spacing.double};
  // }

  p {
    ${paragraphStyles};
  }

  a:not([class]) {
    color: ${({ theme }) => theme.color.tint.default};
  }
`

const Header = styled.h1`
  ${({ theme }) => theme.font.bold.title2};
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing.regular};

  // @media screen and ${device.gtSm} {
  //   ${({ theme }) => theme.font.bold.largeTitle};
  // }
`

const SubHeader = styled.h3`
  ${({ theme }) => theme.font.regular.body};
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing.regular};

  // @media screen and ${device.gtSm} {
  //   ${({ theme }) => theme.font.regular.title3};
  // }
`

const CenterContent = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  background: ${({ theme }) => theme.color.bg.light01};
  padding: ${({ theme }) => theme.spacing.regular};
  text-align: center;
  position: relative;
`

const Paragraph = styled.p`
  ${paragraphStyles};
`

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.double};
`

const CtaWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.more};
`

const BackToHomePageLinkWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.regular};
`

const BackToHomePageActualLink = styled(Link)`
  ${({ theme }) => theme.font.regular.body};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.color.tint.default};
  text-decoration: none;
  cursor: pointer;
  gap: 5px;
`

const BackToHomePageLink = () => {
  const { t } = useTranslation()
  const { isAuthenticated } = useSessionUser()
  const { routeForLanding, routeForHome } = useQuickiesNavigation()

  return (
    <BackToHomePageLinkWrapper>
      <BackToHomePageActualLink
        href={isAuthenticated ? routeForHome : routeForLanding}
      >
        <ChevronLeftIcon height={20} />
        {t(`quickies:components.pages.any_page.button_go_to_homepage`)}
      </BackToHomePageActualLink>
    </BackToHomePageLinkWrapper>
  )
}

export const InfoPageLogo = function ({ size = 101 }: { size?: number }) {
  return (
    <LogoWrapper>
      <QuickiesXLogo size={size} />
    </LogoWrapper>
  )
}

const InfoPageWithComponents = Object.assign(InfoPage, {
  Logo: InfoPageLogo,
  Header,
  SubHeader,
  Paragraph,
  CtaWrapper,
  BackToHomePageLink,
})

export default InfoPageWithComponents
