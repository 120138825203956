let locales = [
  "de",
  "de-DE",
  "uk",
  "uk-UA",
  "en",
  "id",
  "zh-TW",
  "zh",
  "th",
  "tr",
  "fr",
  "pt",
]

if (
  ["alpha", "beta", "development"].includes(process.env.NEXT_PUBLIC_APP_ENV)
) {
  locales = ["cs-CZ", ...locales]
}

module.exports = locales
