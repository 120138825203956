import { useCallback } from "react"
import ApiService from "@hornet-web-react/core/services/API/ApiService"
import { CORE_TYPES } from "@hornet-web-react/core/services/types"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import LoggerService from "@hornet-web-react/core/services/LoggerService"
import { useFlashMessage } from "@hornet-web-react/core/hooks/use-flash-message"
import {
  ProfilePhotoUploadModel,
  serializePhotoToFormData,
} from "../models/profile-photo-upload.model"
import {
  isRight,
  makeLeft,
  makeRight,
  unwrapEither,
} from "@hornet-web-react/core/utils"
import TeapotMessageError from "@hornet-web-react/core/services/API/Errors/TeapotMessageError"
import useTranslation from "next-translate/useTranslation"
import MemberPhotoModel, {
  MemberPhotoCreateApiPayload,
} from "@hornet-web-react/core/models/member-photo.model"
import {
  ApiServiceEndpoint,
  ApiServiceEndpointType,
} from "@hornet-web-react/core/services/API/ApiServiceEndpoint"
import { useApi } from "@hornet-web-react/core/hooks/use-api"
import CustomApiError from "@hornet-web-react/core/services/API/Errors/CustomApiError"

export type ProfilePhotoMember<T> = {
  setAsProfilePhoto: (photo: MemberPhotoModel) => T
  addPhoto: (photo: MemberPhotoModel) => T
  deletePhoto: (photo: MemberPhotoModel) => T
  togglePhotoStatus: (photo: MemberPhotoModel, slot: number) => T
  featuredPhotos: MemberPhotoModel[]
  privatePhotos: MemberPhotoModel[]
}

type ProfilePhotoActionsMutateMember<T> = (member: T) => Promise<void>

type ProfilePhotoActionsEndpoints = {
  PhotosDelete: ApiServiceEndpointType
  PhotosSlotsPut: ApiServiceEndpointType
  PhotosPut: ApiServiceEndpointType
  PhotosPost: ApiServiceEndpointType
}

export function useProfilePhotoActions<M>(
  mutateMember: ProfilePhotoActionsMutateMember<M>,
  endpoints?: ProfilePhotoActionsEndpoints
) {
  const { makeApiRequest } = useApi()
  const loggerService = useCoreService<LoggerService>(CORE_TYPES.LoggerService)
  const { showOops } = useFlashMessage()
  const { t } = useTranslation()

  // default values are hornet values
  const apiEndpoints =
    typeof endpoints === "undefined"
      ? {
          PhotosDelete: ApiServiceEndpoint.PhotosDelete,
          PhotosSlotsPut: ApiServiceEndpoint.PhotosSlotsPut,
          PhotosPut: ApiServiceEndpoint.PhotosPut,
          PhotosPost: ApiServiceEndpoint.PhotosPost,
        }
      : endpoints

  const commonErrorHandler = useCallback(
    (step: string) => (error: unknown) => {
      if (error instanceof Error) {
        loggerService.logExceptionWithSentry(
          error,
          loggerService.createLoggingContext({
            hook: "useProfilePhotoActions",
            step: step,
          })
        )
      }

      void showOops(error)
    },
    [showOops, loggerService]
  )

  const deletePhoto = useCallback(
    async (member: ProfilePhotoMember<M>, photo: MemberPhotoModel) => {
      const apiResult = await makeApiRequest(
        ApiService.getEndpoint(apiEndpoints.PhotosDelete, [photo.id]),
        undefined,
        commonErrorHandler("deletePhoto")
      )

      if (isRight(apiResult)) {
        // we cannot just reload member because API is slow with cache
        // and returns old photos
        await mutateMember(member.deletePhoto(photo))
      }
    },
    [
      apiEndpoints.PhotosDelete,
      commonErrorHandler,
      makeApiRequest,
      mutateMember,
    ]
  )

  const setAsProfilePhoto = useCallback(
    async (member: ProfilePhotoMember<M>, photo: MemberPhotoModel) => {
      const profilePhotoId = photo.id

      const data = {
        photos: [{ id: profilePhotoId, slot: 0 }],
      }

      const reshuffledPhotos = member.featuredPhotos
        .filter((photo) => photo.id !== profilePhotoId)
        .map((photo, index) => {
          return { id: photo.id, slot: index + 1 }
        })

      data.photos.push(...reshuffledPhotos)

      const apiResult = await makeApiRequest(
        ApiService.getEndpoint(apiEndpoints.PhotosSlotsPut, []),
        data,
        commonErrorHandler("setAsProfilePhoto")
      )

      if (isRight(apiResult)) {
        // we cannot just reload member because API is slow with cache
        // and returns old photos
        await mutateMember(member.setAsProfilePhoto(photo))
      }
    },
    [
      apiEndpoints.PhotosSlotsPut,
      commonErrorHandler,
      makeApiRequest,
      mutateMember,
    ]
  )

  const togglePhotoStatus = useCallback(
    async (
      member: ProfilePhotoMember<M>,
      photo: MemberPhotoModel,
      callerName: string
    ) => {
      const getNewLastPhotoSlot = (photos: MemberPhotoModel[]) => {
        // new one
        if (photos.length === 0) {
          return 0
        }

        return (
          photos.reduce((acc, curr) => {
            return Math.max(acc, curr.slot)
          }, 0) + 1
        )
      }

      const data = {
        photo: {
          public: !photo.isPublic,
          slot: getNewLastPhotoSlot(
            photo.isPublic ? member.privatePhotos : member.featuredPhotos
          ),
        },
      }

      const apiResult = await makeApiRequest(
        ApiService.getEndpoint(apiEndpoints.PhotosPut, [photo.id]),
        data,
        commonErrorHandler(callerName)
      )

      if (isRight(apiResult)) {
        // we cannot just reload member because API is slow with cache
        // and returns old photos
        await mutateMember(member.togglePhotoStatus(photo, data.photo.slot))
      }
    },
    [apiEndpoints.PhotosPut, commonErrorHandler, makeApiRequest, mutateMember]
  )

  const makePhotoPrivate = useCallback(
    async (member: ProfilePhotoMember<M>, photo: MemberPhotoModel) => {
      await togglePhotoStatus(member, photo, "makePhotoPrivate")
    },
    [togglePhotoStatus]
  )

  const makePhotoPublic = useCallback(
    async (member: ProfilePhotoMember<M>, photo: MemberPhotoModel) => {
      await togglePhotoStatus(member, photo, "makePhotoPublic")
    },
    [togglePhotoStatus]
  )

  const handlePhotoUpload = useCallback(
    async (
      member: ProfilePhotoMember<M>,
      formData: ProfilePhotoUploadModel,
      isPublic: boolean,
      slot: number,
      callerName: string
    ) => {
      const apiResult = await makeApiRequest<MemberPhotoCreateApiPayload>(
        ApiService.getEndpoint(apiEndpoints.PhotosPost, []),
        serializePhotoToFormData(formData, isPublic, slot),
        (error) => {
          // [418, 460, 461].includes(response.status)
          if (error instanceof TeapotMessageError) {
            return makeLeft({
              title: error.teapotTitle,
              message: error.teapotMessage,
            })
          } else if (error instanceof CustomApiError && error.status === 400) {
            return makeLeft({
              message: t(
                `profile:hooks.use_profile_photo_actions.errors.unsupported_type`
              ),
            })
          } else {
            if (error instanceof Error) {
              loggerService.logExceptionWithSentry(
                error,
                loggerService.createLoggingContext({
                  hook: "useProfilePhotoActions",
                  step: callerName,
                })
              )
            }

            return makeLeft({
              message: t(
                `profile:hooks.use_profile_photo_actions.errors.photo_upload_error`
              ),
            })
          }
        }
      )

      if (isRight(apiResult)) {
        const result = unwrapEither(apiResult)

        // we cannot just reload member because API is slow with cache
        // and returns old photos
        await mutateMember(
          member.addPhoto(
            new MemberPhotoModel({
              photo: {
                id: result.photo.id,
                state: result.photo.state,
                slot: result.photo.slot,
                is_public: result.photo.public,
                url: result.photo.photo.cropped_noretina.url,
                full_url: result.photo.photo.cropped_retina.url,
                full_large_url: result.photo.photo.cropped_retina.url,
                thumbnail_url: result.photo.photo.thumbnail_noretina.url,
                thumbnail_large_url: result.photo.photo.thumbnail_retina.url,
                square_url: result.photo.photo.square_retina.url,
                v6_full_url: result.photo.photo.v6_cropped_retina.url,
              },
            })
          )
        )

        return makeRight(true)
      }

      return apiResult
    },
    [apiEndpoints.PhotosPost, loggerService, makeApiRequest, mutateMember, t]
  )

  const handleProfilePhotoUpload = useCallback(
    async (
      member: ProfilePhotoMember<M>,
      formData: ProfilePhotoUploadModel
    ) => {
      return handlePhotoUpload(
        member,
        formData,
        true,
        0,
        "handleProfilePhotoUpload"
      )
    },
    [handlePhotoUpload]
  )

  const handleFeaturedPhotoUpload = useCallback(
    async (
      member: ProfilePhotoMember<M>,
      slot: number,
      formData: ProfilePhotoUploadModel
    ) => {
      return handlePhotoUpload(
        member,
        formData,
        true,
        slot,
        "handleFeaturedPhotoUpload"
      )
    },
    [handlePhotoUpload]
  )

  const handlePrivatePhotoUpload = useCallback(
    async (
      member: ProfilePhotoMember<M>,
      slot: number,
      formData: ProfilePhotoUploadModel
    ) => {
      return handlePhotoUpload(
        member,
        formData,
        false,
        slot,
        "handlePrivatePhotoUpload"
      )
    },
    [handlePhotoUpload]
  )

  return {
    deletePhoto,
    makePhotoPrivate,
    makePhotoPublic,
    setAsProfilePhoto,
    handleProfilePhotoUpload,
    handleFeaturedPhotoUpload,
    handlePrivatePhotoUpload,
  }
}
