import { FC, ReactNode } from "react"
import HornetHashtag from "./HornetHashtag"

type RichTextBlockProps = {
  text: string
  contentStringRenderer?: (content: string, key: string) => ReactNode
}

const RichTextBlock: FC<RichTextBlockProps> = ({
  text,
  contentStringRenderer,
}) => {
  // text without RTLO
  const textWithoutRtlo = text.replace(/\u202E/g, "")

  // split by hashtags
  const chunks = textWithoutRtlo
    .split(/(#[^\s!@#$%^&*()=+./,[{\]};:'"?><]+)/gi)
    .map((chunk) => {
      if (chunk[0] === "#") {
        return { tag: chunk.slice(1) }
      }

      return { content: chunk }
    })

  return (
    <>
      {chunks.map((chunk, index) => {
        if (chunk.tag) {
          return <HornetHashtag key={index} tag={chunk.tag} />
        }

        if (typeof contentStringRenderer === "function") {
          return contentStringRenderer(chunk.content || "", String(index))
        }

        return outputContentWithNewLines(chunk.content || "", String(index))
      })}
    </>
  )
}

export default RichTextBlock

function outputContentWithNewLines(content: string, key: string) {
  if (content.includes("\n")) {
    return content.split("\n").map((line, index) => {
      return (
        <span key={`${key}_${index}`}>
          {line}
          <br />
        </span>
      )
    })
  }

  return <span key={key}> {content}</span>
}
