import { Component, createRef } from "react"
import styled from "styled-components"

const Label = styled.label`
  left: -10000px;
  position: absolute;
  top: -10000px;
`

type UploadInputProps = {
  onChange: () => void
  accept?: string
}

class UploadInput extends Component<UploadInputProps> {
  inputRef: any

  constructor(props: UploadInputProps) {
    super(props)
    this.inputRef = createRef()
  }

  openDialog() {
    this.inputRef.current.click()
  }

  getFile() {
    const files = this.inputRef.current.files

    if (files.length) {
      return files[0]
    }
  }

  resetValue() {
    this.inputRef.current.value = ""
  }

  override render() {
    return (
      <Label>
        <input
          ref={this.inputRef}
          type="file"
          accept={this.props.accept || "image/png, image/jpeg"}
          onChange={this.props.onChange}
        />
      </Label>
    )
  }
}

export default UploadInput
