import { FC } from "react"
import { FaCrosshairs } from "react-icons/fa"
import styled, { useTheme } from "styled-components"
import useTranslation from "next-translate/useTranslation"
import { InformationCircleIcon } from "@heroicons/react/24/solid"
import GroupPrivateSectionDivider from "./GroupPrivateSectionDivider"

type GroupPrivateSectionProps = {
  address: string | null
  additionalInfo: string | null
}

const i18nKey = "quickies:components.groups.group_private_section"

const GroupPrivateSection: FC<GroupPrivateSectionProps> = ({
  address,
  additionalInfo,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  if (!address && !additionalInfo) {
    return null
  }

  return (
    <Wrapper>
      <GroupPrivateSectionDivider />

      {address ? (
        <Section>
          <Heading>
            <FaCrosshairs width={20} color={theme.color.text.primary} />
            {t(`${i18nKey}.address_heading`)}
          </Heading>
          <Content>{address}</Content>
        </Section>
      ) : null}

      {additionalInfo ? (
        <Section>
          <Heading>
            <InformationCircleIcon
              width={20}
              color={theme.color.text.primary}
            />
            {t(`${i18nKey}.additional_info_heading`)}
          </Heading>
          <Content>{additionalInfo}</Content>
        </Section>
      ) : null}
    </Wrapper>
  )
}

export default GroupPrivateSection

const Wrapper = styled.div``

const Heading = styled.div`
  ${({ theme }) => theme.font.bold.body};
  color: ${({ theme }) => theme.color.text.secondary};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 6px;
`

const Content = styled.div`
  ${({ theme }) => theme.font.regular.body};
  color: ${({ theme }) => theme.color.text.secondary};
  user-select: text;
`

const Section = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.regular};
`
