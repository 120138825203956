import React, { FC, MouseEvent, useEffect, useState } from "react"
import styled, { useTheme } from "styled-components"
import IconButton from "@hornet-web-react/core/components/UI/IconButton"
import { ChatBubbleLeftIcon, CheckCircleIcon } from "@heroicons/react/24/solid"
import { useQuickiesNavigation } from "../../hooks/use-quickies-navigation"
import { InboxType } from "@hornet-web-react/chat/models/inbox-conversations.model"
import TextButton from "@hornet-web-react/core/components/UI/TextButton"
import useTranslation from "next-translate/useTranslation"
import ProfileVerificationError from "@hornet-web-react/core/services/API/Errors/ProfileVerificationError"
import { useQuickiesProfilePreview } from "../../hooks/use-quickies-profile-preview"
import {
  useSessionDevice,
  useSessionUser,
} from "@hornet-web-react/core/contexts/session"
import {
  reloadQuickiesProfile,
  useQuickiesMember,
} from "../../hooks/use-quickies-member"
import ProfilePhoto from "@hornet-web-react/profile/components/ProfilePhoto"
import ImageMissingMemberPhoto from "../../../public/assets/images/missing.png"
import { ProfilePhotoUploadModel } from "@hornet-web-react/profile/models/profile-photo-upload.model"
import QuickiesProfileIntro from "../QuickiesProfile/QuickiesProfileIntro"
import QuickiesProfileAbout from "../QuickiesProfile/QuickiesProfileAbout"
import { useQuickiesProfilePhotoActions } from "../../hooks/use-quickies-profile-photo-actions"
import QuickiesProfilePhotos from "../QuickiesProfile/QuickiesProfilePhotos"
import { useEventTrackerService } from "../../hooks/use-event-tracker-service"
import QuickiesAnalyticsEvent from "../../models/quickies-analytics-event"
import { useQuickiesVanillaMode } from "../../hooks/use-quickies-vanilla-mode"
import {
  HornetProfileId,
  QuickiesProfileId,
} from "@hornet-web-react/core/types"
import { useQuickiesSession } from "../../hooks/use-quickies-session"
import { appUrl } from "../../config"
import { useCopyToClipboard } from "usehooks-ts"
import invariant from "tiny-invariant"
import NiceModal from "@ebay/nice-modal-react"
import Trans from "next-translate/Trans"
import { FaShare } from "react-icons/fa"
import CustomPhotoPolicy from "../EditQuickiesProfile/CustomPhotoPolicy"
import dynamic from "next/dynamic"
import QuickiesProfileEllipsisActions from "../QuickiesProfile/QuickiesProfileEllipsisActions"
import VerifyDickButton from "../QuickiesProfile/VerifyDickButton"
import { ImageSlide } from "yet-another-react-lightbox/core"
import VerifiedDickBadge from "../QuickiesProfile/VerifiedDickBadge"
import ProfilePhotoUploadCta from "@hornet-web-react/profile/components/ProfilePhotoUploadCta"
import { i18nKey as reminderI18nKey } from "../Map/ShouldUploadPhotoReminder"
import getRandomPhotoPlaceholderUrl from "../../utils/get-random-photo-placeholder-url"
import { useQuickiesFeatureFlags } from "../../hooks/use-quickies-feature-flags"
import QuickiesProfileGroups from "../QuickiesProfile/QuickiesProfileGroups"
import {
  shouldCheckForSharingCapability,
  useHasShareCapability,
} from "@hornet-web-react/core/hooks/use-has-share-capability"

type PageProfileProps = {
  hornetProfileId: HornetProfileId
  quickiesProfileId: QuickiesProfileId
}

const i18nKey = `quickies:components.pages.page_profile`

const PageQuickiesProfile: FC<PageProfileProps> = ({
  quickiesProfileId,
  hornetProfileId,
}) => {
  const { t } = useTranslation()
  const { data: member, error } = useQuickiesMember(quickiesProfileId)
  const { reportEvent } = useEventTrackerService()
  // const { sendHeartsting } = useMessenger(hornetProfileId)
  const { navigateToConversation } = useQuickiesNavigation()
  const { closeProfilePreview } = useQuickiesProfilePreview()
  const { currentUser } = useSessionUser()
  const { localeRoute } = useSessionDevice()
  const { handleProfilePhotoUpload } = useQuickiesProfilePhotoActions()
  const { state: isVanillaMode } = useQuickiesVanillaMode()
  const { data: quickiesSession } = useQuickiesSession()
  const { isDickVerificationEnabled, hasGroupsEnabled } =
    useQuickiesFeatureFlags()
  const [, copy] = useCopyToClipboard()
  const hasShareCapability = useHasShareCapability()
  const [hasLinkCopiedToast, setHasLinkCopiedToast] = useState(false)
  const theme = useTheme()
  const photoPlaceholderUrl = getRandomPhotoPlaceholderUrl()

  // for lightbox viewing
  const [galleryIndex, setGalleryIndex] = useState(-1)
  const [hasLightbox, setHasLightbox] = useState(false)

  useEffect(() => {
    if (hasLinkCopiedToast) {
      const timeout = setTimeout(() => {
        setHasLinkCopiedToast(false)
      }, 3000)

      return () => clearTimeout(timeout)
    }
  }, [hasLinkCopiedToast])

  const isVerificationError = error instanceof ProfileVerificationError

  const isMyProfile = hornetProfileId === currentUser?.profileId

  const isEligibleForDickVerification =
    isDickVerificationEnabled &&
    isMyProfile &&
    !!quickiesSession?.isEligibleForDickVerification

  const isPendingDickVerification =
    isDickVerificationEnabled &&
    isMyProfile &&
    !!quickiesSession?.isPendingDickVerification

  const handleGalleryPhotoClick = (index: number) => {
    void reportEvent(QuickiesAnalyticsEvent.guyTapOnPhoto(hornetProfileId))

    setHasLightbox(true)
    setGalleryIndex(index)
  }

  // const handleHeartstingOnClick = async () => {
  //   void closeProfilePreview()
  //   await navigateToConversation(InboxType.enum.all, hornetProfileId)
  //   await sendHeartsting()
  // }

  const handleChatOnClick = async () => {
    void reportEvent(
      QuickiesAnalyticsEvent.guyTapOnStartChat(quickiesProfileId)
    )

    void closeProfilePreview()
    void navigateToConversation(InboxType.enum.all, hornetProfileId)
  }

  const handleShareOnClick = async (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()

    invariant(member, "Member is not defined")
    invariant(member.shareId, "Member share ID is not defined")

    const shareUrl = `${appUrl}${localeRoute}/s/${member.shareId}?utm_source=hornetx&utm_medium=link&utm_campaign=user_profile_share`

    void reportEvent(QuickiesAnalyticsEvent.myProfileTapOnShare())

    try {
      // here we used to check for sharing capability (only), but it turns out there's
      // MacOS built-in sharing on desktop browsers (Safari, Chrome,..) which is
      // quite awkward and doesn't even have "Copy" option
      // so on top, we're going to check for resolution and non-mobile users
      // will always get the "copy to clipboard" functionality
      if (
        shouldCheckForSharingCapability(
          theme.breakpoints.mediaQueryHasVisibleLeftPanel
        ) &&
        hasShareCapability
      ) {
        const title = t(`${i18nKey}.profile_actions.share_action_sheet`)
        const text = shareUrl

        // there used to be actual `url` param, but the problem is, that the URL often
        // does not get shared; it basically depends on the "pasting app" (at least on iOS)
        // so in iMessages, it's correct... on Slack, it only shares the title+text and if you
        // hit Copy, it also often copies without URL... so the workaround is to amend the text
        // with the URL and share it as text only (not URL) - also iOS doesn't even load correct
        // favicon for the case when sharing URL, so it's not like we're missing out on much
        await navigator.share({
          title,
          text,
        })
        return
      }

      const copyResult = await copy(shareUrl)

      if (copyResult) {
        setHasLinkCopiedToast(true)
      } else {
        // show flash message
        void NiceModal.show("FlashMessageModal", {
          text: (
            <Trans
              i18nKey={`${i18nKey}.profile_actions.share_copy_following_link`}
              values={{ link: shareUrl }}
              components={[
                /* eslint-disable-next-line react/jsx-key */
                <LinkHolder href={shareUrl} />,
              ]}
            />
          ),
        })
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      {member ? (
        <>
          {hasLightbox && (
            <Lightbox
              open={galleryIndex >= 0}
              index={galleryIndex}
              close={() => setGalleryIndex(-1)}
              slides={member.featuredPhotos.map((photo) => {
                return {
                  src: photo.squareUrl,
                  alt:
                    isDickVerificationEnabled && photo.isVerifiedDick
                      ? "Verified"
                      : "",
                  width: 640,
                  height: 640,
                }
              })}
              render={{
                slide: (props) => (
                  <>
                    <ImageSlide {...props} />
                    {props.slide.alt === "Verified" && (
                      <LightboxVerifiedDickIconWrapper
                        className={"yarl__slide_image"}
                      >
                        <LightboxVerifiedDickIcon>
                          <VerifiedDickBadge
                            size={42}
                            verifiedDickPhotoUrl={props.slide.src}
                            isClickable={false} // disable in Lightbox
                          />
                        </LightboxVerifiedDickIcon>
                      </LightboxVerifiedDickIconWrapper>
                    )}
                  </>
                ),
              }}
            />
          )}

          <ProfilePhoto
            photoUrl={member.profilePhoto?.squareUrl || ImageMissingMemberPhoto}
            photoOnClick={
              member.hasCustomProfilePhoto
                ? () => handleGalleryPhotoClick(0)
                : undefined
            }
            onlineStatus={
              isMyProfile || !member.isLastOnlineWithinWeek
                ? undefined
                : member.onlineStatus
            }
            isVanillaMode={isVanillaMode}
          >
            <ProfilePhoto.TopLeftCorner>
              <QuickiesProfileEllipsisActions
                isCurrentUser={isMyProfile}
                member={member}
              />
            </ProfilePhoto.TopLeftCorner>

            {isDickVerificationEnabled && (
              <ProfilePhoto.BottomLeftCorner>
                {isEligibleForDickVerification && <VerifyDickButton />}
                {isPendingDickVerification && (
                  <VerifyDickButton isPending={true} />
                )}
              </ProfilePhoto.BottomLeftCorner>
            )}

            <ProfilePhoto.Actions>
              {/*<HeartstingButton*/}
              {/*  type={"button"}*/}
              {/*  onClick={handleHeartstingOnClick}*/}
              {/*>*/}
              {/*  <Image*/}
              {/*    src={ImageHeartsting}*/}
              {/*    alt={""}*/}
              {/*    quality={100}*/}
              {/*    width={22}*/}
              {/*    height={20}*/}
              {/*  />*/}
              {/*</HeartstingButton>*/}

              {hasLinkCopiedToast && (
                <LinkCopiedToast>
                  <CheckCircleIcon
                    height={22}
                    color={theme.color.tint.default}
                  />
                  {t(`${i18nKey}.profile_actions.share_link_copied`)}
                </LinkCopiedToast>
              )}

              {!isMyProfile && (
                <ChatButton type={"button"} onClick={handleChatOnClick}>
                  <ChatBubbleLeftIcon height={22} />
                </ChatButton>
              )}

              {isMyProfile && quickiesSession?.isAnonymous === false && (
                <ShareButton type={"button"} onClick={handleShareOnClick}>
                  <FaShare size={20} color={theme.color.tint.default} />
                </ShareButton>
              )}
            </ProfilePhoto.Actions>

            {isMyProfile && !member.hasCustomProfilePhoto && (
              <QuickiesProfilePhotoUploadCta
                onUpload={(formData: ProfilePhotoUploadModel) =>
                  handleProfilePhotoUpload(member, formData)
                }
                customPhotoPolicy={<CustomPhotoPolicy />}
                hasHeroCrop={false}
                photoPlaceholderUrl={photoPlaceholderUrl}
                i18nOverride={{
                  title: t(`${reminderI18nKey}.title`),
                  text: t(`${reminderI18nKey}.text`),
                  closeButton: t(`${reminderI18nKey}.close_button`),
                  uploadButton: t(`${reminderI18nKey}.upload_button`),
                  thumbCropTitle: " ",
                  heroCropTitle: " ",
                }}
              />
            )}
          </ProfilePhoto>

          <QuickiesProfileIntro member={member} isCurrentUser={isMyProfile} />

          <QuickiesProfilePhotos
            member={member}
            onClick={(photoIndex) => handleGalleryPhotoClick(photoIndex + 1)}
            isCurrentUser={isMyProfile}
          />

          {hasGroupsEnabled && <QuickiesProfileGroups member={member} />}

          <QuickiesProfileAbout member={member} />
        </>
      ) : (
        <>
          {isVerificationError && (
            <EmptyMessage>
              <p>{t(`${i18nKey}.verify_and_reload`)}</p>

              <TextButton
                data-test-id="page-quickies-profile-reload"
                onClick={() => reloadQuickiesProfile(quickiesProfileId)}
                size={"small"}
              >
                {t(`${i18nKey}.reload`)}
              </TextButton>
            </EmptyMessage>
          )}
        </>
      )}
    </>
  )
}

export default PageQuickiesProfile

const EmptyMessage = styled.div`
  ${({ theme }) => theme.font.regular.body};
  text-align: center;
  padding: ${({ theme }) => theme.spacing.regular};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

const ChatButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.color.tint.default};
  color: #fff;
`

const ShareButton = styled(IconButton)`
  background-color: #fff;
  color: ${({ theme }) => theme.color.tint.default};
`

const LinkHolder = styled.a`
  user-select: text;
`

const LinkCopiedToast = styled.span`
  position: absolute;
  bottom: 0;
  right: 60px;
  background: #000;
  color: #fff;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  white-space: nowrap;
  gap: 8px;
  height: 44px;
  min-height: 44px;
  line-height: 44px;
`

const Lightbox = dynamic(
  () => import("@hornet-web-react/core/components-lazy/Lightbox")
)

const LightboxVerifiedDickIconWrapper = styled.span`
  display: block;
  width: 100%;
  position: absolute;
  aspect-ratio: 1;
  max-width: min(640px, 100%) !important;
  max-height: min(640px, 100%) !important;
  z-index: 1;
`

const LightboxVerifiedDickIcon = styled.span`
  width: 42px;
  height: 42px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 2;
`

const QuickiesProfilePhotoUploadCta = styled(ProfilePhotoUploadCta)`
  background: transparent;
`
