import { useCallback } from "react"
import NiceModal from "@ebay/nice-modal-react"
import { QuickiesPremiumPaywall } from "@hornet-web-react/core/types"
import QuickiesAnalyticsEvent from "../models/quickies-analytics-event"
import { useSessionApi } from "@hornet-web-react/core/contexts/session"
import { useQuickiesPaywall } from "./use-quickies-paywall"
import { QuickiesEntitlementState } from "../models/types"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import EventTrackerService from "@hornet-web-react/core/services/EventTrackerService"
import { CORE_TYPES } from "@hornet-web-react/core/services/types"
import { useLazyLoaded } from "@hornet-web-react/core/hooks/use-lazy-loaded"

export function useQuickiesPremium() {
  const {
    premium: { setPremiumPaywall },
  } = useSessionApi()
  // cannot use the {reportEvent} = useEventTrackerService() because of circular dependency
  const eventTrackerService = useCoreService<EventTrackerService>(
    CORE_TYPES.EventTrackerService
  )
  const { get: getQuickiesPremiumModal } = useLazyLoaded(
    () => import("../components/Modals/QuickiesPremiumModal")
  )

  const { state, entitlement, reloadQuickiesSession } = useQuickiesPaywall()

  const hasPremium = state === QuickiesEntitlementState.enum.purchased

  return {
    hasPremium,
    isPremiumLoading: state === QuickiesEntitlementState.enum.unknown,
    entitlement,
    reloadQuickiesSession,

    openQuickiesPremiumModal: useCallback(
      async (paywall: QuickiesPremiumPaywall) => {
        void eventTrackerService.report(
          QuickiesAnalyticsEvent.premiumOpened(paywall)
        )

        setPremiumPaywall(paywall)

        void getQuickiesPremiumModal((modal) =>
          NiceModal.show(modal).catch(() => setPremiumPaywall(undefined))
        )
      },
      [eventTrackerService, getQuickiesPremiumModal, setPremiumPaywall]
    ),
  }
}
