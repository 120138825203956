import { FC } from "react"
import { SubmitErrorModel } from "@hornet-web-react/core/models/submit-error.model"
import Message from "./Message"

type SubmitErrorProps = {
  error: SubmitErrorModel
  className?: string
}

const SubmitError: FC<SubmitErrorProps> = ({ error, className }) => {
  return (
    <Message context="error" className={className}>
      <div>
        {error.title && (
          <>
            <strong>{error.title}</strong>
            <br />
          </>
        )}
        {error.message}
      </div>
    </Message>
  )
}

export default SubmitError
