export interface EntitlementInterface {
  id: string
  feature: EntitlementFeature
  expires_at: Date
  timeLeft: string
  metadata: {
    state: string | undefined
    productId: string | undefined
  }
  entitlementTransaction: Record<string, unknown> | undefined
}

export enum EntitlementFeature {
  DeleteMessage = "delete_message",
  NewGuys = "new_guys",
  ReadReceipts = "read_receipts",
  RemoveAds = "remove_ads",
  SWCYO = "swcyo",
  UnlockGrid = "unlock_grid",
  UnlimitedPhotos = "unlimited_photos",
  AgeFilter = "age_filter",
  FavouritesFilter = "favourites_filter",
  HeightFilter = "height_filter",
  KysFilter = "kys_filter",
  LookingForFilter = "looking_for_filter",
  OnlineOnlyFilter = "online_filter",
  RelationshipStatusFilter = "relationship_status_filter",
  RoleFilter = "role_filter",
  WeightFilter = "weight_filter",
  Quickies = "hornet_x",
}

export enum EntitlementDuration {
  OneDay = "P1D",
  OneWeek = "P1W",
  OneMonth = "P1M",
  ThreeMonths = "P3M",
  HalfYear = "P6M",
  OneYear = "P1Y",
  OneMinute = "PT1M",
  SevenMinutes = "PT7M",
}
