import { useCallback } from "react"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import EventTrackerService from "@hornet-web-react/core/services/EventTrackerService"
import { CORE_TYPES } from "@hornet-web-react/core/services/types"
import QuickiesAnalyticsEvent from "../models/quickies-analytics-event"
import { useQuickiesPremium } from "./use-quickies-premium"

export const useEventTrackerService = () => {
  const eventTrackerService = useCoreService<EventTrackerService>(
    CORE_TYPES.EventTrackerService
  )

  const { hasPremium } = useQuickiesPremium()

  return {
    reportEvent: useCallback(
      async (analyticsEvent: QuickiesAnalyticsEvent) => {
        // enhance every event with a flag whether user has premium or not
        const eventWithPremiumFlag = analyticsEvent.addParams([
          { key: "has_premium", string_value: hasPremium ? "yes" : "no" },
        ])

        return eventTrackerService.report(eventWithPremiumFlag)
      },
      [eventTrackerService, hasPremium]
    ),
  }
}
