import { useScript } from "usehooks-ts"
import { useState } from "react"

type useGoogleRecaptchaV3Props = {
  googleRecaptchaV3SiteKey: string
}

export enum RecaptchaToken {
  CreateAccount = "create_account",
  CreateFavourite = "create_favourite",
}

export const useGoogleRecaptchaV3 = (config: useGoogleRecaptchaV3Props) => {
  useScript(
    `https://www.google.com/recaptcha/api.js?render=${config.googleRecaptchaV3SiteKey}`,
    {
      removeOnUnmount: false,
    }
  )

  const getToken = async (action: RecaptchaToken) => {
    if (typeof window?.grecaptcha?.execute !== "function") {
      return undefined
    }

    return await window.grecaptcha.execute(config.googleRecaptchaV3SiteKey, {
      action,
    })
  }

  return { getToken }
}
